import React, { useState, useEffect, useRef } from "react";
import Searchicon from "../../image/material-symbols_search.png";
import chevrondown from "../../image/subway_down-2.png";
import chevrondownup from "../../image/subway_down-.png";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import axios from "axios";
import { submitform } from "../../helpers/Formhelper";
import SearchableDropdown from "../../helpers/SearchableDropdown";
import { jobSkills, jobSectors, courseLevels } from "../../helpers/Object";
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const SearchCourseForm = ({ onSubmit, query }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  const [formData, setFormData] = useState({
    industry: "",
    skills: "",
  });
  useEffect(() => {
    // Parse the query parameters from the passed prop
    const queryParams = new URLSearchParams(query);
    const industryParam = queryParams.get("industry");
    const skillsParam = queryParams.getAll("skills");

    // Set industry and skills based on query parameters
    if (industryParam) {
      setSelectedIndustry(industryParam);
      setFormData((prevFormData) => ({
        ...prevFormData,
        industry: industryParam,
      }));
      setSkills(jobSkills[industryParam]);
    }

    if (skillsParam.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        skills: skillsParam,
      }));
    }
  }, [query]);
  const toggleSection = () => {
    setIsVisible(!isVisible);
    document.querySelector(".hrp-course-search-form").style.height = isVisible
      ? "50vh"
      : "90vh";
  };
  useEffect(() => {
    if (selectedIndustry) {
      const fetchData = async () => {
        try {
          const [
            categoriesResponse,
            toolsResponse,
            certificationsResponse,
            skillsResponse,
          ] = await Promise.all([
            axios.get(`${BASE_URL_PLATFORM}/api/categories`, {
              params: { industry: selectedIndustry },
            }),
            axios.get(`${BASE_URL_PLATFORM}/api/tools`, {
              params: { industry: selectedIndustry },
            }),
            axios.get(`${BASE_URL_PLATFORM}/api/certifications`, {
              params: { industry: selectedIndustry },
            }),
            axios.get(`${BASE_URL_PLATFORM}/api/skills`, {
              params: { industry: selectedIndustry },
            }),
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [selectedIndustry]);
  const handleIndustryChange = (selectedOption) => {
    console.log(selectedOption);
    const industry = selectedOption;

    setFormData((prevFormData) => ({
      ...prevFormData,
      industry: industry,
    }));

    if (industry) {
      setSkills(jobSkills[industry]);
    } else {
      setSkills([]);
    }
  };
  const findCourse = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = submitform("coursesearchform");
    try {
      const response = await axios.get(
        BASE_URL_PLATFORM + "/api/course/search",
        {
          params: formdata,
        }
      );

      if (response.status !== 400) {
        //console.log(response.data);
        onSubmit(response.data);
        setLoading(false);
      } else {
        console.error("Error posting job:", response.message);
      }
    } catch (error) {
      console.error("Error posting job:", error);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setLoading(true);

    // Reset the form by its ID
    document.getElementById("coursesearchform").reset();
    window.location.href = "/courses";
    // No need to reassign 'industry' as a constant

    setLoading(false);
  };
  const skillOptions = [
    "Programming",
    "Design",
    "Marketing",
    "Management",
    "Data Analysis",
  ];

  const featureOptions = [
    "Downloadable Resources",
    "Certificate of Completion",
    "Quizzes",
  ];

  const topicOptions = [
    "Web Development",
    "Graphic Design",
    "Digital Marketing",
    "Business Management",
  ];

  const subtitleOptions = ["Yes", "No"];

  const priceOptions = ["Free", "Paid"];

  const ratingOptions = ["1 star", "2 stars", "3 stars", "4 stars", "5 stars"];

  return (
    <section className="jobs-form-section hrp-course-search-form container m-auto">
      <form
        id="coursesearchform"
        className="hrp-form-course-search-outer container m-auto"
      >
        <div>
          <div
            className={`form-open-line-outer-inner ${
              isVisible ? "find-course-search-active" : "find-course-search"
            } flex`}
          >
            <div className="PopularJobCategories">
              <div className="flex items-center">
                <img src={Searchicon} alt="Search Icon" />
                <input
                  type="search"
                  name="title"
                  placeholder="Course Name"
                  className="p-1 mr-2 w-full no-border text-base/6"
                />
              </div>
            </div>
            {!isVisible && (
              <div>
                <button className="text-white FIND-JOB-btn">FIND</button>
              </div>
            )}
          </div>

          {!isVisible && (
            <div>
              <div className="form-open-line" id="btn-1">
                <div className="form-open-line-inner" onClick={toggleSection}>
                  <img src={chevrondown} alt="Toggle" />
                </div>
              </div>
            </div>
          )}

          {isVisible && (
            <div
              className="mx-auto pt-8 pb-8 px-4 shadow-md"
              id="hiddenSection"
            >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-5 searchcourse">
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <SearchableDropdown
                      options={jobSectors.map((sector) => ({
                        value: sector.value,
                        label: sector.label,
                      }))}
                      className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                      multiple={false}
                      name="industry"
                      value={jobSectors.find(
                        (sector) => sector.value === formData.industry
                      )}
                      onChange={handleIndustryChange}
                    />
                    <label className="block text-sm font-medium text-gray-700 SearchableDropdown">
                      Job Industry / Sector
                    </label>
                  </div>
                </div>
                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="skills"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="skills"
                    multiple
                    value={formData?.skills}
                    data={skills}
                    options={{ placeholder: "Type Skills" }}
                  />
                  <label
                    htmlFor="skills"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Skills
                  </label>
                </div>

                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="features"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="features"
                    multiple={false}
                    data={featureOptions.map((option, index) => ({
                      id: option || `option-${index}`,
                      text: option,
                    }))}
                    options={{
                      placeholder: "Select Feature",
                      theme: "classic",
                      width: "resolve",
                    }}
                  />
                  <label
                    htmlFor="features"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Features
                  </label>
                </div>

                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="topic"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="topic"
                    multiple={false}
                    data={topicOptions.map((option, index) => ({
                      id: option || `option-${index}`,
                      text: option,
                    }))}
                    options={{
                      placeholder: "Select Topic",
                      theme: "classic",
                      width: "resolve",
                    }}
                  />
                  <label
                    htmlFor="topic"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Topic
                  </label>
                </div>

                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="level"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="level"
                    multiple={false}
                    data={courseLevels}
                    options={{
                      placeholder: "Select Level",
                      theme: "classic",
                      width: "resolve",
                    }}
                  />
                  <label
                    htmlFor="level"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Level
                  </label>
                </div>

                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="subtitles"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="subtitles"
                    multiple={false}
                    data={subtitleOptions.map((option, index) => ({
                      id: option || `option-${index}`,
                      text: option,
                    }))}
                    options={{
                      placeholder: "Select Subtitle",
                      theme: "classic",
                      width: "resolve",
                    }}
                  />
                  <label
                    htmlFor="subtitles"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Subtitles
                  </label>
                </div>

                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="price"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="price"
                    multiple={false}
                    data={priceOptions.map((option, index) => ({
                      id: option || `option-${index}`,
                      text: option,
                    }))}
                    options={{
                      placeholder: "Select Price",
                      theme: "classic",
                      width: "resolve",
                    }}
                  />
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Price
                  </label>
                </div>

                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="rating"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="rating"
                    multiple={false}
                    data={ratingOptions.map((option, index) => ({
                      id: option || `option-${index}`,
                      text: option,
                    }))}
                    options={{
                      placeholder: "Select Rating",
                      theme: "classic",
                      width: "resolve",
                    }}
                  />
                  <label
                    htmlFor="rating"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Ratings
                  </label>
                </div>
              </div>

              <div className="text-align-end flex justify-end gap-2 mt-3">
                <button
                  type="button"
                  className="course-cardbtnapply px-10 text-xl  py-2 hover:text-white "
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  name="submitbtn"
                  className=" md:w-auto px-10 text-xl  py-2 text-white "
                  onClick={findCourse}
                >
                  FIND
                </button>
              </div>
            </div>
          )}

          {isVisible && (
            <div className="form-open-line-bottom" id="btn-2">
              <div
                className="form-open-line-inner-bottom"
                onClick={toggleSection}
              >
                <img src={chevrondownup} alt="Toggle" />
              </div>
            </div>
          )}
        </div>

        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
      </form>
      <CustomToastContainer ref={toastRef} />
    </section>
  );
};

export default SearchCourseForm;
