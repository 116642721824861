import React, { useState, useEffect, useRef } from "react";
import ProfileDownload from "../Website/ProfileDownload";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FeatherIcon from "feather-icons-react";
import Select2 from "../Common/Select2Wrapper";
import SearchableDropdown from "../../helpers/SearchableDropdown";
import CustomToastContainer from "../Common/CustomToastContainer";

import {
  jobSectors,
  jobCategories,
  jobIndustryTools,
  currentJobStatusOptions,
  jobAvailabilityOptions,
  jobSeekingStatusOptions,
  employmentStatusOptions,
} from "../../helpers/Object";
import { PopupProvider } from "../Common/PopupContext";
import { usePopup } from "../Common/PopupContext";
import AddEducation from "../popup/Profilepagepopup/AddEducation.js";
import AddEmployment from "../popup/Profilepagepopup/AddEmployment.js";
import AddOnlineProfile from "../popup/Profilepagepopup/AddOnlineProfile.js";
import AddPresentation from "../popup/Profilepagepopup/Presentation.js";
import AddWorkSample from "../popup/Profilepagepopup/Worksample.js";
import AddPatent from "../popup/Profilepagepopup/AddPatent.js";
import AddCertification from "../popup/Profilepagepopup/AddCertification.js";
import AddResearchpublication from "../popup/Profilepagepopup/AddResearchpublication.js";
import EditUser from "../popup/Profilepagepopup/EditUser.js";
import ProfileChangePopup from "../popup/Profilepagepopup/ProfileChangePopup.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import api from "../../Api";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;
const interests = ["Travelling", "Cricket", "Listening Music"];

const CandidateProfile = () => {
  const [tools, setTools] = useState([]);
  let data = useParams();
  const [render, setrender] = useState(0);
  const [Profile, setProfile] = useState(null);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  const percentage = 60;

  const [skills, setSkills] = useState([]);
  const [uploaddata, setuploaddata] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleCVUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("resume", e.target.files[0]);
    try {
      const res = await api.uploadcv(formData);
      setuploaddata(res);
      // setProfile(res);
      if (
        res.statusCode &&
        (res.statusCode === 500 || res.statusCode === 413)
      ) {
        setError(res.message);
        setLoading(false);
        return;
      }
      setUploadedFileName(e.target.files[0].name);
      setLoading(false);
      setError(null);
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data.id !== undefined) {
      setLoading(true);
      async function getProfileid() {
        try {
          const response = await api.getProfileId(data.id);
          if (response.status !== 500) {
            const industry = response.careerProfile?.industry;
            const industryCategories = jobCategories[industry] || [];

            // Map the categories to the { value, label } format
            const formattedCategories = industryCategories.map((category) => ({
              value: category,
              label: category,
            }));
            setCategories(formattedCategories);
            setTools(jobIndustryTools[industry]);
            setProfile(response);
            setLoading(false);
            // No need to update render here to trigger re-render
          } else if (response.status === 500) {
            // Handle 500 error
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          // Handle error
        }
      }
      getProfileid();
    }

    setSkills(["Skill 1", "Skill 2", "Skill 3"]);
    // Add an empty dependency array if you only want this to run once
  }, [data.id]);

  const Accomplishments = () => {
    const handleAdd = (title) => {
      // Add your add logic here
      alert(`Add ${title}`);
    };
  };
  const useLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue;
  };

  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  // const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    //setPopupComponent(null);
    openPopup();
  };
  const hendlAddEducation = () => {
    const popup = (
      <AddEducation
        onSave={(formData) => handleSave("education", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handlAddEmployment = () => {
    const popup = (
      <AddEmployment
        onSave={(formData) => handleSave("employment", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleEditEducation = (category, index, values) => {
    const popup = (
      <AddEducation
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };
  const handleEditEmployment = (category, index, values) => {
    const popup = (
      <AddEmployment
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleOnlineProfile = () => {
    const popup = (
      <AddOnlineProfile
        onSave={(formData) => handleSave("online_profile", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleSave = (category, formData) => {
    const prevState = Profile;

    if (category === "employment" || category === "education") {
      const updatedCategory = [...prevState[category], formData];

      setProfile({
        ...prevState,
        [category]: updatedCategory,
      });
    } else {
      const updatedAccomplishments = [...prevState.accomplishments];
      const updatedCategory = [
        ...updatedAccomplishments[0][category],
        formData,
      ];
      updatedAccomplishments[0][category] = updatedCategory;

      setProfile({
        ...prevState,
        accomplishments: updatedAccomplishments,
      });
    }

    //setPopupComponent(null); // Close the popup after saving
  };

  const handleDelete = (category, index) => {
    const prevState = Profile;

    if (category === "employment" || category === "education") {
      const updatedCategory = [...prevState[category]];
      updatedCategory.splice(index, 1);

      setProfile({
        ...prevState,
        [category]: updatedCategory,
      });
    } else {
      const updatedAccomplishments = [...prevState.accomplishments];
      const updatedCategory = [...updatedAccomplishments[0][category]];
      updatedCategory.splice(index, 1);
      updatedAccomplishments[0][category] = updatedCategory;

      setProfile({
        ...prevState,
        accomplishments: updatedAccomplishments,
      });
    }
  };

  const handleSelectChange = (e, fieldName) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        careerProfile: {
          ...prevProfile.careerProfile,
          [fieldName]: selectedValue,
        },
      }));
    }
  };
  const handleSelectChange2 = (selectedOptions, fieldName) => {
    // Check if selectedOptions is an event object (from a Select2 or similar component)
    if (selectedOptions && selectedOptions.target) {
      selectedOptions = selectedOptions.target.value; // Get the value directly from the event
    }

    // Safely handle cases where selectedOptions is null, undefined, or an empty array
    if (
      !selectedOptions ||
      (Array.isArray(selectedOptions) && selectedOptions.length === 0)
    ) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        careerProfile: {
          ...prevProfile?.careerProfile,
          [fieldName]: [], // Set to an empty array if no options are selected or selectedOptions is empty
        },
      }));
      return;
    }

    // Ensure selectedOptions is an array (if it's a single object, wrap it in an array)
    const optionsArray = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions];

    // Extract values from optionsArray, handling cases where the option might not have a value property
    const selectedValues = optionsArray
      .map(
        (option) => (typeof option === "object" ? option?.value : option) || ""
      )
      .filter(Boolean); // Only include truthy values

    // Safely update the state with selected values or an empty array
    setProfile((prevProfile) => ({
      ...prevProfile,
      careerProfile: {
        ...prevProfile?.careerProfile,
        [fieldName]: selectedValues, // Update the field with selected values
      },
    }));
  };

  const handlEditResearchpublication = (category, index, values) => {
    const popup = (
      <AddResearchpublication
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };
  const handleEditOnline = (category, index, values) => {
    const popup = (
      <AddOnlineProfile
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleEditWork = (category, index, values) => {
    const popup = (
      <AddWorkSample
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };
  const handleEditPresentation = (category, index, values) => {
    const popup = (
      <AddPresentation
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleEditPatent = (category, index, values) => {
    const popup = (
      <AddPatent
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };
  const handleEditCertification = (category, index, values) => {
    const popup = (
      <AddCertification
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(formData) => handleEditSave(category, index, formData)}
        initialValues={values}
        category={category}
        index={index}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleEditSave = (category, index, formData) => {
    const prevState = Profile;

    if (category === "employment" || category === "education") {
      const updatedCategory = [...prevState[category]];
      updatedCategory[index] = formData;

      setProfile({
        ...prevState,
        [category]: updatedCategory,
      });
    } else {
      const updatedAccomplishments = [...prevState.accomplishments];
      const updatedCategory = [...updatedAccomplishments[0][category]];
      updatedCategory[index] = formData;
      updatedAccomplishments[0][category] = updatedCategory;

      setProfile({
        ...prevState,
        accomplishments: updatedAccomplishments,
      });
    }

    //setPopupComponent(null); // Close the popup after saving
  };
  const handlWorksample = () => {
    const popup = (
      <AddWorkSample
        onSave={(formData) => handleSave("work_sample", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };

  const handlPresentation = () => {
    const popup = (
      <AddPresentation
        onSave={(formData) => handleSave("presentation", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const handlAddPatent = () => {
    const popup = (
      <AddPatent
        onSave={(formData) => handleSave("patent", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const handleCertification = () => {
    const popup = (
      <AddCertification
        onSave={(formData) => handleSave("certification", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const handlResearchpublication = () => {
    const popup = (
      <AddResearchpublication
        onSave={(formData) => handleSave("research_publication", formData)}
        onClose={() => handleClosePopup("Popup closed")}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  // const handlEditResearchpublication = (values) => {
  //   const popup = (
  //     <AddResearchpublication
  //       onSave={(formData) => handleProfileform(formData)}
  //       onClose={() => handleClosePopup("Popup closed")}
  //       initialValues={values}
  //     />
  //   );
  //   //setPopupComponent(popup);
  //   openPopup(popup);
  // };
  const handlEditUser = (values) => {
    const popup = (
      <EditUser
        onSave={(formData) => handleProfileform(formData)}
        onClose={() => handleClosePopup("Popup closed")}
        initialValues={values}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const handleProfileform = (formData) => {
    setProfile(formData);
  };
  const toastRef = useRef();
  /* ======= Ends Popup ====== */
  const downloadRef = useRef(null);
  const handleDownload = () => {
    downloadRef.current.downloadCV();
  };
  function getFileBasename(url) {
    if (!url) {
      console.error("URL is undefined or null");
      return ""; // or return a default value
    }

    return url.split("/").pop().split("#")[0].split("?")[0];
  }
  const handleIndustryChange = (e) => {
    const industry = e.target.value;
    setProfile((prevProfile) => ({
      ...prevProfile,
      careerProfile: {
        ...prevProfile.careerProfile,
        industry: industry,
      },
    }));
    if (industry) {
      const industryCategories = jobCategories[industry] || [];

      // Map the categories to the { value, label } format
      const formattedCategories = industryCategories.map((category) => ({
        value: category,
        label: category,
      }));
      setCategories(formattedCategories);
      setTools(jobIndustryTools[industry] || []);
    } else {
      setCategories([]);
    }
  };
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.updateProfile(data.id, Profile);
      if (res != 500) {
        setLoading(false);
        toastRef.current.addToast("Profile Updated Successfully", "success");
      }
      setLoading(false);
      setError(null);
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
      toastRef.current.addToast(
        err.response ? err.response.data : err.message,
        "error"
      );
      setLoading(false);
    }
  };
  const handleUpload = async (selectedFile) => {
    
    const validTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/gif",
      "image/jiff",
    ];

    // Check if file type is one of the valid image types
    if (!validTypes.includes(selectedFile.type)) {
      setLoading(false);
      toastRef.current.addToast(
        "Only images are allowed (JPG, PNG, WEBP, GIF, JIFF)!",
        "error"
      );
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        domainpath + "/api/upload/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProfile((prevData) => ({
        ...prevData,
        jobProfileImage: response.data.imagePath,
      }));
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };
  const handleChangeprofile = () => {
    const popup = (
      <ProfileChangePopup
        onSave={(formData) => handleProfileform(formData)}
        onClose={() => handleClosePopup("Popup closed")}
        // initialValues={values}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };
  return (
    <>
      <PopupProvider>
        <div className="">
          <div className="">
            <div className="">
              <div className="container mx-auto p-4 shadow-lg profilesection hrp-candidate-profile-page userinapplicant">
                <div className="profile-header md:flex border-b pb-4 mb-4 items-start">
                  <div className="relative lg:w-52  hrp-profile-header-image ">
                    <CircularProgressbar
                      value={percentage}
                      styles={buildStyles({
                        rotation: 0.5,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `rgba(0, 128, 1, ${percentage / 100})`,
                        textColor: "#f88",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                        strokeWidth: 3,
                      })}
                    />
                    <label
                      htmlFor="profileImage"
                      className=" cursor-pointer absolute transform -translate-x-1/2 -translate-y-1/2 text-gray-700 font-medium hrp-label-position"
                    >
                      <input
                        type="file"
                        id="profileImage"
                        accept="image/*"
                        name="profileImage"
                        onChange={(e) => handleUpload(e.target.files[0])}
                        className="hidden"
                      />
                      <div className="w-full cursor-pointer bg-white rounded-full flex items-center justify-center border-2 border-white hrp-profile-section-image ">
                        <img
                          src={
                            Profile?.jobProfileImage &&
                            Profile?.jobProfileImage.trim() !== "" &&
                            Profile?.jobProfileImage !== "NA"
                              ? Profile?.jobProfileImage.startsWith(
                                  "http://"
                                ) ||
                                Profile?.jobProfileImage.startsWith("https://")
                                ? Profile?.jobProfileImage
                                : Profile?.jobProfileImage.startsWith(
                                    "/uploads/"
                                  )
                                ? `${domainpath}${Profile?.jobProfileImage}`
                                : `${domainpath}/uploads/${Profile?.jobProfileImage}`
                              : `${domainpath}/uploads/deafultman.png`
                          }
                          alt="Profile"
                          className="w-full h-full rounded-full"
                        />
                        <div
                          className="absolute lg:top-10 md:top-4 right-0 transform -translate-x-1/2 translate-y-1/2 bg-blue-500 p-1 rounded-full"
                          style={{ right: "-30px" }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-white md:w-4 md:h-4"
                          >
                            <path
                              d="M9 3H15L17 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7L9 3ZM12 19C13.5913 19 15.1174 18.3679 16.2426 17.2426C17.3679 16.1174 18 14.5913 18 13C18 11.4087 17.3679 9.88258 16.2426 8.75736C15.1174 7.63214 13.5913 7 12 7C10.4087 7 8.88258 7.63214 7.75736 8.75736C6.63214 9.88258 6 11.4087 6 13C6 14.5913 6.63214 16.1174 7.75736 17.2426C8.88258 18.3679 10.4087 19 12 19ZM12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13C8 11.9391 8.42143 10.9217 9.17157 10.1716C9.92172 9.42143 10.9391 9 12 9C13.0609 9 14.0783 9.42143 14.8284 10.1716C15.5786 10.9217 16 11.9391 16 13C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </label>
                    <div className="absolute  left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center hrp-profile-section-text">
                      <div className="text-center text-gray-700 font-medium text-lg">
                        {percentage}%
                      </div>
                    </div>
                  </div>
                  <div className="profile-info ml-4 xl:flex items-center ">
                    <div className="profile-info xl:ml-4 flex-1 mainprofile">
                      <h1 className="text-lg font-medium flex items-center">
                        {/* Mr. John{" "} */}
                        {Profile?.salutation}{" "}
                        {Profile?.firstName + " " + " " + Profile?.lastName}
                        <span
                          className="ml-2 text-lg cursor-pointer"
                          onClick={(e) => handlEditUser(Profile)}
                        >
                          &#9998;
                        </span>
                      </h1>
                      <p className="text-gray-600 font-medium">
                        {Profile?.jobTitle}
                      </p>
                      <p className="text-gray-600">
                        at {Profile?.employment?.[0]?.company}
                      </p>
                    </div>
                    <div className="details flex flex-wrap mt-2">
                      <div className="detail-item md:w-1/2 flex items-center mb-2">
                        <span className="mr-2">&#x1F4CD;</span>{" "}
                        {Profile?.location}
                      </div>
                      <div className="detail-item md:w-1/2 flex items-center mb-2">
                        <span className="mr-2">&#128197;</span>
                        {Profile?.experience}
                      </div>
                      <div className="detail-item md:w-1/2 flex items-center mb-2">
                        <span className="mr-2">&#x1F4B0;</span>
                        {Profile?.currentCTC}
                      </div>
                      <div className="detail-item md:w-1/2 flex items-center mb-2">
                        <span className="mr-2">&#128222;</span> {Profile?.phone}
                        <span className="ml-2 text-green-600">&#10004;</span>
                      </div>
                      <div className="detail-item md:w-1/2 flex items-center mb-2">
                        <span className="mr-2">&#x2709;</span> {Profile?.email}
                        <span className="ml-2 text-green-600">&#10004;</span>
                      </div>
                      {Profile?.noticePeriod && (
                        <div className="detail-item md:w-1/2 flex items-center mb-2">
                          <span className="mr-2">&#128197;</span>{" "}
                          {Profile?.noticePeriod}
                        </div>
                      )}
                    </div>
                    <button
                      className="joblist-btnclear change-profile-btn bg-blue-500 text-white py-2 px-4 mt-4 rounded hover:bg-blue-700"
                      style={{ padding: "5px 10px" }}
                      onClick={(e) => handleChangeprofile(e)}
                    >
                      Change Profile
                    </button>
                  </div>
                </div>
                <div className="UploadCV-border">
                  <div className="resumesection">
                    <p>Resume</p>
                  </div>
                  <div className="container m-auto">
                    <div className="uploadedcvdetails">
                      <div className="uploadedcvdetailsfirst">
                        <p className="firststep">
                          {Profile?.cvUrl != undefined && Profile?.cvUrl != ""
                            ? getFileBasename(Profile?.cvUrl)
                            : ""}
                        </p>
                        <p className="secondp">Uploaded on Jun 28,2024</p>
                      </div>
                      <div className="flex gap-4 items-center pt-2">
                        <div className="flex user-profile-viewdetails-btn ">
                          <button
                            className="flex items-center ViewDetails-btn px-6 py-2 gap-2 text-white  rounded-full"
                            onClick={(e) => handleDownload(Profile)}
                          >
                            Download CV
                            <FeatherIcon
                              icon="download"
                              className="cursor-pointer"
                            />
                            <ProfileDownload
                              ref={downloadRef}
                              Profiles={Profile}
                              domainpath={domainpath}
                              tools={tools}
                            />
                          </button>
                        </div>
                        <a
                          href={clientdomainpath + "/profile/" + Profile?._id}
                          target="_blank"
                        >
                          <h1
                            className="text-medium font-medium"
                            style={{ color: "#003366" }}
                          >
                            View Profile
                          </h1>
                        </a>
                        {/* <FeatherIcon icon="download" /> */}
                        {/* <FeatherIcon icon="trash-2" /> */}
                      </div>
                    </div>
                    <div className=" flex items-center upload-cv-main justify-center md:p-10">
                      <div className="upoad-cv-section uplloadcvinjob">
                        <div className="upload-section">
                          <input
                            type="file"
                            id="file-upload"
                            hidden
                            accept=".pdf, .docx"
                            onChange={handleCVUpload}
                          />
                          <label htmlFor="file-upload" className="upload-label">
                            Upload CV
                          </label>

                          {loading && <p>Uploading...</p>}
                          {uploadedFileName && !loading && (
                            <p className="upload-success-message">
                              Uploaded: {uploadedFileName}
                            </p>
                          )}
                          {error && !loading && (
                            <p className="upload-error-message">
                              Error: {error}
                            </p>
                          )}

                          <p className="supported-formats">
                            Supported Formats: doc, docx, rtf, pdf, up to 2 MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <input
                      id="profileheadline"
                      name=""
                      defaultValue={Profile?.jobTitle}
                      className="border rounded w-full py-2 px-3 text-gray-700 "
                      type="text"
                      placeholder=""
                    />
                    <label
                      htmlFor="profileheadline"
                      className="profileheadline"
                    >
                      Profile Headline
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-5 hrp-create-profile">
                  {/* <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <select
                        name="industry"
                        defaultValue={categories?.industry}
                        onChange={(e) => handleIndustryChange(e)}
                        className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                      >
                        {jobSectors.map((sector) => (
                          <option key={sector.value} value={sector.value}>
                            {sector.label}
                          </option>
                        ))}
                      </select>
                      <label className="block text-sm font-medium text-gray-700">
                        Job Industry / Sector
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="animated-form profileheadlinediv">
                    <div className="relative mt-1">
                      <Select2
                        className="categoriesdropdown"
                        id="categories"
                        name="categories"
                        multiple
                        defaultValue={formData?.categories}
                        data={categories}
                        options={{
                          placeholder: "Type Categories",
                        }}
                        onSelect={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            categories: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          }))
                        }
                      />
                      <label
                        htmlFor="categories"
                        className="block text-sm font-medium  text-gray-700 multipleselectlabelskill"
                      >
                        Categories
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="container mx-auto mt-8">
                  <div className="employmentmain">
                    <div className="flex justify-between items-center employment">
                      <h2 className="md:text-base font-medium">Employment</h2>
                      <button
                        className="px-4 py-2 rounded-md"
                        onClick={handlAddEmployment}
                      >
                        + Add Employment
                      </button>
                    </div>
                    {Profile?.employment.length > 0 ? (
                      <div className="p-6 rounded-md shadow-md ">
                        {Profile?.employment.map((employment, iemp) => (
                          <div
                            key={employment._id}
                            className="md:flex justify-between  bg-white p-4 mt-4 border shadow-sm employmentdivcard"
                          >
                            <div>
                              <h3 className="text-lg font-medium">
                                {employment?.role}
                              </h3>
                              <p className="text-gray-700">
                                {employment?.company}
                              </p>
                              <p className="text-gray-600">
                                {employment?.description}
                              </p>
                              <p className="text-gray-600">
                                {employment?.duration}
                              </p>
                              <p className="text-gray-600">
                                {employment?.noticePeriod}
                              </p>
                            </div>
                            <div className="flex justify-end mt-4 space-x-2 iconemployement">
                              <div className="iconemployementsvg1">
                                <FeatherIcon
                                  icon="edit-2"
                                  onClick={() =>
                                    handleEditEmployment(
                                      "employment",
                                      iemp,
                                      employment
                                    )
                                  }
                                />
                              </div>
                              <div className="iconemployementsvg2">
                                <FeatherIcon
                                  icon="trash-2"
                                  onClick={() =>
                                    handleDelete("employment", iemp)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="container mx-auto mt-8">
                  <div className="employmentmain">
                    <div className="flex justify-between items-center employment">
                      <h2 className="md:text-base font-medium">Education</h2>
                      <button
                        className="px-4 py-2 rounded-md"
                        onClick={hendlAddEducation}
                      >
                        + Add Education
                      </button>
                    </div>
                    {Profile?.education.length > 0 ? (
                      <div className="p-6 rounded-md shadow-md ">
                        {Profile?.education.map((education, iedu) => (
                          <div
                            key={education?._id}
                            className="md:flex justify-between  bg-white p-4 mt-4 border shadow-sm employmentdivcard"
                          >
                            <div>
                              <h3 className="text-lg font-medium">
                                {education?.degree}
                              </h3>
                              <p className="text-gray-700">
                                {education?.institution}
                              </p>
                              <p className="text-gray-600">
                                {education?.description}
                              </p>
                              <p className="text-gray-600">
                                {education?.duration}
                              </p>
                            </div>
                            <div className="flex justify-end mt-4 space-x-2 iconemployement">
                              <div className="iconemployementsvg1">
                                <FeatherIcon
                                  icon="edit-2"
                                  onClick={() =>
                                    handleEditEducation(
                                      "education",
                                      iedu,
                                      education
                                    )
                                  }
                                />
                              </div>
                              <div className="iconemployementsvg2">
                                <FeatherIcon
                                  icon="trash-2"
                                  onClick={() =>
                                    handleDelete("education", iedu)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="container mx-auto mt-8 profileAccomplishmentsform">
                  <div className=" p-4 rounded-md shadow-md employmentmain">
                    <div className="employment">
                      <h2 className="text-xl font-semibold">Accomplishments</h2>
                    </div>
                    <p className="text-gray-600 mt-2">
                      Showcase your credentials by adding relevant
                      certifications, work samples, online profiles, etc.
                    </p>

                    <div
                      className={
                        Profile?.accomplishments[0]?.online_profile.length > 0
                          ? "container mx-auto mt-8 editprfileaccomplishment"
                          : "container mx-auto mt-8  profileaccomplishments"
                      }
                    >
                      <div className="employmentmain">
                        <div className="flex justify-between items-center employment">
                          <h2 className="md:text-base font-medium">
                            Online Profile
                          </h2>
                          <button
                            className="px-4 py-2 rounded-md"
                            onClick={handleOnlineProfile}
                          >
                            + Add
                          </button>
                        </div>
                        {Profile?.accomplishments[0]?.online_profile?.length >
                        0 ? (
                          <div className="md:p-6 p-3">
                            {Profile?.accomplishments[0]?.online_profile?.map(
                              (online_profile, index) => (
                                <div
                                  id={"online_profile_" + index}
                                  key={`${online_profile.profile_type}-${index}`}
                                  className="md:flex justify-between cardinneraccom"
                                >
                                  <div>
                                    <h3 className="text-lg font-medium">
                                      {online_profile?.profile_type}
                                    </h3>
                                    <p className="text-gray-700">
                                      {online_profile?.profile_description}
                                    </p>
                                    <p className="text-gray-700">
                                      {online_profile?.profile_url}
                                    </p>
                                  </div>
                                  <div className="flex justify-end mt-4 space-x-2 iconemployement">
                                    <div className="iconemployementsvg1">
                                      <FeatherIcon
                                        icon="edit-2"
                                        onClick={(e) =>
                                          handleEditOnline(
                                            "online_profile",
                                            index,
                                            online_profile
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="iconemployementsvg2">
                                      <FeatherIcon
                                        icon="trash-2"
                                        onClick={(e) =>
                                          handleDelete("online_profile", index)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        Profile?.accomplishments[0]?.work_sample?.length > 0
                          ? "container mx-auto mt-8 editprfileaccomplishment"
                          : "container mx-auto mt-8  profileaccomplishments"
                      }
                    >
                      <div className="employmentmain">
                        <div className="flex justify-between items-center employment">
                          <h2 className="md:text-base font-medium">
                            Work Sample
                          </h2>
                          <button
                            className="px-4 py-2 rounded-md"
                            onClick={handlWorksample}
                          >
                            + Add
                          </button>
                        </div>
                        {Profile?.accomplishments[0]?.work_sample?.length >
                        0 ? (
                          <div className="md:p-6 p-3">
                            {Profile?.accomplishments[0]?.work_sample?.map(
                              (work_sample, index1) => (
                                <div
                                  id={"work_sample_" + index1}
                                  key={work_sample?.sample_title}
                                  className="md:flex justify-between cardinneraccom"
                                >
                                  <div>
                                    <h3 className="text-lg font-medium">
                                      {work_sample?.sample_title}
                                    </h3>
                                    <p className="text-gray-700">
                                      {work_sample?.sample_description}
                                    </p>
                                    <p className="text-gray-700">
                                      {work_sample?.link_to_sample}
                                    </p>
                                  </div>
                                  <div className="flex justify-end mt-4 space-x-2 iconemployement">
                                    <div className="iconemployementsvg1">
                                      <FeatherIcon
                                        icon="edit-2"
                                        onClick={(e) =>
                                          handleEditWork(
                                            "work_sample",
                                            index1,
                                            work_sample
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="iconemployementsvg2">
                                      <FeatherIcon
                                        icon="trash-2"
                                        onClick={(e) =>
                                          handleDelete("work_sample", index1)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      className={
                        Profile?.accomplishments[0]?.research_publication
                          ?.length > 0
                          ? "container mx-auto mt-8 editprfileaccomplishment"
                          : "container mx-auto mt-8  profileaccomplishments"
                      }
                    >
                      <div className="employmentmain">
                        <div className="flex justify-between items-center employment">
                          <h2 className="md:text-base font-medium">
                            Research publication
                          </h2>
                          <button
                            className="px-4 py-2 rounded-md"
                            onClick={handlResearchpublication}
                          >
                            + Add
                          </button>
                        </div>
                        {Profile?.accomplishments[0]?.research_publication
                          ?.length > 0 ? (
                          <div className="md:p-6 p-3">
                            {Profile?.accomplishments[0]?.research_publication?.map(
                              (research_publication, index5) => (
                                <div
                                  id={"research_publication_" + index5}
                                  key={research_publication?.title}
                                  className="md:flex justify-between cardinneraccom"
                                >
                                  <div>
                                    <h3 className="text-lg font-medium">
                                      {research_publication?.title}
                                    </h3>
                                    <p className="text-gray-700">
                                      {research_publication?.author}
                                    </p>
                                    <p className="text-gray-700">
                                      {research_publication?.publish_date}
                                    </p>
                                    <p className="text-gray-700">
                                      {research_publication?.journal}
                                    </p>
                                    <p className="text-gray-700">
                                      {research_publication?.abstract}
                                    </p>
                                    <p className="text-gray-700">
                                      <a href={research_publication?.url}>
                                        {research_publication?.url}
                                      </a>
                                    </p>
                                    <p className="text-gray-700">
                                      {research_publication?.urdescriptionl}
                                    </p>
                                  </div>
                                  <div className="flex justify-end mt-4 space-x-2 iconemployement">
                                    <div className="iconemployementsvg1">
                                      <FeatherIcon
                                        icon="edit-2"
                                        onClick={(e) =>
                                          handlEditResearchpublication(
                                            "research_publication",
                                            index5,
                                            research_publication
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="iconemployementsvg2">
                                      <FeatherIcon
                                        icon="trash-2"
                                        onClick={(e) =>
                                          handleDelete(
                                            "research_publication",
                                            index5
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      className={
                        Profile?.accomplishments[0]?.presentation?.length > 0
                          ? "container mx-auto mt-8 editprfileaccomplishment"
                          : "container mx-auto mt-8  profileaccomplishments"
                      }
                    >
                      <div className="employmentmain">
                        <div className="flex justify-between items-center employment">
                          <h2 className="md:text-base font-medium">
                            Presentation
                          </h2>
                          <button
                            className="px-4 py-2 rounded-md"
                            onClick={handlPresentation}
                          >
                            + Add
                          </button>
                        </div>
                        {Profile?.accomplishments[0]?.presentation?.length >
                        0 ? (
                          <div className="md:p-6 p-3">
                            {Profile?.accomplishments[0]?.presentation?.map(
                              (presentation, index2) => (
                                <div
                                  id={"presentation_" + index2}
                                  key={presentation?.title}
                                  className="md:flex justify-between cardinneraccom"
                                >
                                  <div>
                                    <h3 className="text-lg font-medium">
                                      {presentation?.title}
                                    </h3>
                                    <p className="text-gray-700">
                                      {presentation?.presenter}
                                    </p>
                                    <p className="text-gray-700">
                                      {presentation?.description}
                                    </p>
                                    <p className="text-gray-700">
                                      {presentation?.link}
                                    </p>
                                    <p className="text-gray-700">
                                      {presentation?.event_date}
                                    </p>
                                  </div>
                                  <div className="flex justify-end mt-4 space-x-2 iconemployement">
                                    <div className="iconemployementsvg1">
                                      <FeatherIcon
                                        icon="edit-2"
                                        onClick={(e) =>
                                          handleEditPresentation(
                                            "presentation",
                                            index2,
                                            presentation
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="iconemployementsvg2">
                                      <FeatherIcon
                                        icon="trash-2"
                                        onClick={(e) =>
                                          handleDelete("presentation", index2)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        Profile?.accomplishments[0]?.patent?.length > 0
                          ? "container mx-auto mt-8 editprfileaccomplishment"
                          : "container mx-auto mt-8  profileaccomplishments"
                      }
                    >
                      <div className="employmentmain">
                        <div className="flex justify-between items-center employment">
                          <h2 className="md:text-base font-medium">Patent</h2>
                          <button
                            className="px-4 py-2 rounded-md"
                            onClick={handlAddPatent}
                          >
                            + Add
                          </button>
                        </div>
                        {Profile?.accomplishments[0]?.patent?.length > 0 ? (
                          <div className="p-6">
                            {Profile?.accomplishments[0]?.patent?.map(
                              (patent, index3) => (
                                <div
                                  id={"patent_" + index3}
                                  key={patent?.title}
                                  className="md:flex justify-between cardinneraccom"
                                >
                                  <div>
                                    <h3 className="text-lg font-medium">
                                      {patent?.title}
                                    </h3>
                                    <p className="text-gray-700">
                                      {patent?.patent_number}
                                    </p>
                                    <p className="text-gray-700">
                                      {patent?.date_filed}
                                    </p>
                                    <p className="text-gray-700">
                                      {patent?.inventor}
                                    </p>
                                    <p className="text-gray-700">
                                      {patent?.description}
                                    </p>
                                    <p className="text-gray-700">
                                      {patent?.url}
                                    </p>
                                  </div>
                                  <div className="flex justify-end mt-4 space-x-2 iconemployement">
                                    <div className="iconemployementsvg1">
                                      <FeatherIcon
                                        icon="edit-2"
                                        onClick={(e) =>
                                          handleEditPatent(
                                            "patent",
                                            index3,
                                            patent
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="iconemployementsvg2">
                                      <FeatherIcon
                                        icon="trash-2"
                                        onClick={(e) =>
                                          handleDelete("patent", index3)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        Profile?.accomplishments[0]?.certification?.length > 0
                          ? "container mx-auto mt-8 editprfileaccomplishment"
                          : "container mx-auto mt-8  profileaccomplishments"
                      }
                    >
                      <div className="employmentmain">
                        <div className="flex justify-between items-center employment">
                          <h2 className="md:text-base font-medium">
                            Certification
                          </h2>
                          <button
                            className="px-4 py-2 rounded-md"
                            onClick={handleCertification}
                          >
                            + Add
                          </button>
                        </div>
                        {Profile?.accomplishments[0]?.certification?.length >
                        0 ? (
                          <div className="md:p-6 p-3">
                            {Profile?.accomplishments[0]?.certification?.map(
                              (certification, index4) => (
                                <div
                                  id={"certification_" + index4}
                                  key={certification?.certificate_name}
                                  className="md:flex justify-between cardinneraccom"
                                >
                                  <div>
                                    <h3 className="text-lg font-medium">
                                      {certification?.certificate_name}
                                    </h3>
                                    <p className="text-gray-700">
                                      {certification?.issuing_of_organization}
                                    </p>
                                    <p className="text-gray-700">
                                      {certification?.issue_date}
                                    </p>{" "}
                                    <p className="text-gray-700">
                                      {certification?.expirattion_date}
                                    </p>
                                    <p className="text-gray-700">
                                      {certification?.certification_id}
                                    </p>
                                    <p className="text-gray-700">
                                      {certification?.url}
                                    </p>
                                    <p className="text-gray-700">
                                      {certification?.description}
                                    </p>
                                  </div>
                                  <div className="flex justify-end mt-4 space-x-2 iconemployement">
                                    <div className="iconemployementsvg1">
                                      <FeatherIcon
                                        icon="edit-2"
                                        onClick={(e) =>
                                          handleEditCertification(
                                            "certification",
                                            index4,
                                            certification
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="iconemployementsvg2">
                                      <FeatherIcon
                                        icon="trash-2"
                                        onClick={(e) =>
                                          handleDelete("certification", index4)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container mx-auto mt-8 Careerprofile">
                  <div className="employmentmain">
                    <h2 className="text-xl font-semibold mb-4 employment">
                      Career profile
                    </h2>
                    <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
                      <p className="mb-6">
                        Add details about your current and preferred job
                        profile. This helps us personalise your job
                        recommendations.
                      </p>
                      <form className="animated-form">
                        <div className="grid grid-cols-1 gap-6  md:grid-cols-2">
                          <div className="relative job-search-label-section singleselect2 mt-1">
                            <Select2
                              id="industry"
                              name="industry"
                              value={Profile?.careerProfile?.industry || ""}
                              onChange={(e) => handleIndustryChange(e)}
                              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                              data={jobSectors.map((sector) => ({
                                id: sector.value,
                                text: sector.label,
                              }))}
                              options={{
                                placeholder: "Select Industry",
                                theme: "classic",
                                width: "resolve",
                              }}
                            />
                            <label
                              htmlFor="industry"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Current Industry
                            </label>
                          </div>

                          <div className="relative job-secrch-lable-section mt-1">
                            <SearchableDropdown
                              className="categoriesdropdown"
                              id="categories"
                              name="categories"
                              multiple={true}
                              defaultValue={
                                Profile?.careerProfile?.categories || []
                              } // Default selected categories
                              onChange={(selectedOptions) =>
                                handleSelectChange2(
                                  selectedOptions,
                                  "categories"
                                )
                              }
                              options={categories.map((category) => ({
                                value: category.value, // Use category.value for the value
                                label: category.label, // Use category.label for the label
                              }))}
                              placeholder="Select Categories"
                            />
                            <label
                              htmlFor="categories"
                              className="block text-sm font-medium py-1 text-gray-700 multipleselectlabelskill"
                            >
                              Categories
                            </label>
                          </div>
                          <div className="hrp-create-profile">
                            <div className="relative text-justify ">
                              <div className="mt-1 flex flex-wrap ">
                                <Select2
                                  id="skills"
                                  name="skills"
                                  multiple
                                  defaultValue={Profile?.skills}
                                  // data={jobSkills}
                                  data={Profile?.skills}
                                  options={{
                                    placeholder: "",
                                  }}
                                />
                                <label
                                  htmlFor="skills"
                                  className="block text-sm font-medium  multipleselectlabelprofile text-gray-700"
                                >
                                  Skills
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="hrp-create-profile">
                            <div className="relative text-justify ">
                              <div className="mt-1 flex flex-wrap ">
                                <Select2
                                  id="tools"
                                  name="tools"
                                  multiple
                                  onChange={(e) =>
                                    handleSelectChange(e, "tools")
                                  }
                                  defaultValue={Profile?.tools}
                                  // data={jobSkills}
                                  data={tools}
                                  options={{
                                    placeholder: "",
                                  }}
                                />
                                <label
                                  htmlFor="tools"
                                  className="block text-sm font-medium  multipleselectlabelprofile text-gray-700"
                                >
                                  Tools
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="relative job-search-label-section singleselect2">
                            <Select2
                              id="currentJobStatus"
                              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                              name="currentJobStatus"
                              multiple={false}
                              defaultValue={
                                Profile?.careerProfile?.currentJobStatus
                              }
                              data={currentJobStatusOptions}
                              onChange={(e) =>
                                handleSelectChange(e, "currentJobStatus")
                              }
                              options={{
                                placeholder: "",
                                theme: "classic",
                                width: "resolve",
                              }}
                            />
                            <label
                              htmlFor="currentJobStatus"
                              className="block text-sm font-medium text-gray-700 "
                            >
                              Current Job Status
                            </label>
                          </div>
                          <div className="relative job-search-label-section singleselect2">
                            <Select2
                              id="jobAvailability"
                              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                              name="jobAvailability"
                              multiple={false}
                              defaultValue={
                                Profile?.careerProfile?.jobAvailability
                              }
                              data={jobAvailabilityOptions}
                              onChange={(e) =>
                                handleSelectChange(e, "jobAvailability")
                              }
                              options={{
                                placeholder: "",
                                theme: "classic",
                                width: "resolve",
                              }}
                            />
                            <label
                              htmlFor="jobAvailability"
                              className="block text-sm font-medium text-gray-700 "
                            >
                              Job Availability
                            </label>
                          </div>
                          <div className="relative job-search-label-section singleselect2">
                            <Select2
                              id="jobSeekingStatus"
                              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                              name="jobSeekingStatus"
                              multiple={false}
                              defaultValue={
                                Profile?.careerProfile?.jobSeekingStatus
                              }
                              data={jobSeekingStatusOptions}
                              onChange={(e) =>
                                handleSelectChange(e, "jobSeekingStatus")
                              }
                              options={{
                                placeholder: "",
                                theme: "classic",
                                width: "resolve",
                              }}
                            />
                            <label
                              htmlFor="jobSeekingStatus"
                              className="block text-sm font-medium text-gray-700 "
                            >
                              Job Seeking Status
                            </label>
                          </div>
                          <div className="relative job-search-label-section singleselect2">
                            <Select2
                              id="employmentStatus"
                              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                              name="employmentStatus"
                              multiple={false}
                              defaultValue={
                                Profile?.careerProfile?.employmentStatus
                              }
                              data={employmentStatusOptions}
                              onChange={(e) =>
                                handleSelectChange(e, "employmentStatus")
                              }
                              options={{
                                placeholder: "",
                                theme: "classic",
                                width: "resolve",
                              }}
                            />
                            <label
                              htmlFor="employmentStatus"
                              className="block text-sm font-medium text-gray-700 "
                            >
                              Employment Status
                            </label>
                          </div>
                          <div>
                            <label
                              htmlFor="shift"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Preferred shift
                            </label>
                            <div className="mt-1 flex items-center">
                              <input
                                type="radio"
                                name="shift"
                                id="Dayshift"
                                className="h-4 w-4 text-indigo-600 border-gray-300"
                                checked
                              />
                              <label
                                htmlFor="Dayshift"
                                className="ml-2 block text-sm text-gray-900"
                              >
                                Day
                              </label>
                              <input
                                type="radio"
                                name="shift"
                                id="Nightshift"
                                className="ml-4 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label
                                htmlFor="Nightshift"
                                className="ml-2 block text-sm text-gray-900"
                              >
                                Night
                              </label>
                              <input
                                type="radio"
                                name="shift"
                                id="Flexibleshift"
                                className="ml-4 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label
                                htmlFor="Flexibleshift"
                                className="ml-2 block text-sm text-gray-900"
                              >
                                Flexible
                              </label>
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Desired job type
                            </label>
                            <div className="mt-1 flex items-center">
                              <input
                                type="checkbox"
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked
                              />
                              <label className="ml-2 block text-sm text-gray-900">
                                Permanent
                              </label>
                              <input
                                type="checkbox"
                                className="ml-4 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <label className="ml-2 block text-sm text-gray-900">
                                Contractual
                              </label>
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Desired employment type
                            </label>
                            <div className="mt-1 flex items-center">
                              <input
                                type="checkbox"
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked
                              />
                              <label className="ml-2 block text-sm text-gray-900">
                                Full Time
                              </label>
                              <input
                                type="checkbox"
                                className="ml-4 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <label className="ml-2 block text-sm text-gray-900">
                                Part Time
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              id="workloc"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                              placeholder=""
                              defaultValue={
                                Profile?.careerProfile?.preferred_work_location
                              }
                              name="preferred_work_location"
                            />
                            <label
                              htmlFor="workloc"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Preferred work location
                            </label>
                          </div>
                          <div className="form-group">
                            {/* <div className="mt-1 flex items-center "> */}

                            <input
                              id="expectedsalary"
                              type="text"
                              onChange={(e) =>
                                setProfile((prevProfile) => ({
                                  ...prevProfile,
                                  expectedCTC: e.target.value,
                                }))
                              }
                              defaultValue={Profile?.expectedCTC}
                              className=" block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                            {/* </div>  */}
                            <label
                              htmlFor="expectedsalary"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Expected salary
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="container mx-auto mt-8 Careerprofile">
                  <div className="employmentmain">
                    <h2 className="text-xl font-semibold mb-4 employment">
                      Additional Information
                    </h2>
                    <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
                      <p className="mb-6">
                        Add details about your Additional Information job
                        profile. This helps us personalise your job
                        recommendations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="profilesecionButton">
                  <button
                    className="joblist-btnclear"
                    onClick={(e) => handleProfileUpdate(e)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <CustomToastContainer ref={toastRef} />
      </PopupProvider>
    </>
  );
};

export default CandidateProfile;
