import React, { useEffect, useState } from "react";
import { getUserData } from "../../helpers/utils";

const LoginUser = ({ onClose, id }) => {
  const [loading, setLoading] = useState(false);
  const User = getUserData();
  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About"></h1>
              <p>
                It looks like you're currently logged in as an {User?.role}.
                <br></br> To post a job, please log in with an{" "}
                {User?.role == "applicant" ? "Employer" : "Applicant"} account.
              </p>
            </div>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
    </div>
  );
};

export default LoginUser;
