import React, { useState, useRef } from "react";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";

import { getUserData } from "../../helpers/utils";

const Interview = ({ onClose, applicationId }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [mode, setMode] = useState("Online");
  const [location, setLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const User = getUserData();
  const toastRef = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Collecting data from the form
    const formdata = {
      status: "Interview Scheduled",
      interviewDetails: {
        date,
        time,
        location,
        mode,
        notes,
      },
      userId: User?._id,
    };

    try {
      const res = await api.PostApplicationStatus(applicationId, formdata);
      if (res.status === 200) {
        toastRef.current.addToast(
          "Interview scheduled successfully!",
          "success"
        );
        onClose("");
        setError("");
        window.location.reload();
      }
    } catch (err) {
      setError(err.response?.data?.message);

      toastRef.current.addToast(err.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const interviewModes = [
    "In-Person Interview",
    "Phone Interview",
    "Video Interview (Live)",
    "Video Interview (Pre-recorded)",
    "Panel Interview",
    "Group Interview",
    "Technical Interview",
    "Case Study Interview",
    "Assessment Center",
  ];

  const getPlaceholder = () => {
    switch (mode) {
      case "Phone Interview":
        return "Enter the phone number for the interview";
      case "Video Interview (Live)":
      case "Video Interview (Pre-recorded)":
        return "Enter the meeting link";
      case "In-Person Interview":
      case "Panel Interview":
      case "Group Interview":
      case "Technical Interview":
      case "Case Study Interview":
      case "Assessment Center":
        return "Enter the interview location";
      default:
        return "Enter the relevant details";
    }
  };

  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About">
                Schedule Interview
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="schedule-interview-form p-4 "
            >
              <div className="grid grid-cols-2 gap-5">
                <div className="animated-form">
                  <div className="form-group">
                    <input
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      name="interviewDate"
                      className="w-full px-3 py-2 border rounded-md"
                      required
                    />
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Date
                    </label>
                  </div>
                </div>
                <div className="animated-form">
                  <div className="form-group">
                    <input
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      name="interviewTime"
                      className="w-full px-3 py-2 border rounded-md"
                      required
                    />
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Time
                    </label>
                  </div>
                </div>
              </div>

              <div className="animated-form">
                <div className="form-group">
                  <select
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    name="interviewMode"
                    className="w-full px-3 py-2 border rounded-md"
                    required
                  >
                    {interviewModes.map((modeOption, index) => (
                      <option key={index} value={modeOption}>
                        {modeOption}
                      </option>
                    ))}
                  </select>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Mode
                  </label>
                </div>
              </div>

              <div className="animated-form">
                <div className="form-group">
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    name="interviewLocation"
                    className="w-full px-3 py-2 border rounded-md"
                    placeholder={getPlaceholder()}
                    required
                  />
                </div>
              </div>

              <div className="animated-form">
                <div className="form-group">
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    name="interviewNotes"
                    className="w-full px-3 py-2 border rounded-md"
                    placeholder=""
                    rows={4}
                  ></textarea>
                  <label className="block text-sm font-bold mb-2 description-label">
                    Additional Notes
                  </label>
                </div>
              </div>

              <button
                type="submit"
                className="w-full login-form-btn text-white font-bold py-3 px-4 "
                disabled={loading}
              >
                {loading ? "Scheduling..." : "Schedule Interview"}
              </button>

              {error && <p className="mt-4 text-red-500 text-sm">{error}</p>}
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Interview;
