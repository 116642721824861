import React, { useEffect, useState, useRef } from "react";
import { submitform } from "../../helpers/Formhelper";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";

import FeatherIcon from "feather-icons-react";
const EditStaff = ({ onClose, id }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [role, setrole] = useState([
    "superadmin",
    "employer",
    "applicant",
    "staff",
    "seller",
  ]);
  const [error, setError] = useState("");
  const User = getUserData();
  const toastRef = useRef();
  useEffect(() => {
    if (id !== undefined) {
      setLoading(true);
      async function fetchStaffById() {
        try {
          const response = await api.getUserByid(id);
          if (response.status !== 500) {
            setFormData(response);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
      fetchStaffById();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, confirmPassword: value });
    if (value !== formData.password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedData = { ...formData };

    try {
      const response = await api.UpdateUser(id, updatedData);
      if (response.status === 200) {
        toastRef.current.addToast("User Updated Successfully", "success");
        setLoading(false);
        setTimeout(() => {
          onClose("");
        }, 900);
      } else {
        console.error("Error updating staff:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating staff:", error);
      setLoading(false);
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About">
                Edit User Account
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              id="staffcreate"
              className="register-form-section animated-form"
            >
              <div className="mb-2 grid grid-cols-2 gap-x-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={
                      formData.firstName != "" &&
                      formData.firstName != undefined
                        ? formData.firstName
                        : formData.name
                    }
                    onChange={handleInputChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="firstName" className="form-input-label">
                    First Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="lastName" className="form-input-label">
                    Last Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="email" className="form-input-label">
                    Email
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    maxLength={13}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="phone" className="form-input-label">
                    Phone
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    // value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="password" className="form-input-label">
                    Password
                  </label>
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? (
                      // Eye Slash Icon
                      <FeatherIcon
                        icon="eye-off"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      // Eye Icon
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="confirm-password"
                    name="confirmPassword"
                    // value={formData.confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label
                    htmlFor="confirm-password"
                    className="form-input-label"
                  >
                    Confirm Password
                  </label>
                </div>
              </div>
              {error && (
                <p className="text-red-500 text-xs italic hrp-password-error ">
                  {error}
                </p>
              )}
              <div className="form-group">
                <select
                  id="role"
                  name="role"
                  className="shadow w-full border rounded py-2 px-3"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {role.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
                <label htmlFor="role" className="form-input-label">
                  Role
                </label>
              </div>

              <div className="flex ">
                <button
                  type="submit"
                  className="hrp-find-course-btn py-2 px-10 rounded-full w-full"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default EditStaff;
