import React, { useState, useEffect } from "react";
import SearchableDropdown from "../../../helpers/SearchableDropdown"; // Importing react-select
import api from "../../../Api";
import { getUserData } from "../../../helpers/utils";

const EditUser = ({ onClose, onSave }) => {
  const [profiles, setProfiles] = useState([]); // To store profile data
  const [selectedProfile, setSelectedProfile] = useState(null); // To store selected profile

  // Fetch profiles from the API
  async function getProfileAll() {
    try {
      const User = getUserData();
      const response = await api.getprofileByUserid(User._id);
      if (response.status !== 400) {
        // Assuming response.data contains an array of profiles
        const profileOptions = response.map((profile) => ({
          id: profile._id,
          text: `${profile.firstName} ${profile.lastName}`, // Display full name in dropdown
        }));
        setProfiles(profileOptions);
      }
    } catch (error) {
      console.error(error);
    }
  }
  var domainpath = process.env.REACT_APP_DOMAIN;
  // Fetch profile details based on the selected profile ID and trigger onSave
  const handleProfileSelection = (selectedOption) => {
    // No need for e.target.value, just directly use selectedOption
    //console.log(selectedOption);

    if (selectedOption) {
      const foundProfile = profiles.find(
        (profile) => profile.id === selectedOption
      ); // Match the selected profile by value (id)
      if (foundProfile) {
        setSelectedProfile(foundProfile); // Update the selected profile state
        onClose(""); // Close the dropdown
        // Slight delay before navigating
        setTimeout(() => {
          window.location.href = `${domainpath}/dashboard/userprofile/${foundProfile.id}`;
        }, 100);
      }
    }
  };
  useEffect(() => {
    getProfileAll(); // Fetch all profiles on component mount
  }, []);

  return (
    <div className="flex justify-center h-screen m-auto p-20 job-page">
      <div className="flex">
        <div className="form_upto jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <div className="hrp-heading-job">
              <h1 className="hrp-heading-size text-center hrp-About">
                Select Profile
              </h1>
            </div>
            <form className="register-form-section animated-form p-6 mx-auto rounded">
              {/* Profile Selection Dropdown */}
              <div className="form-group">
                <SearchableDropdown
                  id="profile"
                  name="profile"
                  className="categoriesdropdown"
                  value={selectedProfile}
                  onChange={handleProfileSelection} // Handle profile selection
                  // data={profiles}
                  options={profiles.map((sector) => ({
                    value: sector.id,
                    label: sector.text,
                  }))} // Set options for the dropdown
                  placeholder="Select a Profile"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                      ...theme.colors,
                      primary25: "lightblue",
                      primary: "blue",
                    },
                  })}
                />{" "}
                <label
                  htmlFor="profile"
                  className="block text-sm font-medium text-gray-700 SearchableDropdown"
                >
                  Select Profile
                </label>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="hrp-btn text-white py-3 px-10 rounded-full"
                  onClick={() => onClose("")}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
