import React, { useEffect, useState, useRef } from "react";
import FeatherIcon from "feather-icons-react";
import { initialState } from "../../helpers/Object";
const QualificationsTable = ({ userqualification, onQualificationsChange }) => {
  const [qualifications, setQualifications] = useState([]);
  useEffect(() => {
    if (userqualification && userqualification.length > 0) {
      setQualifications(userqualification);
    } else {
      setQualifications(initialState);
    }
  }, [userqualification]);
  const triggerQualificationsChange = (updatedQualifications) => {
    if (onQualificationsChange) {
      onQualificationsChange(updatedQualifications);
    }
  };

  const handleInputChange = (qIndex, dIndex, field, value) => {
    const updatedQualifications = [...qualifications];
    if (field === "qualification") {
      updatedQualifications[qIndex].qualification = value;
    } else if (field === "details") {
      updatedQualifications[qIndex].details[dIndex].details = value;
    } else if (field === "level_of_qualification") {
      updatedQualifications[qIndex].details[dIndex].level_of_qualification =
        value;
    }
    setQualifications(updatedQualifications);
    triggerQualificationsChange(updatedQualifications);
  };
  const addRow = (qIndex, e) => {
    e.preventDefault();
    const updatedQualifications = [...qualifications];
    updatedQualifications[qIndex].details.push({
      details: "",
      level_of_qualification: "",
    });
    setQualifications(updatedQualifications);
    triggerQualificationsChange(updatedQualifications);
  };
  const removeRow = (qIndex, dIndex, e) => {
    e.preventDefault();

    const updatedQualifications = [...qualifications];
    updatedQualifications[qIndex].details.splice(dIndex, 1);
    if (updatedQualifications[qIndex].details.length === 0) {
      updatedQualifications[qIndex].details.push({
        details: "",
        level_of_qualification: "",
      });
    }
    setQualifications(updatedQualifications);
    triggerQualificationsChange(updatedQualifications);
  };
  const addQualificationBlock = (e) => {
    e.preventDefault();
    const updatedQualifications = [...qualifications];
    updatedQualifications.push({
      qualification: "New Qualification",
      details: [{ details: "", level_of_qualification: "" }],
    });
    setQualifications(updatedQualifications);
    triggerQualificationsChange(updatedQualifications);
  };

  const removeQualificationBlock = (qIndex, e) => {
    e.preventDefault();
    const updatedQualifications = [...qualifications];
    if (updatedQualifications.length > 1) {
      updatedQualifications.splice(qIndex, 1);
      setQualifications(updatedQualifications);
      triggerQualificationsChange(updatedQualifications);
    }
  };

  return (
    <div>
      <table style={styles.table}>
        <thead>
          <tr style={styles.rowstyle}>
            <th style={styles.header}>Category</th>
            <th style={styles.header}>Details</th>
            <th style={styles.header}>Level of Qualification</th>
          </tr>
        </thead>
        <tbody>
          {qualifications?.map((qualification, qIndex) => (
            <React.Fragment key={qIndex}>
              {qualification?.details.map((detail, dIndex) => (
                <tr key={dIndex} style={styles.rowstyle}>
                  {dIndex === 0 && (
                    <td
                      rowSpan={qualification.details.length}
                      style={styles.categoryCell}
                    >
                      <div
                        style={styles.text}
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) =>
                          handleInputChange(
                            qIndex,
                            dIndex,
                            "qualification",
                            e.currentTarget.textContent,
                            e
                          )
                        }
                      >
                        <p style={styles.categoryText}>
                          {qualification.qualification}
                        </p>
                      </div>
                    </td>
                  )}
                  <td style={styles.cell}>
                    <div
                      style={styles.text2}
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) =>
                        handleInputChange(
                          qIndex,
                          dIndex,
                          "details",
                          e.currentTarget.textContent,
                          e
                        )
                      }
                    >
                      {detail.details}
                    </div>
                  </td>
                  <td style={styles.crossWithTd}>
                    <div
                      style={styles.text2}
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) =>
                        handleInputChange(
                          qIndex,
                          dIndex,
                          "level_of_qualification",
                          e.currentTarget.textContent,
                          e
                        )
                      }
                    >
                      {detail.level_of_qualification}
                    </div>
                    <div style={styles.borderButtons}>
                      <button
                        onClick={(e) => removeRow(qIndex, dIndex, e)}
                        style={styles.iconButton}
                      >
                        <FeatherIcon icon="x-circle" color="red" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              <tr style={styles.rowstyle}>
                <td colSpan="4" style={{ position: "relative" }}>
                  <div style={styles.addRowIconWrapper}>
                    <button
                      onClick={(e) => addRow(qIndex, e)}
                      style={styles.addRowIcon}
                    >
                      <FeatherIcon
                        style={{ width: "16px", height: "16px" }}
                        icon="plus"
                      />
                    </button>
                  </div>
                </td>
              </tr>
              <tr style={{ position: "relative" }}>
                <td
                  rowSpan={qualification.details.length}
                  style={styles.deleteQualificationCell}
                >
                  <button
                    onClick={(e) => removeQualificationBlock(qIndex, e)}
                    style={styles.iconButton}
                  >
                    <FeatherIcon icon="trash-2" color="red" />
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "inherit",
          margin: "10px",
        }}
      >
        <button
          onClick={(e) => addQualificationBlock(e)}
          style={styles.addButton}
        >
          <FeatherIcon style={{ width: "16px", height: "16px" }} icon="plus" />
          Add More
        </button>
      </div>
    </div>
  );
};

const styles = {
  table: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #C6C6C6",
  },
  rowstyle: {
    borderBottom: "1px solid #C6C6C6",
  },
  header: {
    borderRight: "1px solid #C6C6C6",
    padding: "8px 20px",
    color: "#003366",
    backgroundColor: "#F1F4F7",
    fontWeight: "500",
    textAlign: "left",
  },
  categoryCell: {
    borderRight: "1px solid #C6C6C6",
    padding: "8px",
    fontWeight: "bold",
    verticalAlign: "top",
    backgroundColor: "#f9f9f9",
    width: "20%",
  },
  cell: {
    borderRight: "1px solid #C6C6C6",
    padding: "8px",
    width: "30%",
    verticalAlign: "top",
    position: "relative",
  },
  deleteQualificationCell: {
    padding: "10px",
    verticalAlign: "middle",
    textAlign: "center",
    position: "absolute",
    right: "-6%",
    bottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    padding: "5px",
    cursor: "text",
    color: "#3E3E3E",
    fontWeight: "500",
    fontSize: "14px",
  },
  text2: {
    padding: "5px",
    cursor: "text",
    color: "#3E3E3E",
    fontWeight: "400",
    fontSize: "14px",
  },
  borderButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    border: "none",
    cursor: "pointer",
    background: "#fff",
  },
  crossWithTd: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "top",
    justifyContent: "space-between",
    padding: "8px",
  },
  addRowIconWrapper: {
    position: "absolute",
    bottom: "-12px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    zIndex: "999",
  },
  addRowIcon: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    background: "green",
    borderRadius: "30px",
  },
  addButton: {
    padding: "5px 10px",
    border: "none",
    borderRadius: "40px",
    background: "rgb(0 51 102)",
    color: "rgb(255, 255, 255)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
};

export default QualificationsTable;
