import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import ApplyJobs from "../popup/Applyjob";
import { usePopup } from "../Common/PopupContext";
import CustomToastContainer from "../Common/CustomToastContainer";

const domainpath = process.env.REACT_APP_DOMAIN;
const Savedjobs = () => {
  const { openPopup } = usePopup();
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    const userid = User._id;
    async function getjobs() {
      try {
        const response = await api.saveAllJob(userid);
        if (response.status !== 400) {
          setData(response.savedJobs);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
      }
    }
    getjobs();
  }, []);
  const handleClosePopup = (message) => {
    openPopup();
  };
  const jobApply = (jobid) => {
    const popup = (
      <ApplyJobs
        onClose={() => handleClosePopup("Popup closed")}
        JobId={jobid}
      />
    );
    openPopup(popup);
  };
  const handleUnsavedJOb = async (e, jobid) => {
    var id = User?._id;
    e.preventDefault();
    try {
      const res = await api.unsavedJOb(id, jobid);
      if (res.status === 200) {
        toastRef.current.addToast(res.message, "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);

        setLoading(false);
      } else {
        console.error("Error updating staff:", res.message);
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <div>
          <div>
            {" "}
            <a
              href={domainpath + "/jobs/" + row?.jobId}
              target="_blank"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.title}
            </a>
          </div>
          <div>{row.company}</div>
        </div>
      ),
      sortable: true,
      width: "33%",
    },
    {
      name: "Saved Date",
      selector: (row) => new Date(row.savedDate).toLocaleDateString(),
      sortable: true,
      width: "33%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="xl:flex block gap-1 xl:py-0 py-2 m-auto">
          <button
            className="manage-edit-btn text-white xl:px-8 md:px-8 lg:px-6 px-8 py-2"
            onClick={(e) => jobApply(row.jobId)}
          >
            Apply
          </button>
          <button
            className="manage-delete-btn text-white xl:px-4 px-6 lg:px-4 py-2 mt-1 xl:mt-0"
            onClick={(e) => handleUnsavedJOb(e, row?.jobId)}
          >
            Remove
          </button>
        </div>
      ),
      width: "34%",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Manage Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Savedjobs;
