import React, { useState, useEffect, useRef } from "react";

const EditUser = ({ onClose, onSave, initialValues }) => {
  const [formData, setFormData] = useState({
    salutation: "Mr.",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    experience: "",
    expectedCTC: "",
    noticePeriod: "",
  });
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "uk" },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setInputValue(place.formatted_address);
        setFormData((prevFormData) => ({
          ...prevFormData,
          location: place.formatted_address,
        }));
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, []);

  function formatPhoneNumber(value) {
    const cleaned = ("" + value).replace(/\D/g, "");
    const limited = cleaned.substring(0, 12);
    if (limited.length >= 10) {
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6,
        10
      )}`;
    } else if (limited.length > 6) {
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6
      )}`;
    } else if (limited.length > 3) {
      return `${limited.slice(0, 3)}-${limited.slice(3)}`;
    } else {
      return limited;
    }
  }

  useEffect(() => {
    if (initialValues) {
      setInputValue(initialValues?.location);
      setFormData((prevFormData) => ({
        ...prevFormData,
        salutation: initialValues.salutation || "Mr.",
        firstName: initialValues.firstName || "",
        lastName: initialValues.lastName || "",
        email: initialValues.email || "",
        phone: initialValues.phone || "",
        location: initialValues.location || "",
        experience: initialValues.experience || "",
        expectedCTC: initialValues.expectedCTC || "",
        noticePeriod: initialValues.noticePeriod || "",
        careerProfile: {
          ...prevFormData.careerProfile,
          industry: initialValues.careerProfile?.industry || "",
          department: initialValues.careerProfile?.department || "",
          role: initialValues.careerProfile?.role || "",
          jobType: initialValues.careerProfile?.jobType || "",
          desired_jobtype: initialValues.careerProfile?.desired_jobtype || "",
          employmentType: initialValues.careerProfile?.employmentType || "",
          shift: initialValues.careerProfile?.shift || "",
          preferred_work_location:
            initialValues.careerProfile?.preferred_work_location || "",
          expectedSalary: initialValues.careerProfile?.expectedSalary || "",
          currentSalary: initialValues.careerProfile?.currentSalary || "",
          jobAvailability: initialValues.careerProfile?.jobAvailability || "",
        },
        ...initialValues,
      }));
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" || name === "alternateContact") {
      const formattedNumber = value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedNumber,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
  };

  const salutationOptions = ["Mr.", "Mrs.", "Ms.", "Dr."];

  return (
    <div className="flex justify-center h-screen m-auto p-20 job-page">
      <div className="flex">
        <div className="form_upto jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <div className="hrp-heading-job">
              <h1 className="hrp-heading-size text-center hrp-About">
                Edit Profile
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="register-form-section animated-form p-6 mx-auto rounded"
            >
              <div className="grid grid-cols-2 gap-4">
                {/* First Row */}
                <div className="flex justify-between gap-2">
                  <div className="job-search-label-section w-24 mb-5">
                    <label
                      htmlFor="salutation"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mr./Miss
                    </label>
                    <select
                      id="salutation"
                      className=" h-full block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                      name="salutation"
                      value={formData.salutation}
                      onChange={handleChange}
                    >
                      {salutationOptions.map((salutation, index) => (
                        <option key={index} value={salutation}>
                          {salutation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group w-full relative mb-4">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder=" "
                      className="shadow w-full border rounded py-2 px-3"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                    <label
                      htmlFor="firstName"
                      className="form-input-label absolute left-3 -top-3.5 text-sm text-gray-500 bg-white px-1"
                    >
                      First Name
                    </label>
                  </div>
                </div>

                <div className="form-group w-full relative mb-4">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder=" "
                    className="shadow w-full border rounded py-2 px-3"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="lastName"
                    className="form-input-label absolute left-3 -top-3.5 text-sm text-gray-500 bg-white px-1"
                  >
                    Last Name
                  </label>
                </div>

                {/* Second Row */}
                <div className="form-group relative mb-4">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder=" "
                    className="shadow w-full border rounded py-2 px-3"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="email"
                    className="form-input-label absolute left-3 -top-3.5 text-sm text-gray-500 bg-white px-1"
                  >
                    Email
                  </label>
                </div>

                <div className="form-group relative mb-4">
                  <input
                    id="phone"
                    name="phone"
                    placeholder=" "
                    className="shadow w-full border rounded py-2 px-3"
                    value={formData.phone}
                    type="tel"
                    maxLength={15}
                    // inputMode="tel"
                    // pattern="\d{3}-\d{3}-\d{4}"
                    // title="Phone number should be in the format: 123-456-7890"
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="phone"
                    className="form-input-label absolute left-3 -top-3.5 text-sm text-gray-500 bg-white px-1"
                  >
                    Phone
                  </label>
                </div>

                {/* Third Row */}
                <div className="form-group relative mb-4">
                  <input
                    ref={inputRef}
                    type="text"
                    id="location"
                    name="location"
                    className="no-border p-1 mr-2 w-full no-border appearance-none text-base/8"
                    placeholder="Where"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <label
                    htmlFor="location"
                    className="form-input-label absolute left-3 -top-3.5 text-sm text-gray-500 bg-white px-1"
                  >
                    Location
                  </label>
                </div>

                <div className="form-group relative mb-4">
                  <input
                    type="tel"
                    id="alternateContact"
                    name="alternateContact"
                    placeholder=" "
                    className="shadow w-full border rounded py-2 px-3"
                    value={formData.alternateContact}
                    onChange={handleChange}
                    maxLength={13}
                  />
                  <label
                    htmlFor="alternateContact"
                    className="form-input-label absolute left-3 -top-3.5 text-sm text-gray-500 bg-white px-1"
                  >
                    Alternate Contact
                  </label>
                </div>

                {/* New Fields */}
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="hrp-btn text-white py-3 px-10 rounded-full "
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default EditUser;
