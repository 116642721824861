import React, { useEffect, useState, useRef } from "react";
import api from "../../Api";
import { useLocation, useHistory } from "react-router-dom";
import { usePopup } from "../Common/PopupContext";
import { encryptData, decryptData } from "../../helpers/encryption";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";
const Success = ({ message }) => (
  <div className="  flex flex-col items-center justify-center  m-auto bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
    <div className="text-center">
      <h1 className="text-2xl font-semibold text-blue-900">
        Application Reviewed
      </h1>
      <p className="mt-2 text-gray-700">
        We have sent an email. Click the link to confirm the email address.
      </p>
      <div className="mt-4 flex justify-center">
        <svg
          width="132"
          height="132"
          viewBox="0 0 132 132"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.9997 132C77.9228 132.001 89.6235 128.772 99.8586 122.657C110.094 116.541 118.481 107.767 124.129 97.2665C129.778 86.7662 132.476 74.932 131.938 63.0211C131.4 51.1101 127.645 39.5675 121.073 29.6193L68.603 87.9193C66.1522 90.6431 62.7706 92.3507 59.1238 92.7063C55.477 93.0619 51.8292 92.0396 48.8984 89.8407L24.933 71.8667C23.3771 70.6997 22.3485 68.9625 22.0734 67.0371C21.7984 65.1117 22.2994 63.1559 23.4664 61.6C24.6333 60.0441 26.3706 59.0154 28.296 58.7404C30.2213 58.4653 32.1771 58.9664 33.733 60.1333L57.6984 78.1073L111.569 18.26C103.765 10.8101 94.2534 5.38707 83.8674 2.46563C73.4814 -0.455814 62.5375 -0.786544 51.994 1.5024C41.4505 3.79134 31.6289 8.63015 23.3892 15.5952C15.1494 22.5602 8.74282 31.439 4.73028 41.4542C0.717743 51.4694 -0.778357 62.3155 0.372976 73.043C1.52431 83.7705 5.28796 94.0522 11.3344 102.988C17.3808 111.923 25.5257 119.24 35.0558 124.298C44.5859 129.356 55.2106 132.001 65.9997 132Z"
            fill="#4B8700"
          />
        </svg>
      </div>
    </div>
  </div>
);
const CandidateApply = ({ onClose, profileid }) => {
  const { openPopup } = usePopup();
  // const [popupComponent, setPopupComponent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // State to manage success message visibility
  const [successMessage, setSuccessMessage] = useState(""); // State to store success message
  const [Profile, setProfile] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const history = useHistory();
  const toastRef = useRef();
  const handleConfirmPasswordChange = async (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    async function getProfileid() {
      try {
        const response = await api.getProfileId(profileid);
        if (response.status !== 500) {
          setProfile(response);
        } else if (response.status == 500) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getProfileid();
  }, []);
  // --------------- profile card end -----------------------------------------------------
  const handleClosePopup = (message) => {
    //console.log(message);
    //setPopupComponent(null);
    openPopup();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const profiledata = { email, password, name: firstName, phone };
      const response = await api.updateProfileById(profileid, profiledata);
      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        const { accessToken, refreshToken, _id, role } = response;
        const encryptedUserData = encryptData(response.user);
        localStorage.setItem("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        setTimeout(() => {
          setLoading(false);
          window.location.href = `/dashboard/userprofile/${profileid}`;
        }, 1000);
      } else if (response.status == 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toastRef.current.addToast(error, "error");
      setLoading(false);
    }
    // Optionally, clear the form or redirect the user
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width  m-3 loginDiv" id="step_1">
          {success ? (
            <Success message={successMessage} />
          ) : (
            <>
              <div className="bg-white shadow-md rounded py-12 px-12 overflow-scroll  h-4/6 overflow-x-hidden custom-scrollbar">
                <div className="w-full">
                  <div className="hrp-heading-job ">
                    <h1>Create Password</h1>
                  </div>
                  <form
                    className="register-form-section animated-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-2 grid grid-cols-2 gap-x-5">
                      <div className="form-group">
                        <input
                          type="text"
                          id="first-name"
                          defaultValue={Profile?.firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder=""
                          className="shadow w-full border rounded py-2 px-3"
                        />
                        <label
                          htmlFor="first-name"
                          className="form-input-label"
                        >
                          First Name
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          defaultValue={Profile?.lastName}
                          id="last-name"
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder=""
                          className="shadow w-full border rounded py-2 px-3"
                        />
                        <label htmlFor="last-name" className="form-input-label">
                          Last Name
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        autoComplete="off"
                        value={Profile?.email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="email" className="form-input-label">
                        Email
                      </label>
                    </div>
                    <div className="mb-2 grid grid-cols-2 gap-x-5">
                      <div className="form-group">
                        <input
                          type="password"
                          id="password"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder=""
                          className="shadow w-full border rounded py-2 px-3"
                        />
                        <label htmlFor="password" className="form-input-label">
                          Password
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          id="cpassword"
                          placeholder=""
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          className="shadow w-full border rounded py-2 px-3"
                        />
                        <label htmlFor="cpassword" className="form-input-label">
                          Confirm Password
                        </label>
                      </div>
                    </div>
                    <div className="terms-policy my-5">
                      I agree <a href=""> Terms condation </a> &{" "}
                      <a href=""> Privacy Policy</a>
                    </div>

                    <div>
                      <div className="flex justify-between gap-x-5">
                        <div className="w-full">
                          <button className="hrp-btn w-full flex items-center">
                            Confirm &nbsp;
                            <svg
                              width="35"
                              height="16"
                              viewBox="0 0 35 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M34.7071 8.70711C35.0976 8.31658 35.0976 7.68342 34.7071 7.29289L28.3431 0.928932C27.9526 0.538408 27.3195 0.538408 26.9289 0.928932C26.5384 1.31946 26.5384 1.95262 26.9289 2.34315L32.5858 8L26.9289 13.6569C26.5384 14.0474 26.5384 14.6805 26.9289 15.0711C27.3195 15.4616 27.9526 15.4616 28.3431 15.0711L34.7071 8.70711ZM0 9H34V7H0V9Z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-10">
          <button
            className="   text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 close-btn-style"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};
export default CandidateApply;
