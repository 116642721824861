// AOS.js
import React, { useEffect, useRef } from "react";
import "../animations.css";

const AOS = ({ children, animation, duration = "0.6s" }) => {
  const ref = useRef();

  const handleScroll = () => {
    const element = ref.current;
    const rect = element.getBoundingClientRect(); // Log for debugging

    // Check if the element is in the viewport
    if (rect.top < window.innerHeight && rect.bottom > 0) {
      // Log when adding the animation
      element.classList.add("aos-animate");
      element.setAttribute("data-aos", animation);
    } else {
      // Log when removing the animation
      element.classList.remove("aos-animate");
      element.removeAttribute("data-aos");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check if the element is already in view

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};

export default AOS;
