import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const Managejobs = () => {
  let paramdata = useParams();
  const [data, setdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [statusCounts, setStatusCounts] = useState({});
  const [render, setRender] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toastRef = useRef();
  const statuses = [
    "All",
    "Awaiting Review",
    "Reviewed",
    "Shortlisted",
    "Interview Scheduled",
    "Interviewed",
    "In Review",
    "Offered",
    "Hired",
    "Rejected",
  ];
  const [jobDetails, setJobDetails] = useState({});
  const columns = [
    {
      name: "Applicant Image",
      selector: (row) => {
        if (
          !row ||
          !row.profieimg ||
          row.profieimg === "null" ||
          row.profieimg === "NA" ||
          row.profieimg === "N/A" ||
          row.profieimg === ""
        ) {
          return (
            <img
              className="image"
              width={60}
              style={{ borderRadius: "40px" }}
              height={60}
              src={`${domainpath}/uploads/deafultman.png`}
              alt="Default"
            />
          );
        }

        const profileImagePath = row.profieimg.startsWith("https://")
          ? row.profieimg
          : `${domainpath}/uploads/${row.profieimg
              .replace("/uploads/uploads/", "/uploads/")
              .replace(/^\/uploads\//, "")}`;

        return (
          <img
            className="image"
            width={60}
            style={{ borderRadius: "40px" }}
            height={60}
            src={profileImagePath}
            alt="Featured"
            onError={(e) => {
              e.target.onerror = null; // Prevents looping in case the default image fails too
              e.target.src = `${domainpath}/uploads/deafultman.png`; // Set default image on error
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: "Applicant  Name",

      selector: (row) => (
        <a
          href={`${domainpath}/profile/${row?.profie}`}
          className=" hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.applicantname}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Application Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Score",
      selector: (row) => {
        let color;
        if (row?.score < 60) {
          color = "font-semibold text-lg text-red-500";
        } else if (row?.score < 80) {
          color = "font-semibold text-lg text-yellow-500";
        } else if (row?.score === "N/A") {
          color = "font-semibold text-lg text-red-500";
        } else {
          color = "font-semibold text-lg text-green-500";
        }
        return (
          <span className={color}>
            {row?.score !== "N/A" ? row?.score : "0"}%
          </span>
        );
      },
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button
            className=" ManageApplication-View px-6 py-2 text-white "
            onClick={() =>
              history.push(`/dashboard/application/${row?.id}`, row?.profie)
            }
          >
            View Application
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    async function getjobs() {
      try {
        const response = await api.getAllApplicationByJObId(paramdata?.jobid);

        if (response.status === 200) {
          setLoading(false);
          setJobDetails({
            jobTitle: response.jobTitle,
            jobLocation: response.jobLocation,
            company: response.company,
            jobStatus: response.jobStatus,
          });
          // If applications exist, map the data; otherwise, set only the job details
          const data = response.applications.map((application) => ({
            id: application?.applicationId,
            applicantname: application?.applicantId.name,
            profie: application.profileId,
            profieimg: application.profileImg,
            status:
              application.status === "Applied"
                ? "Awating For Review"
                : application.status,
            score: application.score,
          }));

          // Calculate status counts and set data
          calculateStatusCounts(data);
          setdata(data);
          setFilteredData(data);
        } else if (response.status === 400) {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    getjobs();
  }, [render]);

  const tableData = {
    columns,
    data: filteredData,
  };
  const status = [
    { value: "Publish", label: "Published" },
    { value: "Hold", label: "Hold" },
    { value: "Disabled", label: "Disabled" },
    { value: "Closed", label: "Closed" },
    { value: "Draft", label: "Draft" },
    { value: "Block", label: "Block" },
  ];

  const handleUpdateJObStatus = async (e) => {
    setLoading(true);
    var id = paramdata?.jobid;
    e.preventDefault();
    var formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.UpdateJObStatus(id, formdata);

      toastRef.current.addToast(res.message, "success");
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    filterDataByStatus(selectedStatus, data);
    calculateStatusCounts(data); // Always update counts when data changes
  }, [selectedStatus, data]);

  const filterDataByStatus = (selectedStatus, data) => {
    if (selectedStatus === "All") {
      setFilteredData(data); // If 'All', show all data
    } else if (selectedStatus === "Awaiting Review") {
      // "Awaiting Review" includes "Applied"
      const filtered = data.filter(
        (application) =>
          application.status === "Awaiting Review" ||
          application.status === "Applied"
      );
      setFilteredData(filtered);
    } else {
      const filtered = data.filter(
        (application) => application.status === selectedStatus
      );
      setFilteredData(filtered); // Set filtered data based on status
    }
  };

  // Function to calculate status counts
  const calculateStatusCounts = (applications) => {
    const counts = {
      All: applications.length,
    };
    statuses.slice(1).forEach((status) => {
      if (status === "Awaiting Review") {
        // "Awaiting Review" includes "Applied"
        counts[status] = applications.filter(
          (application) =>
            application.status === "Awaiting Review" ||
            application.status === "Applied"
        ).length;
      } else {
        counts[status] = applications.filter(
          (application) => application.status === status
        ).length;
      }
    });
    setStatusCounts(counts);
  };
  return (
    <div className="p-8 hrp-manage-job jobapplication">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow ">
        <div className="flex justify-between">
          <div>
            {console.log(jobDetails)}
            {jobDetails?.jobTitle != "" ? (
              <h2 className="text-lg font-bold " style={{ color: "#003366" }}>
                {jobDetails.jobTitle}
              </h2>
            ) : (
              ""
            )}
            {jobDetails.jobLocation !== "N/A" ? (
              <>
                <span> {jobDetails?.jobLocation}</span>
                <br></br>
              </>
            ) : (
              ""
            )}

            {jobDetails?.company != "" ? (
              <span> {jobDetails.company}</span>
            ) : (
              ""
            )}
          </div>
          <div className="relative job-search-label-section singleselect2">
            <Select2
              id="status"
              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
              name="status"
              multiple={false}
              data={status.map((type) => ({
                id: type.value,
                text: type.label,
              }))}
              defaultValue={jobDetails.jobStatus}
              options={{
                placeholder: "Select Job Status",
                theme: "classic",
                width: "resolve",
              }}
              onSelect={(e) => handleUpdateJObStatus(e)}
            />
            <label
              htmlFor="status"
              className="block text-sm font-medium py-1 text-gray-700 multipleselectlabelskill"
            >
              Status
            </label>
          </div>
        </div>
        <div
          className="flex gap-2 mb-4 overflow-x-auto custom-scrollbar"
          style={{ whiteSpace: "nowrap", paddingBottom: "10px" }}
        >
          {statuses.map((status) => (
            <button
              key={status}
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                selectedStatus === status ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => setSelectedStatus(status)}
            >
              {status} ({statusCounts[status] || 0})
            </button>
          ))}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Managejobs;
