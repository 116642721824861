import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
const Savedjobs = () => {
  const User = getUserData();
  const [data, setData] = useState([]);

  useEffect(() => {
    const userid = User._id;
    async function getjobs() {
      try {
        const response = await api.saveAllJob(userid);
        if (response.status !== 400) {
          setData(response.savedJobs);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
      }
    }
    getjobs();
  }, []);
  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <div>
          <div className="font-bold" style={{ color: "#003366" }}>
            {row.title}
          </div>
          <div>{row.company}</div>
        </div>
      ),
      sortable: true,
      width: "40%",
    },
    {
      name: "Alert Date",
      selector: (row) => (
        <div>
          <div>
            {new Date(row.savedDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            {new Date(row.savedDate).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>
      ),
      sortable: true,
      width: "30%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1 m-auto">
          <button className="manage-edit-btn text-white px-2 lg:px-6 py-2">
            View Job
          </button>
        </div>
      ),
      width: "30%",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Manage Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default Savedjobs;
