import React, { useEffect, useState, useRef } from "react";
import { submitform } from "../../helpers/Formhelper";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";

const Inviteprofile = ({ onClose, id }) => {
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [error, setError] = useState("");
  const User = getUserData();
  const [selectedJobs, setSelectedJobs] = useState([]);
  const toastRef = useRef();
  useEffect(() => {
    async function getActiveJob() {
      try {
        const response = await api.getActiveJobByemp(User?._id);
        if (response.status !== 400) {
          setData(response?.jobs);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getActiveJob();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedData = {
      jobIds: selectedJobs,
    };
    try {
      const response = await api.jobInvite(id, updatedData);
      if (response.status === 200) {
        toastRef.current.addToast("Job Invited Successfully", "success");
        setLoading(false);
        setTimeout(() => {
          onClose("");
        }, 800);
      } else {
        console.error("Error updating staff:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating staff:", error);
      setLoading(false);
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  const handleCheckboxChange = (event) => {
    const jobId = event.target.value;
    setSelectedJobs((prevSelectedJobs) =>
      prevSelectedJobs.includes(jobId)
        ? prevSelectedJobs.filter((id) => id !== jobId)
        : [...prevSelectedJobs, jobId]
    );
  };

  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About">
                Active Jobs
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              id="staffcreate"
              className="register-form-section animated-form"
            >
              <div className="job-checkboxes">
                {Data.map((job) => (
                  <div key={job._id} className="job-checkbox">
                    <label>
                      <input
                        style={{ marginRight: "10px" }}
                        type="checkbox"
                        value={job._id}
                        onChange={handleCheckboxChange}
                      />
                      {job.title}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex mt-4">
                <button
                  type="submit"
                  className="hrp-find-course-btn py-2 px-10 rounded-full w-full"
                >
                  Send Invite
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Inviteprofile;
