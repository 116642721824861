import React, { useState } from "react";
import { getUserData } from "../../helpers/utils";
import api from "../../Api";

const AccountSettings = () => {
  const user = getUserData();
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Prepare data for API request
    const userData = {
      email,
      password,
    };

    try {
      // Replace with actual API endpoint
      const response = await api.UpdateAccount(userData);
      if (!response.ok) {
        throw new Error("Failed to update account.");
      }

      // Handle success
      alert("Account updated successfully!");
      setError(null);
    } catch (error) {
      // Handle API errors
      setError(error.message);
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="p-8 account-settings-main">
        <div className="mb-8">
          <h1 className="account-settings">Account Settings</h1>
          <form onSubmit={handleSubmit}>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="email"
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email"
                  required
                />
                <label htmlFor="email" className="profileheadline">
                  Email
                </label>
              </div>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className=" form-group relative">
                <input
                  id="password"
                  className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="password" className="password-label">
                  Password
                </label>

                {/* Eye Icon */}
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-6 transform -translate-y-1/2 cursor-pointer"
                >
                  {showPassword ? (
                    // Eye Slash Icon
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M634.82 471l-49.65-38.94C609.75 411.33 640 366.31 640 320c0-70.68-112.49-192-320-192-49.66 0-92.89 7.73-129.76 19.92L132.16 49.15a16.03 16.03 0 00-22.46 2.58L85.65 83.05a16.03 16.03 0 002.58 22.47l49.64 38.94C30.24 191.36 0 236.38 0 288c0 70.68 112.49 192 320 192 49.66 0 92.89-7.73 129.76-19.92l58.08 45.77a16.03 16.03 0 0022.46-2.58l24.06-31.32a16.03 16.03 0 00-2.58-22.47zM320 160c99.21 0 176.31 54.55 214.36 107.02-4.39 6.63-9.07 13.4-14.28 20.16L444.8 232.27c-12.48-8.2-27.07-13.16-43.23-13.16a80 80 0 00-80 80c0 16.16 4.96 30.75 13.16 43.23l-54.91 75.28C197.69 392.11 144 337.45 105.64 288 126.71 256.28 195.24 160 320 160zm0 192a48 48 0 110-96 48 48 0 010 96z"
                      />
                    </svg>
                  ) : (
                    // Eye Icon
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>
            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <input
                  id="confirmPassword"
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Confirm  password"
                  required
                />
                <label htmlFor="confirmPassword" className="profileheadline">
                  Confirm Password
                </label>
              </div>
            </div>
            {error && <div className="text-red-500">{error}</div>}
            <div>
              <button
                type="submit"
                className="ViewDetails-btn text-white px-4 py-2"
              >
                Update Settings
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
