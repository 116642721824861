import React, { useState, useEffect } from "react";

const Researchpublication = ({
  onClose,
  onSave,
  initialValues,
  category,
  index,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    publish_date: "",
    journal: "",
    abstract: "",
    url: "",
    description: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <div className="hrp-heading-job ">
                <h1 className="hrp-heading-size text-center hrp-About">
                  White paper / Research publication / Journal entry
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form p-6  mx-auto  rounded "
              >
                <div className="form-group mb-4">
                  <input
                    type="text"
                    id="title"
                    placeholder=""
                    value={formData.title}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="title" className="form-input-label">
                    Title
                  </label>
                </div>

                <div className="form-group mb-4">
                  <input
                    type="text"
                    id="author"
                    placeholder=""
                    value={formData.author}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="author" className="form-input-label">
                    Authors
                  </label>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                  <div className="form-group mb-4">
                    <input
                      type="date"
                      id="publish_date"
                      placeholder=""
                      value={formData.publish_date}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="publish_date" className="form-input-label">
                      Publication Date
                    </label>
                  </div>

                  <div className="form-group mb-4">
                    <input
                      type="text"
                      id="journal"
                      placeholder=""
                      value={formData.journal}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="journal" className="form-input-label">
                      Journal/Conference
                    </label>
                  </div>
                </div>

                <div className="form-group mb-4">
                  <input
                    id="abstract"
                    type="text"
                    placeholder=""
                    value={formData.abstract}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    // rows="4"
                  />
                  <label htmlFor="abstract" className="form-input-label">
                    Abstract
                  </label>
                </div>

                <div className="form-group mb-4">
                  <input
                    type="url"
                    id="url"
                    placeholder=""
                    value={formData.url}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="url" className="form-input-label">
                    URL
                  </label>
                </div>

                <div className="form-group mb-4">
                  <textarea
                    id="description"
                    placeholder=""
                    value={formData.description}
                    onChange={handleChange}
                    className=" w-full border rounded-full py-2 px-3"
                    // rows="4"
                  />
                  <label htmlFor="description" className="form-input-label">
                    Description
                  </label>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn text-white py-3 px-10 rounded-full "
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="   text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 close-btn-style"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default Researchpublication;
