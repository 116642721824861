import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";

const domainpath = process.env.REACT_APP_DOMAIN;
const Savedjobs = () => {
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const toastRef = useRef();
  useEffect(() => {
    const userid = User._id;
    const status = "Applied";

    async function getjobs() {
      try {
        const response = await api.getapplications(userid, status);
        if (response.status !== 400) {
          const filteredData = response.filter((row) => row.jobId?.title);
          setData(filteredData);
        } else if (response.status === 400) {
          // Handle 400 status if needed
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }

    getjobs();
  }, []);
  const handleWithdraw = async (jobId) => {
    const formdata = {
      userId: User?._id,
    };
    try {
      const response = await api.jobwithdraw(jobId, formdata);
      if (response.status === 200) {
        toastRef.current.addToast(response.message, "success");
        setData((prevApplications) =>
          prevApplications.filter((application) => application.jobId !== jobId)
        );
        setTimeout(() => {
          window.location.reload();
        }, 1500);

        setLoading(false);
      } else {
        console.error("Error updating staff:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error withdrawing application:", error);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <div>
          <div>
            <a
              href={domainpath + "/jobs/" + row?.jobId?._id}
              target="_blank"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.jobId?.title}
            </a>
          </div>
          <div>{row.jobId?.company.name}</div>
        </div>
      ),
      sortable: true,
      width: "33%",
    },
    // {
    //   name: "Company",
    //   selector: (row) => row.jobId?.company.name,
    //   sortable: true,
    // },
    {
      name: "Applied Date",
      selector: (row) => new Date(row.appliedAt).toLocaleDateString(),
      sortable: true,
      width: "33%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1 m-auto">
          <button
            className="manage-edit-btn text-white px-6 py-2 rounded"
            onClick={(e) => handleWithdraw(row.jobId?._id)}
          >
            Withdraw
          </button>
        </div>
      ),
      width: "34%",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Applied Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Savedjobs;
