import React, { useState } from "react";
import { usePopup } from "../Common/PopupContext";
import Login from "../Website/Login";

const SuccessMessage = ({ onClose, message }) => {
  const { openPopup } = usePopup();
  const [successMessage, setSuccessMessage] = useState("");

  const handleClosePopup = (message) => {
    openPopup(); // Assuming openPopup toggles the popup state
    if (onClose) onClose(message); // Optional: call the provided onClose prop function
  };

  const handleLoginClick = (user) => {
    if (user) {
      const popup = (
        <Login
          onClose={() => handleClosePopup("Popup closed")}
          redirect_url={`/dashboard/profile/${user?.profileid}`}
          data={user}
        />
      );
      openPopup(popup);
    }
  };

  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div className="form_upto jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4 relative">
            {/* Cross icon for close */}
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => handleClosePopup("Closed by cross icon")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="w-4/5 flex flex-col items-center justify-center m-auto bg-white">
              <div className="text-center">
                <h1 className="text-2xl font-semibold text-blue-900">
                  Profile Completion
                </h1>
                <p className="mt-2 text-gray-700">
                  {typeof message === "object" ? message.message : message}
                </p>
                {message.message ===
                "We found an account already associated with your email. Kindly login to continue the Profile completion." ? (
                  <button
                    onClick={() => handleLoginClick(message.user)}
                    className="hrp-find-course-btn rounded-full lg:py-2 joblist-mobile-views lg:w-full md:py-3 md:px-3 px-9 py-2"
                  >
                    Login Here to continue Job Application
                  </button>
                ) : (
                  <div className="mt-4 flex justify-center">
                    <svg
                      width="132"
                      height="132"
                      viewBox="0 0 132 132"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M65.9997 132C77.9228 132.001 89.6235 128.772 99.8586 122.657C110.094 116.541 118.481 107.767 124.129 97.2665C129.778 86.7662 132.476 74.932 131.938 63.0211C131.4 51.1101 127.645 39.5675 121.073 29.6193L68.603 87.9193C66.1522 90.6431 62.7706 92.3507 59.1238 92.7063C55.477 93.0619 51.8292 92.0396 48.8984 89.8407L24.933 71.8667C23.3771 70.6997 22.3485 68.9625 22.0734 67.0371C21.7984 65.1117 22.2994 63.1559 23.4664 61.6C24.6333 60.0441 26.3706 59.0154 28.296 58.7404C30.2213 58.4653 32.1771 58.9664 33.733 60.1333L57.6984 78.1073L111.569 18.26C103.765 10.8101 94.2534 5.38707 83.8674 2.46563C73.4814 -0.455814 62.5375 -0.786544 51.994 1.5024C41.4505 3.79134 31.6289 8.63015 23.3892 15.5952C15.1494 22.5602 8.74282 31.439 4.73028 41.4542C0.717743 51.4694 -0.778357 62.3155 0.372976 73.043C1.52431 83.7705 5.28796 94.0522 11.3344 102.988C17.3808 111.923 25.5257 119.24 35.0558 124.298C44.5859 129.356 55.2106 132.001 65.9997 132Z"
                        fill="#4B8700"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
