import React, { useEffect, useState, useRef } from "react";
import Select2 from "../Common/Select2Wrapper";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";
import debounce from "lodash.debounce";

const TicketForm = () => {
  const User = getUserData();
  const [data, setData] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toastRef = useRef();
  const [formData, setFormData] = useState({
    userId: User?._id,
    assignedTo: "",
    jobId: "",
    subject: "",
    description: "",
    status: "",
    currentJobStatus: "",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
  const dropdownRef = useRef();
  const dropDownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false); // Controls dropdown visibility
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [userKeyword, setUserKeyword] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id === "description" ? "description" : id]: value,
    });
  };

  const handleSelectChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };
  const fetchUsers = debounce(async () => {
    if (!userKeyword) return;
    try {
      setLoading(true);
      const response = await api.searchUsers(userKeyword);
      if (response && response.users) {
        setUserdata(
          response.users.map((user) => ({
            value: user._id,
            label: user.name,
          }))
        );
      } else {
        setUserdata([]);
      }
      setShowUserDropdown(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  }, 500);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    if (userKeyword && !formData.assignedTo) {
      fetchUsers();
    }
  }, [userKeyword]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.addTicket(formData);
      if (response.status === 201) {
        toastRef.current.addToast("Ticket Created Successfully", "success");
        setLoading(false);
        setTimeout(
          () => history.push(`./edit-ticket/${response?.ticket?._id}`),
          1000
        );
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchJobs = debounce(async () => {
    if (!searchKeyword) return;
    try {
      setLoading(true);
      const response = await api.getJobsByAdmin(10, 0, searchKeyword);
      if (response && response.jobs) {
        setData(
          response.jobs.map((job) => ({
            value: job._id,
            label: job.title,
          }))
        );
        setShowDropdown(true); // Show dropdown when data is fetched
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    fetchJobs();
  }, [searchKeyword]);
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value); // Update search keyword
    setShowDropdown(true); // Show dropdown while typing
  };

  const handleUserSelect = (user) => {
    setFormData({ ...formData, assignedTo: user.value });
    setUserKeyword(user.label);
    setShowUserDropdown(false);
  };
  const handleUserSearchChange = (e) => {
    setUserKeyword(e.target.value);
    setFormData({ ...formData, assignedTo: "" });
    setShowUserDropdown(true);
  };
  const dummyJobStatusOptions = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];
  const handleJobSelect = (job) => {
    setFormData({ ...formData, jobId: job.value });
    setSearchKeyword(job.label); // Display selected job title in input
    setShowDropdown(false); // Hide dropdown after selection
  };
  return (
    <div>
      <div className="w-3/4 m-auto p-10">
        <div className="py-5 text-3xl font-bold" style={{ color: "#003366" }}>
          Create Ticket
        </div>
        <form
          onSubmit={handleSubmit}
          className="animated-form profileheadlinediv space-y-6"
        >
          <input type="hidden" id="userId" value={User?._id} />
          <div className="grid grid-cols-2 gap-5">
            <div className="form-group relative" ref={dropdownRef}>
              <input
                id="jobSearch"
                type="text"
                value={searchKeyword}
                onChange={handleSearchChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
                placeholder="Type to search jobs..."
              />
              <label htmlFor="jobSearch">Search Job</label>
              {showDropdown && (
                <ul className="absolute z-10 w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto mt-1 custom-scrollbar">
                  {data.length > 0 ? (
                    data.map((job) => (
                      <li
                        key={job.value}
                        onClick={() => handleJobSelect(job)}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                      >
                        {job.label}
                      </li>
                    ))
                  ) : (
                    <li className="p-2 text-gray-500">No data found</li>
                  )}
                </ul>
              )}
            </div>
            <div className="form-group relative" ref={dropDownRef}>
              <input
                id="assignedToSearch"
                type="text"
                name="assignedTo"
                value={userKeyword}
                onChange={handleUserSearchChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
                placeholder="Type to search users..."
              />
              <label htmlFor="assignedToSearch">Assign To</label>
              {showUserDropdown && (
                <ul className="absolute z-10 w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto mt-1 custom-scrollbar">
                  {userdata.length > 0 ? (
                    userdata.map((user) => (
                      <li
                        key={user.value}
                        onClick={() => handleUserSelect(user)}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                      >
                        {user.label}
                      </li>
                    ))
                  ) : (
                    <li className="p-2 text-gray-500">No data found</li>
                  )}
                </ul>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="relative job-search-label-section singleselect2 mt-1">
              <Select2
                id="statusOptions"
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                name="statusOptions"
                multiple={false}
                defaultValue={formData.status}
                onChange={(e) => handleSelectChange(e, "status")}
                data={dummyJobStatusOptions.map((status) => ({
                  id: status.value,
                  text: status.label,
                }))}
                options={{
                  placeholder: "",
                  theme: "classic",
                  width: "resolve",
                }}
              />
              <label
                htmlFor="statusOptions"
                className="block text-sm font-medium text-gray-700"
              >
                Status
              </label>
            </div>
            <div className="form-group">
              <input
                id="subject"
                placeholder=""
                value={formData.subject}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
              />
              <label htmlFor="subject" className="profileheadline">
                Subject
              </label>
            </div>
          </div>

          <div className="form-group">
            <textarea
              id="description"
              placeholder=""
              value={formData.description}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700 custom-scrollbar"
              rows="4"
            />
            <label htmlFor="description" className="profileheadline">
              Description
            </label>
          </div>

          <div className="flex justify-end mt-6">
            <button type="submit" className="Job-Post-btn text-white">
              Save
            </button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default TicketForm;
