import React, { useState, useEffect } from "react";

const Certifection = ({ onClose, onSave, initialValues, category, index }) => {
  const [formData, setFormData] = useState({
    certificate_name: "",
    issuing_of_organization: "",
    issue_date: "",
    expiration_date: "",
    certification_id: "",
    description: "",
    url: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                {" "}
                Certifection{" "}
              </h1>

              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="certificate_name"
                    placeholder=""
                    value={formData.certificate_name}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label
                    htmlFor="certificate_name"
                    className="form-input-label"
                  >
                    Certification Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="issuing_of_organization"
                    placeholder=""
                    value={formData.issuing_of_organization}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label
                    htmlFor="issuing_of_organization"
                    className="form-input-label"
                  >
                    Issuing Organization
                  </label>
                </div>
                <div className="md:mb-2 grid md:grid-cols-2 grid-cols-1 gap-x-5">
                  <div className="form-group">
                    <input
                      type="date"
                      id="issue_date"
                      placeholder=""
                      value={formData.issue_date}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="issue_date" className="form-input-label">
                      Issue Date
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="date"
                      id="expiration_date"
                      placeholder=""
                      value={formData.expiration_date}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label
                      htmlFor="expiration_date"
                      className="form-input-label"
                    >
                      Expiration Date
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="certification_id"
                    placeholder=""
                    value={formData.certification_id}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label
                    htmlFor="certification_id"
                    className="form-input-label"
                  >
                    Certification ID
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    id="description"
                    placeholder=""
                    value={formData.description}
                    onChange={handleChange}
                    className="shadow w-full border rounded-full py-2 px-3"
                  />
                  <label htmlFor="description" className="form-input-label">
                    Description
                  </label>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="   text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 close-btn-style"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default Certifection;
