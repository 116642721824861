import React from "react";
import moment from "moment";
const BASE_DOMAIN_URL = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const ChatList = ({ chats, onSelectChat }) => {
  return (
    <div className="h-full overflow-y-auto p-4">
      <h2 className="text-xl font-bold mb-4">Chats</h2>
      <ul>
        {chats.map((chat) => {
          const lastMessage =
            chat.messages && chat.messages.length > 0
              ? chat.messages[chat.messages.length - 1]
              : null;         

          return (
            <li
              key={chat._id}
              className="p-2 cursor-pointer hover:bg-gray-200 rounded"
              onClick={() => onSelectChat(chat)}
            >
              <div className="flex items-center">
                <div className="w-10 h-10">
                  <img
                    className="profileimgheader"
                    alt="profileimg"
                    src={
                      chat?.profileImage
                        ? chat?.profileImage.startsWith("http")
                          ? chat?.profileImage
                          : `${BASE_DOMAIN_URL}/uploads/${chat?.profileImage}`
                        : BASE_DOMAIN_URL + "/uploads/deafultman.png"
                    }
                    style={{ width: "120px" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        BASE_DOMAIN_URL + "/uploads/deafultman.png";
                    }}
                  />
                </div>

                <div className="ml-4">
                  {/* Display course title */}
                  <p className="font-semibold">{chat.courseTitle}</p>

                  {/* Display time of the last message */}
                  {lastMessage ? (
                    <p className="text-sm text-gray-500">
                      {moment(lastMessage.createdAt).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-500">No messages yet</p>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatList;
