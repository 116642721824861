import React, { useEffect, useState, useRef } from "react";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";
import debounce from "lodash.debounce";

const TicketForm = () => {
  const User = getUserData();
  const [data, setData] = useState([]); // Job data for dropdown
  const [userdata, setUserdata] = useState([]); // User data for dropdown
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toastRef = useRef();
  const dropdownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false); // Job dropdown visibility
  const [showUserDropdown, setShowUserDropdown] = useState(false); // User dropdown visibility
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userKeyword, setUserKeyword] = useState("");

  const [formData, setFormData] = useState({
    userId: User?._id,
    jobId: "",
    subject: "",
    description: "",
    status: "Open",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });

  // Fetch jobs based on search keyword
  const fetchJobs = debounce(async () => {
    if (!searchKeyword) return;
    try {
      setLoading(true);
      const response = await api.getSearchEmpjob(User?._id, searchKeyword);
      if (response) {
        setData(
          response.map((job) => ({
            value: job._id,
            label: job.title,
          }))
        );
        setShowDropdown(true); // Show dropdown when jobs are fetched
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoading(false);
    }
  }, 500);

  // Update job search keyword and trigger fetch
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
    setShowDropdown(true); // Show dropdown while typing
  };

  useEffect(() => {
    fetchJobs();
  }, [searchKeyword]);

  const handleUserSelect = (user) => {
    setFormData({ ...formData, assignedTo: user.value });
    setUserKeyword(user.label); // Show selected user's name
    setShowUserDropdown(false); // Hide dropdown after selection
  };

  const handleJobSelect = (job) => {
    setFormData({ ...formData, jobId: job.value });
    setSearchKeyword(job.label); // Show selected job's title
    setShowDropdown(false); // Hide dropdown after selection
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.addTicket(formData);
      if (response.status === 201) {
        toastRef.current.addToast("Ticket Created Successfully", "success");
        setLoading(false);
        setTimeout(
          () => history.push(`./edit-ticket/${response?.ticket?._id}`),
          1000
        );
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className="w-3/4 m-auto p-10">
      <div className="py-5 text-3xl font-bold" style={{ color: "#003366" }}>
        Create Ticket
      </div>
      <form
        onSubmit={handleSubmit}
        className="animated-form profileheadlinediv space-y-6"
      >
        <input type="hidden" id="userId" value={User?._id} />

        {/* Job Search Input with Dropdown */}
        <div className="grid grid-cols-2 gap-5">
          <div className="form-group relative" ref={dropdownRef}>
            <input
              id="jobSearch"
              type="text"
              value={searchKeyword}
              onChange={handleSearchChange}
              className="border rounded w-full py-2 px-3 text-gray-700"
              placeholder="Type to search jobs..."
            />
            <label htmlFor="jobSearch">Search Job</label>
            {showDropdown && (
              <ul className="absolute z-10 w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto mt-1 custom-scrollbar">
                {data.length > 0 ? (
                  data.map((job) => (
                    <li
                      key={job.value}
                      onClick={() => handleJobSelect(job)}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                    >
                      {job.label}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No data found</li>
                )}
              </ul>
            )}
          </div>

          <div className="form-group">
            <input
              id="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={(e) =>
                setFormData({ ...formData, subject: e.target.value })
              }
              className="border rounded w-full py-2 px-3 text-gray-700"
            />
            <label htmlFor="subject">Subject</label>
          </div>

          {/* User Search Input with Dropdown */}
        </div>

        <div className="form-group">
          <textarea
            id="description"
            placeholder="Description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            className="border rounded w-full py-2 px-3 text-gray-700"
            rows="4"
          />
          <label htmlFor="description">Description</label>
        </div>

        <div className="flex justify-end mt-6">
          <button type="submit" className="Job-Post-btn text-white">
            Save
          </button>
        </div>
      </form>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default TicketForm;
