import React, { useState, useEffect } from "react";
import Enrollpopuppage from "../popup/Profilepagepopup/Enrollpopup.js";
import { usePopup } from "../Common/PopupContext";
import api from "../../Api";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VisitorTracker from "../Common/VisitorTracker.js";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;
const CourseCard = () => {
  const [data, setdata] = useState([]);
  const [cousredata, setcousredata] = useState([]);
  let { courseid } = useParams();
  useEffect(() => {
    async function getCoursesbyid() {
      try {
        const response = await api.getCoursebyId(courseid);
        //console.log(response);

        if (response.status !== 400) {
          setcousredata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getCoursesbyid();
    async function getCpurses() {
      try {
        const response = await api.getAllCourses();
        if (response.status !== 400) {
          setdata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getCpurses();
  }, []);

  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  // const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    //console.log(message);
    //setPopupComponent(null);
    openPopup();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 courses per slide
    slidesToScroll: 1, // Scroll 4 courses at a time
    arrows: true, // Optional: Add arrows for easier navigation
    responsive: [
      {
        breakpoint: 1024, // For larger screens
        settings: {
          slidesToShow: 3, // Show 3 courses per slide on medium screens
          slidesToScroll: 3, // Scroll 3 at a time
        },
      },
      {
        breakpoint: 768, // For tablet screens
        settings: {
          slidesToShow: 2, // Show 2 courses per slide on tablets
          slidesToScroll: 2, // Scroll 2 at a time
        },
      },
      {
        breakpoint: 480, // For mobile screens
        settings: {
          slidesToShow: 1, // Show 1 course per slide on small screens
          slidesToScroll: 1, // Scroll 1 at a time
        },
      },
    ],
  };
  const Enrollpopup = (data) => {
    const popup = (
      <Enrollpopuppage
        onClose={() => handleClosePopup("Popup closed")}
        course={data}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const getImageSrc = (thumbnail) => {
    if (thumbnail && thumbnail.trim() !== "" && thumbnail !== "NA") {
      if (thumbnail.startsWith("http://") || thumbnail.startsWith("https://")) {
        return thumbnail;
      }
      return thumbnail.startsWith("/uploads/")
        ? `${domainpath}${thumbnail}`
        : `${domainpath}/uploads/${thumbnail}`;
    }
    return `${domainpath}/uploads/defaultblog.png`;
  };

  const getTextContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const truncatedText = (text) => text.split(" ").slice(0, 25).join(" ");
  const Courses = () => {
    return (
      <div className="w-full mb-10 hrp-course-card-outer">
        {/* Slider */}
        <Slider {...settings}>
          {data.map((course, index) => {
            const originalPrice = course.discountPrice
              ? course.price.toFixed(2)
              : null;
            const description = course.courseDescription || "";
            const isImageInDescription = description.includes("<img");
            const isHtmlContent = /<\/?[a-z][\s\S]*>/i.test(description);
            return (
              <div
                key={index}
                className="coursecard p-4 w-full h-full max-w-xs mx-auto my-4 border border-gray-300 rounded-md shadow-md"
                style={{ height: "460px" }}
              >
                {/* Course Link */}
                <a href={clientdomainpath + "/courses/" + course._id}>
                  {/* Course Thumbnail */}
                  <img
                    className="imgse renderCategoryEdit text uploadImage-logo"
                    src={
                      course.courseThumbnail &&
                      course.courseThumbnail.trim() !== "" &&
                      course.courseThumbnail !== "NA"
                        ? course.courseThumbnail.startsWith("http://") ||
                          course.courseThumbnail.startsWith("https://")
                          ? course.courseThumbnail
                          : course.courseThumbnail.startsWith("/uploads/")
                          ? `${domainpath}${course.courseThumbnail}`
                          : `${domainpath}/uploads/${course.courseThumbnail}`
                        : `${domainpath}/uploads/defaultblog.png` // Use default if the logo is not present
                    }
                    alt="Course"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop
                      e.target.src = `${domainpath}/uploads/defaultblog.png`; // Use the default image on error
                    }}
                  />
                </a>

                {/* Course Details */}
                <div className="py-4">
                  <a href={"/courses/" + course._id}>
                    <h3 className="text-lg font-bold hrp-course-card-title">
                      {course.courseTitle}
                    </h3>
                  </a>
                  <p className="mt-1 hrp-find-cours-card-description">
                    {isImageInDescription ? (
                      "Description contains an image. Please check the course details for more information."
                    ) : isHtmlContent ? (
                      <>
                        {truncatedText(getTextContent(description))}
                        {getTextContent(description).split(" ").length > 25 &&
                          "..."}
                      </>
                    ) : (
                      <>
                        {truncatedText(description)}
                        {description.split(" ").length > 25 && "..."}
                      </>
                    )}
                  </p>
                  <p className="text-sm text-gray-600 hrp-course-card-instructor">
                    {course.instructorName}
                  </p>

                  {/* Pricing */}
                  <div className="mt-4 flex items-center">
                    <span className="text-lg font-bold text-green-600 hrp-course-card-price">
                      £{course.discountPrice.toFixed(2)}
                    </span>
                    {originalPrice && (
                      <span className="ml-2 text-sm line-through text-gray-500 hrp-course-card-originalPrice">
                        £{originalPrice}
                      </span>
                    )}
                  </div>

                  {/* Course Levels */}
                  <div className="hrp-card-tittle mt-2 flex flex-wrap">
                    {course.courseLevel &&
                      course.courseLevel.map(
                        (level, index) =>
                          level && (
                            <p
                              key={index}
                              className="border rounded-full px-2 py-1 m-1 text-sm text-blue-800 border-blue-800"
                              style={{
                                color: "#003366",
                                border: "1px solid #003366",
                                fontSize: "13px",
                              }}
                            >
                              {level}
                            </p>
                          )
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  return (
    <div className="hrp-main-section course-page">
      <div className="  hrp-container  ">
        <section className=" hrp-sub-section hrp-course-section-1 ">
          <div className="flex py-10 mt-10   hrp-sub-section-container container m-auto">
            <div className="w-3/5 pr-20  ">
              <h2
                className="hrp-heading-section-1"
                style={{ color: "#003366" }}
              >
                {cousredata?.courseTitle}
              </h2>
              <p
                className="mt-2 hrp-course-section text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: cousredata.courseDescription,
                }}
              >
                {/* {cousredata?.courseDescription} */}
              </p>
            </div>
            <div className="w-2/5  flex justify-end items-center ">
              <div className="hrp-sub-section-image">
                <img
                  className="w-full object-cover rounded-t-md"
                  src={
                    cousredata?.courseThumbnail &&
                    cousredata?.courseThumbnail.trim() !== "" &&
                    cousredata?.courseThumbnail !== "NA"
                      ? cousredata?.courseThumbnail.startsWith("http://") ||
                        cousredata?.courseThumbnail.startsWith("https://")
                        ? cousredata?.courseThumbnail
                        : cousredata?.courseThumbnail.startsWith("/uploads/")
                        ? `${domainpath}${cousredata?.courseThumbnail}`
                        : `${domainpath}/uploads/${cousredata?.courseThumbnail}`
                      : `${domainpath}/uploads/defaultblog.png` // Use default if the logo is not present
                  }
                  alt="Course"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop
                    e.target.src = `${domainpath}/uploads/defaultblog.png`; // Use the default image on error
                  }}
                />
                <button
                  className="my-10 w-full rounded-full "
                  onClick={(e) => Enrollpopup(cousredata)}
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </section>
        <div className=" hrp-sub-section hrp-section-1 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-section-2 hrp-About-course py-6">
              <h2 className="  mb-2 hrp-About">About </h2>
              <p className="">
                Our client has developed a one-stop blockchain data platform to
                make on-chain and off-chain data indexing, querying, and sharing
                extremely fast, reliable, flexible, etc. We help developers
                build real-time, reliable, data-intensive apps in minutes — all
                without worrying about data processing in their frontends or
                backends! This is done through ready-to-use, flexible, and
                customizable APIs.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className=" hrp-sub-section hrp-section-1 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-section-2 hrp-About-course">
              <p className="">
                <strong>Skills:</strong>
              </p>
              <ul className="list-disc list-inside mb-4">
                {cousredata?.skills?.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className=" hrp-sub-section hrp-section-2 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    What you'll learn
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <ul className="list-disc list-inside text-gray-700">
                  <li>
                    Build microservices to serve our customers Build real-time
                  </li>
                  <li>
                    systems that can handle millions of TPS in sub-10ms latency
                  </li>
                  <li> Own the end-to-end product lifecycle of identifying,</li>
                  <li>designing, and building new features</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-3 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    What you'll learn
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <div className="lg:flex ">
                  <ul className="list-disc list-inside text-gray-700 lg:w-1/2">
                    <li>Solidity Programming By Building Projects</li>
                    <li>Smart Contract Usage (Theory + Practise)</li>
                    <li>The Development Functions Of Ethereum</li>
                    <li>How Decentralized Technology Works</li>
                  </ul>
                  <ul className="list-disc list-inside text-gray-700 lg:w-1/2">
                    <li>Solidity Programming By Building Projects</li>
                    <li>Smart Contract Usage (Theory + Practise)</li>
                    <li>The Development Functions Of Ethereum</li>
                    <li>How Decentralized Technology Works</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Course Objectives
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <ul className="list-disc list-inside text-gray-700"></ul>
                {typeof cousredata?.courseObjectives === "string" ? (
                  // Check if the string contains HTML tags
                  cousredata?.courseObjectives.includes("<") &&
                  cousredata?.courseObjectives.includes(">") ? (
                    // Parse HTML and extract text if it's in HTML format
                    Array.from(
                      new DOMParser().parseFromString(
                        cousredata?.courseObjectives,
                        "text/html"
                      ).body.childNodes
                    )
                      .map((node) => node.textContent.trim())
                      .filter((courseobj) => courseobj !== "")
                      .map((courseobj, index) => (
                        <li key={index}>{courseobj}</li>
                      ))
                  ) : (
                    // Split by line breaks if it's plain text with newlines
                    cousredata?.courseObjectives
                      .split(/\r?\n•\s*/)
                      .filter((courseobj) => courseobj.trim() !== "")
                      .map((courseobj, index) => (
                        <li key={index}>{courseobj.trim()}</li>
                      ))
                  )
                ) : Array.isArray(cousredata?.courseObjectives) ? (
                  cousredata?.courseObjectives.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))
                ) : (
                  <li>No valid responsibilities found.</li>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className="hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Target Audience
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <ul className="list-disc list-inside text-gray-700">
                  {typeof cousredata?.targetAudience === "string" ? (
                    cousredata?.targetAudience.includes("<") &&
                    cousredata?.targetAudience.includes(">") ? (
                      Array.from(
                        new DOMParser().parseFromString(
                          cousredata?.targetAudience,
                          "text/html"
                        ).body.childNodes
                      )
                        .map((node) => node.textContent.trim())
                        .filter((item) => item !== "")
                        .map((item, index) => <li key={index}>{item}</li>)
                    ) : (
                      cousredata?.targetAudience
                        .split(/\r?\n•\s*/)
                        .filter((item) => item.trim() !== "")
                        .map((item, index) => (
                          <li key={index}>{item.trim()}</li>
                        ))
                    )
                  ) : Array.isArray(cousredata?.targetAudience) ? (
                    cousredata?.targetAudience.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  ) : (
                    <li>No valid target audience information found.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className="hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Prerequisites
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <ul className="list-disc list-inside text-gray-700">
                  {typeof cousredata?.prerequisites === "string" ? (
                    cousredata?.prerequisites.includes("<") &&
                    cousredata?.prerequisites.includes(">") ? (
                      Array.from(
                        new DOMParser().parseFromString(
                          cousredata?.prerequisites,
                          "text/html"
                        ).body.childNodes
                      )
                        .map((node) => node.textContent.trim())
                        .filter((item) => item !== "")
                        .map((item, index) => <li key={index}>{item}</li>)
                    ) : (
                      cousredata?.prerequisites
                        .split(/\r?\n•\s*/)
                        .filter((item) => item.trim() !== "")
                        .map((item, index) => (
                          <li key={index}>{item.trim()}</li>
                        ))
                    )
                  ) : Array.isArray(cousredata?.prerequisites) ? (
                    cousredata?.prerequisites.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  ) : (
                    <li>No valid prerequisites found.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className="hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Course Resources
                  </h3>
                </div>
              </div>
              <div className="p-6">
                {Array.isArray(cousredata?.courseResources) &&
                cousredata?.courseResources.length > 0 ? (
                  <ul className="text-gray-700 space-y-2">
                    {cousredata?.courseResources.map((resource, index) => (
                      <li key={index} className="flex items-start space-x-2">
                        <span className="text-blue-500">📄</span>
                        <span
                          className="flex-1"
                          dangerouslySetInnerHTML={{
                            __html: resource,
                          }}
                        ></span>
                      </li>
                    ))}
                  </ul>
                ) : typeof cousredata?.courseResources === "string" ? (
                  cousredata?.courseResources.includes("<") &&
                  cousredata?.courseResources.includes(">") ? (
                    // HTML parsing
                    <ul className="text-gray-700 space-y-2">
                      {Array.from(
                        new DOMParser().parseFromString(
                          cousredata?.courseResources,
                          "text/html"
                        ).body.childNodes
                      )
                        .map((node) => node.textContent.trim())
                        .filter((item) => item !== "")
                        .map((item, index) => (
                          <li
                            key={index}
                            className="flex items-start space-x-2"
                          >
                            <span className="text-blue-500">📄</span>
                            <span className="flex-1">{item}</span>
                          </li>
                        ))}
                    </ul>
                  ) : (
                    // Plain text with line breaks
                    <ul className="text-gray-700 space-y-2">
                      {cousredata?.courseResources
                        .split(/\r?\n•\s*|\r?\n/) // Split by line breaks or bullet points
                        .filter((item) => item.trim() !== "")
                        .map((item, index) => (
                          <li
                            key={index}
                            className="flex items-start space-x-2"
                          >
                            <span className="text-blue-500">📄</span>
                            <span className="flex-1">{item.trim()}</span>
                          </li>
                        ))}
                    </ul>
                  )
                ) : (
                  <p className="text-gray-600">
                    No valid course resources found.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section hrp-section-4 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div className=" hrp-sub-skills">
              <div className="Benefits-heading">
                <div className="w-full text-left px-6 py-4 focus:outline-none">
                  <h3 className="text-lg font-medium text-gray-900">
                    Benefits
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <ul className="list-disc list-inside text-gray-700">
                  <li>
                    Top-notch team - Did we mention this? You’ll work with the
                    sharpest crew you’ve ever met. We are a team of 10+
                    experienced engineers, having built data infrastructure and
                    products as early employees at Databricks, Uber, Coinbase,
                    Confluent, Salesforce, Zoom, LinkedIn, and so on.
                  </li>
                  <li>
                    Unlimited vacation policy - we need you at your best at all
                    times. Our expected minimum time off lets you schedule your
                    work around your life.
                  </li>
                  <li>
                    Competitive compensation - we firmly believe amazing talent
                    is the No. 1 reason for success, and thus we provide
                    globally leading compensation, stock, and benefits.
                  </li>
                  <li>All of the standard benefits -Healthcare, 401k etc.</li>
                  <li>
                    Travel With Purpose - we are a fully distributed, 100%
                    remote-friendly team, however, we recognize the value of
                    in-person time. We will pay for round-trip flights, hotel,
                    transportation, and food on weekdays for you to visit our
                    headquarters in San Francisco, or other members of your team
                    around the world.
                  </li>
                  <li>
                    Continuous learning/training policy - we sponsor you for the
                    conferences and training programs you feel would add to your
                    development in the company. Sponsored visas - for employees
                    in need of them, such as H1b and Green Card.
                  </li>

                  {typeof cousredata?.courseObjectives === "string" ? (
                    // Check if the string contains HTML tags
                    cousredata?.courseObjectives.includes("<") &&
                    cousredata?.courseObjectives.includes(">") ? (
                      // Parse HTML and extract text if it's in HTML format
                      Array.from(
                        new DOMParser().parseFromString(
                          cousredata?.courseObjectives,
                          "text/html"
                        ).body.childNodes
                      )
                        .map((node) => node.textContent.trim())
                        .filter((courseobj) => courseobj !== "")
                        .map((courseobj, index) => (
                          <li key={index}>{courseobj}</li>
                        ))
                    ) : (
                      // Split by line breaks if it's plain text with newlines
                      cousredata?.courseObjectives
                        .split(/\r?\n•\s*/)
                        .filter((courseobj) => courseobj.trim() !== "")
                        .map((courseobj, index) => (
                          <li key={index}>{courseobj.trim()}</li>
                        ))
                    )
                  ) : Array.isArray(cousredata?.courseObjectives) ? (
                    cousredata?.courseObjectives.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  ) : (
                    <li>No valid responsibilities found.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section  hrp-section-5 ">
          <div className="m-auto container hrp-sub-section-container ">
            <div>
              <div className="text-center  hrp-broad-selection py-10">
                <h1>A broad selection of courses</h1>
                <p>
                  Choose from over 220,000 online video courses with new
                  additions published every month
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className=" hrp-sub-section  hrp-section-5 ">
          <div className="m-auto container hrp-sub-section-container  flex ">
            <Courses />
          </div>
        </div>
      </div>
      <VisitorTracker />
    </div>
  );
};
export default CourseCard;
