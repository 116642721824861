import React from "react";
import "../Layout/menubaar.css";
import JobSearchForm from "../Homepage/JobSearchForm";
import { Menubar } from "../Layout/dashboardmenus";
import { useLocalStorage } from "../Layout/useLocalStorage";
import { PopupProvider } from "../Common/PopupContext";

function App() {
  const user = useLocalStorage("useraccessToken");
  return (
    <PopupProvider>
      <div className="CareerOpportunities  ">
        <div className="CareerOpportunities-inner container m-auto">
          <JobSearchForm />
        </div>
      </div>
      {user ? <Menubar /> : ""}
    </PopupProvider>
  );
}
export default App;
