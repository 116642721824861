import React, { useEffect, useState, useRef } from "react";

const CountUp = ({ target }) => {
  const [count, setCount] = useState(0);
  const countUpRef = useRef(null); // Ref for the count-up element

  useEffect(() => {
    let start = 0;
    const duration = 2000; // 2 seconds
    const increment = target / (duration / 16); // Adjust to frame rate
    let animationFrameId;

    const animateCount = () => {
      start += increment;
      if (start < target) {
        setCount(Math.floor(start));
        animationFrameId = requestAnimationFrame(animateCount);
      } else {
        setCount(target);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateCount();
            observer.unobserve(countUpRef.current); // Stop observing after the count starts
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current); // Observe the count-up element
    }

    return () => {
      cancelAnimationFrame(animationFrameId); // Clean up on component unmount
      observer.disconnect(); // Clean up the observer
    };
  }, [target]);

  return (
    <span ref={countUpRef} className="successection">
      {count.toLocaleString()}
    </span>
  );
};

export default CountUp;
