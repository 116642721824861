import React, { useState, useEffect, useRef } from "react";
import Message from "../../helpers/Messageschat";
import { getUserData } from "../../helpers/utils";
import FeatherIcon from "feather-icons-react";
const BASE_DOMAIN_URL = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const MessagePanel = ({ selectedChat, onSendMessage, messageList }) => {
  const [messageText, setMessageText] = useState("");
  const messagesEndRef = useRef(null);
  const User = getUserData();

  const handleSend = () => {
    if (messageText.trim()) {
      onSendMessage(messageText);
      setMessageText(""); // Clear input after sending
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageList]); // Scroll when messageList changes
  return (
    <div className="flex flex-col chat-panel-section">
      {/* Header with receiver's name and image */}
      <div className="flex items-center p-4 border-b border-gray-300 bg-white">
        <div className="w-10 h-10">
          <img
            className="profileimgheader"
            src={BASE_DOMAIN_URL + "/uploads/deafultman.png"}
            alt="profileimg"
          />
        </div>
        <div>
          <p className="text-lg font-semibold">{selectedChat?.jobTitle}</p>
          <p className="text-sm text-gray-500">Online</p>
        </div>
      </div>

      {/* Message area */}
      <div className="flex-1 overflow-y-auto p-2 border-b border-gray-300 bg-gray-50 custom-scrollbar">
        {messageList.map((msg, index) => (
          <Message
            key={index}
            user={msg.user}
            text={msg.text}
            timestamp={msg.timestamp}
            isCurrentUser={msg.senderId === User?._id} // Determine if current user is the sender
          />
        ))}
        <div ref={messagesEndRef} /> {/* Auto-scrolls to here */}
      </div>

      {/* Input area */}
      <div className="flex items-center border border-gray-300 rounded-full p-2 w-full mt-2">
        <input
          type="text"
          placeholder="Type Something here..."
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          className="flex-grow px-4 py-2 text-sm rounded-full outline-none"
        />

        <FeatherIcon
          icon="send"
          onClick={handleSend}
          className="sendiconmessage"
        />
      </div>
    </div>
  );
};

export default MessagePanel;
