import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Adduser from "../popup/AddUserEmployer";
import EditUser from "../popup/EditUserEmployer";
import { getUserData } from "../../helpers/utils";
import { usePopup } from "../Common/PopupContext";
import CustomToastContainer from "../Common/CustomToastContainer";
import api from "../../Api";
import Cookies from "js-cookie";
import { encryptData } from "../../helpers/encryption";
import FeatherIcon from "feather-icons-react";
const Manageuser = () => {
  const { openPopup } = usePopup();
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(0);
  const [data, setdata] = useState([]);
  const toastRef = useRef();
  const handleLoginClick = () => {
    const popup = <Adduser onClose={() => handleClosePopup("Popup closed")} />;
    openPopup(popup);
  };

  const handleDelete = async (jobId) => {
    const response = await api.deleteStaff(`${jobId}`);

    if (response.status != 500) {
      toastRef.current.addToast(response.message, "success");
      setRender(render + 1);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the Staff!",
        "error"
      );
    }
  };
  const handleClosePopup = (message) => {
    openPopup();
  };
  const handleEditUser = (id) => {
    const popup = (
      <EditUser id={id} onClose={() => handleClosePopup("Popup closed")} />
    );
    openPopup(popup);
  };
  const handleSecertLogin = async (id) => {
    setLoading(true);
    try {
      const userData = {
        userId: id,
      };
      const response = await api.SeceretLoginInEmployer(userData);

      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        const { accessToken, refreshToken } = response?.user;
        const encryptedUserData = encryptData(response.user);
        const existingAccessToken =
          localStorage.getItem("accessToken") || Cookies.get("accessToken");
        const existingRefreshToken =
          localStorage.getItem("refreshToken") || Cookies.get("refreshToken");
        const existingUser =
          localStorage.getItem("user") || Cookies.get("user");
        if (existingAccessToken) {
          localStorage.setItem("adminaccessToken", existingAccessToken);
          Cookies.set("adminaccessToken", existingAccessToken);
        }
        if (existingRefreshToken) {
          localStorage.setItem("adminrefreshToken", existingRefreshToken);
          Cookies.set("adminrefreshToken", existingRefreshToken);
        }
        if (existingUser) {
          localStorage.setItem("adminuser", existingUser);
          Cookies.set("adminuser", existingUser);
        }
        // console.log("Existing tokens and user renamed as admin keys", {
        //   adminAccessToken: localStorage.getItem("adminaccessToken"),
        //   adminRefreshToken: localStorage.getItem("adminrefreshToken"),
        //   adminUser: localStorage.getItem("adminuser"),
        // });
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        Cookies.set("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);

        setTimeout(() => {
          setLoading(false);
          window.location.href = "/dashboard";
        }, 2000);
      } else if (response.status === 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );

        toastRef.current.addToast(response.data.msg, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);

      toastRef.current.addToast(error.response?.data?.msg, "error");
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div
          className="flex justify-between items-center hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {/* <FeatherIcon
            icon="eye"
            onClick={(e) => handleSecertLogin(row?._id)}
            className="cursor-pointer"
          /> */}
          <span
            style={{ marginLeft: "10px" }}
            className="cursor-pointer"
            // onClick={(e) => handleSecertLogin(row?._id)}
          >
            {row?.name}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row?.position,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 w-full item-center justify-center">
          <button
            className="manage-edit-btn text-white px-4 py-2"
            onClick={(e) => handleEditUser(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-edit-2 "
            >
              <g>
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </g>
            </svg>
          </button>
          <span
            className="manage-delete-btn text-white px-3 py-2 "
            onClick={(e) => handleDelete(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-trash-2 "
              color="red"
            >
              <g>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </g>
            </svg>
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function getusers() {
      try {
        const response = await api.getStaff(User?._id);
        if (response.status !== 400) {
          setdata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getusers();
  }, [render]);

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between ">
          <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
            Manage User
          </h2>
          <button
            className="manage-add-btn text-white px-3 py-1 Job-Post-btn"
            onClick={handleLoginClick}
          >
            Add User
          </button>
        </div>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Manageuser;
