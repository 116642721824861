import React, { forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Section = ({ title, children }) => (
  <div>
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    {children}
  </div>
);

const InterestBadge = ({ name }) => (
  <div
    className="flex items-center justify-center pt-0 "
    style={{
      border: "1px solid #c6c6c6",
      paddingBottom: "15px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderRadius: "33px",
      color: "#3e3e3e",
      fontSize: "16px",
      fontWeight: "600",
    }}
  >
    {name}
  </div>
);
const interests = ["Travelling", "Cricket", "Listening Music"];

const ProfileDownload = forwardRef(({ Profiles, domainpath, tools }, ref) => {
  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useImperativeHandle(ref, () => ({
    downloadCV() {
      const container = document.createElement("div");

      const profileImageUrl =
        Profiles.jobProfileImage &&
        Profiles.jobProfileImage.trim() !== "" &&
        Profiles.jobProfileImage !== "NA"
          ? Profiles.jobProfileImage.startsWith("http://") ||
            Profiles.jobProfileImage.startsWith("https://")
            ? Profiles.jobProfileImage
            : Profiles.jobProfileImage.startsWith("/uploads/")
            ? `${domainpath}${Profiles.jobProfileImage}`
            : `${domainpath}/uploads/${Profiles.jobProfileImage}`
          : `${domainpath}/uploads/deafultman.png`;

      loadImage(profileImageUrl).then(() => {
        document.body.appendChild(container);
        ReactDOM.render(
          <div className="">
            <div className="hrp-main-section hrp-profile-mobile-main ">
              <div className="hrp-container">
                <div
                  className="hrp-sub-section hrp-profile-mobile-responcive hrp-background-color  "
                  style={{ borderBottom: "1px solid #c6c6c6" }}
                >
                  <div className=" m-auto container p-6 lg:pt-20 md:pt-10">
                    <div className="flex  lg:gap-5 gap-3 md:gap-3 space-x-4">
                      <div className="w-1/4  ">
                        <div className="hrp-user-profile-image">
                          <img
                            src={
                              Profiles.jobProfileImage &&
                              Profiles.jobProfileImage.trim() !== "" &&
                              Profiles.jobProfileImage !== "NA"
                                ? Profiles.jobProfileImage.startsWith(
                                    "http://"
                                  ) ||
                                  Profiles.jobProfileImage.startsWith(
                                    "https://"
                                  )
                                  ? Profiles.jobProfileImage
                                  : Profiles.jobProfileImage.startsWith(
                                      "/uploads/"
                                    )
                                  ? `${domainpath}${Profiles.jobProfileImage}`
                                  : `${domainpath}/uploads/${Profiles.jobProfileImage}`
                                : `${domainpath}/uploads/deafultman.png`
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${domainpath}/uploads/deafultman.png`;
                            }}
                            alt="Profile"
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        {Profiles?.location &&
                        Profiles?.location !== "" &&
                        Profiles?.location !== undefined &&
                        Profiles?.location !== null ? (
                          <div className="flex justify-center mt-2">
                            <h1 className="flex" style={{ color: "#3e3e3e" }}>
                              <span>
                                <svg
                                  width={16}
                                  hanging={16}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                  fill="#3e3e3e"
                                >
                                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                              </span>
                              &nbsp; {Profiles?.location}
                            </h1>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="m-auto w-3/4">
                        <div className="md:flex justify-between pb-3">
                          <div>
                            <div className="hrp-user-profile-details">
                              <h2 className=" hrp-user-name text-gray-600 ">
                                {Profiles?.salutation} {Profiles?.name}
                              </h2>
                              <div className="lg:flex gap-2 items-center ">
                                <p className="">{Profiles?.jobTitle}</p>

                                <h1>at {Profiles?.location} </h1>
                              </div>
                            </div>
                            <div className="md:flex gap-5 mt-3 ">
                              <div className="  hrp-userprofile-role-heading">
                                <p className="text-gray-600  ">
                                  Year Experience
                                </p>
                                <h4 className=" hrp-heading-size hrp-heading-color ">
                                  {Profiles?.experience}
                                </h4>
                              </div>
                              {Profiles?.currentCTC !== undefined ||
                              Profiles?.currentCTC != null ? (
                                <div className="hrp-userprofile-role-heading">
                                  <p className="text-gray-600">Current CTC</p>
                                  <h4 className="hrp-heading-color hrp-heading-size">
                                    {Profiles.currentCTC}
                                  </h4>
                                </div>
                              ) : null}
                            </div>
                          </div>{" "}
                          <div className="lg:w-1/5">
                            <div className="mt-4 "></div>
                          </div>
                        </div>
                        {Profiles?.skills.length > 0 ? (
                          <>
                            <h3 className="hrp-heading-color hrp-user-name font-semibold flex md:mt-2 lg:mt-5">
                              Skill sets
                            </h3>
                            <div className="mt-2">
                              <div className="mt-2 flex flex-wrap gap-2">
                                {Profiles?.skills.map((skill) => (
                                  <span
                                    key={skill}
                                    className=" hrp-heading-color hrp-profile-skill lg:px-3 lg:pb-8  md:py-5 md:px-2 rounded-full text-sm bg-white"
                                  >
                                    {skill === "IT"
                                      ? "Information Technology"
                                      : skill}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {Profiles?.languages.length > 0 ? (
                          <div className="mt-6">
                            <h3 className="hrp-heading-color text-2xl font-semibold">
                              Language Known
                            </h3>
                            <div className="mt-4 flex flex-wrap gap-2">
                              {Profiles?.languages.map((lang) => (
                                <span
                                  key={lang}
                                  className=" hrp-heading-color hrp-profile-skill px-3 pb-5 rounded-full text-sm"
                                >
                                  {lang}
                                </span>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Section>
                  {Profiles?.tools?.length > 0 && (
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="lg:flex   gap-10  container m-auto ">
                          <div className="lg:w-1/4">
                            <h2 className="hrp-heading-color text-center lg:text-2xl px-10 mb-6">
                              Applications & Tools Known
                            </h2>
                          </div>
                          <div className="lg:w-3/4 md:mx-10">
                            <div className="grid lg:grid-cols-7 md:grid-cols-5 grid-cols-2 gap-6  md:ml-3 md:mr-0  mx-4">
                              {Profiles?.tools?.map((profileTool, index) => {
                                const matchedTool = tools?.find(
                                  (tool) => tool.name === profileTool
                                );

                                return (
                                  <div
                                    className="hrp-main-section"
                                    key={
                                      matchedTool?.id ||
                                      `${profileTool}-${index}`
                                    }
                                  >
                                    <div className="hrp-container">
                                      <div className="hrp-sub-section">
                                        <div className="flex flex-col items-center justify-center p-4 hrp-Applications-prfile">
                                          <div className="h-12 w-12 flex justify-center items-center">
                                            {matchedTool?.icon ? (
                                              matchedTool.icon
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                              >
                                                <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4l54.1 0 109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109 0-54.1c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7L352 176c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
                                              </svg>
                                            )}
                                          </div>
                                          <p className="text-center text-gray-700">
                                            {matchedTool?.name || profileTool}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </Section>
                {/* ------------title="Major Projects"---------------------- */}
                {Profiles?.projects?.length > 0 ? (
                  <Section>
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="md:flex  gap-3 md:gap-10 container m-auto">
                          <div className="w-1/4">
                            <h2
                              className=" hrp-heading-color text-center lg:text-2xl px-10 mb-6"
                              style={{ color: "#003366" }}
                            >
                              Major Projects
                            </h2>
                          </div>
                          <div className="w-3/4">
                            <div className="  ml-3">
                              {Profiles?.projects.map((project) => (
                                <div className=" md:flex space-x-4   items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="flex justify-between ">
                                      <div className="">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {project?.title}
                                        </h3>
                                        <p className=" mb-2 hrp-company-name">
                                          {project?.company}
                                        </p>
                                      </div>

                                      <div className=" justify-between  text-gray-600 mb-4">
                                        <div>{project?.duration}</div>
                                      </div>
                                    </div>
                                    <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
                                      {project?.description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}
                {Profiles?.employment?.length > 0 ? (
                  <Section>
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="md:flex   md:gap-10 container m-auto">
                          <div className="md:w-1/4">
                            <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                              Work History
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            <div className="  ml-3">
                              {Profiles?.employment.map((job) => (
                                <div className=" md:flex space-x-4   items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="md:flex justify-between pb-3">
                                      <div className="">
                                        <h3 className="text-xl pb-2 hrp-company-name">
                                          {job?.role}
                                        </h3>
                                        <p className=" mb-2 hrp-company-name">
                                          {job?.company}
                                        </p>
                                      </div>

                                      <div className=" justify-between  text-gray-600 mb-4">
                                        <div>{job?.duration}</div>
                                      </div>
                                    </div>
                                    <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
                                      {job?.description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}

                {Profiles?.accomplishments?.length > 0 &&
                Profiles.accomplishments.some(
                  (acc) =>
                    acc.online_profile?.length > 0 ||
                    acc.work_sample?.length > 0 ||
                    acc.research_publication?.length > 0 ||
                    acc.presentation?.length > 0 ||
                    acc.patent?.length > 0 ||
                    acc.certification?.length > 0
                ) ? (
                  <Section>
                    <section className="hrp-container hrp-section-1 md:py-8">
                      <div className="hrp-sub-section">
                        <div className="md:flex gap-3 md:gap-10 container m-auto">
                          <div className="md:w-1/4">
                            <h2 className="hrp-heading-color text-center lg:text-2xl md:px-10 mb-6 hrp-mobile-responcive-heading">
                              Accomplishments
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            {/* Online Profiles */}
                            {Profiles?.accomplishments?.[0]?.online_profile
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Online Profiles
                                </h2>
                                {Profiles.accomplishments[0].online_profile.map(
                                  (profile, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        {profile.profile_type && (
                                          <h3 className="text-xl pb-2 hrp-company-name">
                                            {profile.profile_type}
                                          </h3>
                                        )}
                                        {profile.profile_url && (
                                          <p className="mb-2 hrp-company-name">
                                            <a
                                              href={profile.profile_url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {profile.profile_url}
                                            </a>
                                          </p>
                                        )}
                                        {profile.profile_description && (
                                          <p className="mb-2 hrp-company-name">
                                            {profile.profile_description}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Work Samples */}
                            {Profiles?.accomplishments?.[0]?.work_sample
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Work Samples
                                </h2>
                                {Profiles.accomplishments[0].work_sample.map(
                                  (work, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        {work.sample_title && (
                                          <h3 className="text-xl pb-2 hrp-company-name">
                                            {work.sample_title}
                                          </h3>
                                        )}
                                        {work.link_to_sample && (
                                          <p className="hrp-description pt-1">
                                            <a
                                              href={work.link_to_sample}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {work.link_to_sample}
                                            </a>
                                          </p>
                                        )}
                                        {work.sample_description && (
                                          <p className="hrp-description pb-5 pt-1">
                                            {work.sample_description}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Research Publications */}
                            {Profiles?.accomplishments?.[0]
                              ?.research_publication?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Research Publications
                                </h2>
                                {Profiles.accomplishments[0].research_publication.map(
                                  (pub, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        {pub.title && (
                                          <h3 className="text-xl pb-2 hrp-company-name">
                                            {pub.title}
                                          </h3>
                                        )}
                                        {pub.url && (
                                          <p className="mb-2 hrp-company-name">
                                            <a
                                              href={pub.url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {pub.url}
                                            </a>
                                          </p>
                                        )}
                                        {pub.author && (
                                          <p className="hrp-description pt-1">
                                            Author: {pub.author}
                                          </p>
                                        )}
                                        {pub.journal && (
                                          <p className="hrp-description pt-1">
                                            Journal: {pub.journal}
                                          </p>
                                        )}
                                        {pub.abstract && (
                                          <p className="hrp-description pt-1">
                                            Abstract: {pub.abstract}
                                          </p>
                                        )}
                                        {pub.description && (
                                          <p className="hrp-description pb-5 pt-1">
                                            {pub.description}
                                          </p>
                                        )}
                                        {pub.publish_date && (
                                          <div className="text-gray-600 mb-4">
                                            <div>
                                              Published on: {pub.publish_date}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Presentations */}
                            {Profiles?.accomplishments?.[0]?.presentation
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Presentations
                                </h2>
                                {Profiles.accomplishments[0].presentation.map(
                                  (pres, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        {pres.title && (
                                          <h3 className="text-xl pb-2 hrp-company-name">
                                            {pres.title}
                                          </h3>
                                        )}
                                        {pres.presenter && (
                                          <p className="mb-2 hrp-company-name">
                                            Presenter: {pres.presenter}
                                          </p>
                                        )}
                                        {pres.link && (
                                          <p className="hrp-description pb-5 pt-1">
                                            <a
                                              href={pres.link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {pres.link}
                                            </a>
                                          </p>
                                        )}
                                        {pres.description && (
                                          <p className="hrp-description pb-5 pt-1">
                                            {pres.description}
                                          </p>
                                        )}
                                        {pres.event_date && (
                                          <div className="text-gray-600 mb-4">
                                            <div>
                                              Event Date: {pres.event_date}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Patents */}
                            {Profiles?.accomplishments?.[0]?.patent?.length >
                              0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Patents
                                </h2>
                                {Profiles.accomplishments[0].patent.map(
                                  (pat, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        {pat.title && (
                                          <h3 className="text-xl pb-2 hrp-company-name">
                                            {pat.title}
                                          </h3>
                                        )}
                                        {pat.patent_number && (
                                          <p className="mb-2 hrp-company-name">
                                            Patent Number: {pat.patent_number}
                                          </p>
                                        )}
                                        {pat.inventor && (
                                          <p className="mb-2 hrp-company-name">
                                            Inventors: {pat.inventor}
                                          </p>
                                        )}
                                        {pat.description && (
                                          <p className="hrp-description pb-5 pt-1">
                                            {pat.description}
                                          </p>
                                        )}
                                        {pat.date_granted && (
                                          <div className="text-gray-600 mb-4">
                                            <div>
                                              Date Granted: {pat.date_granted}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {/* Certifications */}
                            {Profiles?.accomplishments?.[0]?.certification
                              ?.length > 0 && (
                              <div className="ml-3 mb-6">
                                <h2
                                  className="text-xl font-bold mb-4 capitalize"
                                  style={{ color: "#343434" }}
                                >
                                  Certifications
                                </h2>
                                {Profiles.accomplishments[0].certification.map(
                                  (cert, index) => (
                                    <div
                                      key={index}
                                      className="md:flex space-x-4 items-start hrp-border-left mb-4"
                                    >
                                      <div className="relative hrp-position flex-shrink-0">
                                        <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                      </div>
                                      <div className="w-full">
                                        {cert.certificate_name && (
                                          <h3 className="text-xl pb-2 hrp-company-name">
                                            {cert.certificate_name}
                                          </h3>
                                        )}
                                        {cert.certification_id && (
                                          <p className="mb-2 hrp-company-name">
                                            Certification ID:{" "}
                                            {cert.certification_id}
                                          </p>
                                        )}
                                        {cert.description && (
                                          <p className="hrp-description pb-5 pt-1">
                                            {cert.description}
                                          </p>
                                        )}
                                        {cert.issue_date && (
                                          <div className="text-gray-600 mb-4">
                                            <div>
                                              Date Issued: {cert.issue_date}
                                            </div>
                                          </div>
                                        )}
                                        {cert.issuing_of_organization && (
                                          <div className="text-gray-600 mb-4">
                                            <div>
                                              Issued By:{" "}
                                              {cert.issuing_of_organization}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : null}
                <Section>
                  <section className=" hrp-container hrp-section-1  md:py-8">
                    <div className="hrp-sub-section  ">
                      <div className="md:flex  gap-3 md:gap-10 container m-auto ">
                        <div className="md:w-1/4">
                          <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                            Education
                          </h2>
                        </div>
                        <div className="m-auto w-3/4">
                          <div className="  ml-3">
                            {Profiles?.education.map((edu) => (
                              <div className=" md:flex space-x-4   items-start hrp-border-left">
                                <div className="relative hrp-position flex-shrink-0">
                                  <div className=" relative  flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                </div>
                                <div className="w-full">
                                  <div className="md:flex justify-between pb-3">
                                    <div className="">
                                      <h3 className="text-xl  hrp-heading-color">
                                        {edu?.degree}
                                      </h3>
                                    </div>
                                  </div>
                                  <p className="hrp-description pb-5 pt-1  hrp-responcive-description ">
                                    {edu?.institution}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </Section>
                {Profiles?.careerProfile?.interest.filter(
                  (interest) => interest.trim().length > 0
                ).length > 0 ? (
                  <Section>
                    <section className=" hrp-container hrp-section-1  md:py-8">
                      <div className="hrp-sub-section  ">
                        <div className="md:flex  gap-3 md:gap-10 container m-auto">
                          <div className="md:w-1/4">
                            <h2 className=" hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                              Interests
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            <div className="md:flex md:space-x-3 space-y-3 md:space-y-0 md:ml-3">
                              {Profiles?.careerProfile?.interest
                                .filter(
                                  (interest) => interest.trim().length > 0
                                )
                                .map((interest) => (
                                  <InterestBadge
                                    key={interest}
                                    name={interest}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}
                {Profiles?.careerProfile != {} &&
                Profiles?.careerProfile != null &&
                Profiles?.careerProfile != undefined ? (
                  <Section>
                    <section className="hrp-container hrp-section-1 md:py-8">
                      <div className="hrp-sub-section">
                        <div className="md:flex container md:gap-10 m-auto">
                          <div className="md:w-1/4">
                            <h2 className="hrp-heading-color text-center lg:text-2xl md:px-10 mb-6     hrp-mobile-responcive-heading">
                              Career Profile
                            </h2>
                          </div>
                          <div className="m-auto w-3/4">
                            <div className="ml-3">
                              {/* Industry */}
                              {Profiles?.careerProfile?.industry && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Industry
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.industry}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Department */}
                              {Profiles?.careerProfile?.categories &&
                                Profiles.careerProfile.categories.length >
                                  0 && (
                                  <div className="md:flex space-x-4 items-start hrp-border-left">
                                    <div className="relative hrp-position flex-shrink-0">
                                      <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                    </div>
                                    <div className="w-full">
                                      <div className="pb-3">
                                        <h3 className="text-xl hrp-heading-color heading-reaponcive-size">
                                          Categories
                                        </h3>
                                        <ul className="hrp-description pb-5 pt-1 list-disc list-inside">
                                          {Profiles.careerProfile.categories.map(
                                            (category, index) => (
                                              <li key={index}>{category}</li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {/* Role */}
                              {Profiles?.careerProfile?.role && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Role
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.role}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Job Type */}
                              {Profiles?.careerProfile?.jobType && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Job Type
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.jobType}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Desired Job Type */}
                              {Profiles?.careerProfile?.desired_jobtype > 0 &&
                                Profiles?.careerProfile?.desired_jobtype[0] !==
                                  "" && (
                                  <div className="md:flex space-x-4 items-start hrp-border-left">
                                    <div className="relative hrp-position flex-shrink-0">
                                      <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                    </div>
                                    <div className="w-full">
                                      <div className="pb-3">
                                        <h3 className="text-xl hrp-heading-color heading-reaponcive-size">
                                          Desired Job Type
                                        </h3>
                                        <p className="hrp-description pb-5 pt-1">
                                          {Profiles?.careerProfile?.desired_jobtype.join(
                                            ", "
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {/* Shift */}

                              {Profiles?.careerProfile?.employmentType?.length >
                                0 &&
                                Profiles?.careerProfile?.employmentType[0] !==
                                  "" && (
                                  <div className="md:flex space-x-4 items-start hrp-border-left">
                                    <div className="relative hrp-position flex-shrink-0">
                                      <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                    </div>
                                    <div className="w-full">
                                      <div className="pb-3">
                                        <h3 className="text-xl hrp-heading-color heading-reaponcive-size">
                                          Employment Type
                                        </h3>
                                        <p className="hrp-description pb-5 pt-1">
                                          {Profiles?.careerProfile?.employmentType.join(
                                            ", "
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {/* Preferred Work Location */}
                              {Profiles?.careerProfile
                                ?.preferred_work_location && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Preferred Work Location
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.preferred_work_location
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Expected Salary */}
                              {Profiles?.careerProfile?.expectedSalary && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Expected Salary
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.expectedSalary
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Current Salary */}
                              {Profiles?.careerProfile?.currentSalary && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Current Salary
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {Profiles?.careerProfile?.currentSalary}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Current Job Status */}
                              {Profiles?.careerProfile?.currentJobStatus && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Current Job Status
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.currentJobStatus
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Job Availability */}
                              {Profiles?.careerProfile?.jobAvailability && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Job Availability
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.jobAvailability
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Job Seeking Status */}
                              {Profiles?.careerProfile?.jobSeekingStatus && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Job Seeking Status
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.jobSeekingStatus
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Employment Status */}
                              {Profiles?.careerProfile?.employmentStatus && (
                                <div className="md:flex space-x-4 items-start hrp-border-left">
                                  <div className="relative hrp-position flex-shrink-0">
                                    <div className="relative flex items-center justify-center h-4 w-4 bg-yellow-500 rounded-full"></div>
                                  </div>
                                  <div className="w-full">
                                    <div className="pb-3">
                                      <h3 className="text-xl hrp-heading-color   heading-reaponcive-size">
                                        Employment Status
                                      </h3>
                                      <p className="hrp-description pb-5 pt-1">
                                        {
                                          Profiles?.careerProfile
                                            ?.employmentStatus
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Section>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>,
          container
        );
        html2canvas(container, {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          scrollX: 0,
          scrollY: 0,
          windowWidth: container.scrollWidth,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 295; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;

          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          const fileName = `${Profiles?.firstName}-${
            Profiles?.jobTitle?.replace(/ /g, "-") || "Profile"
          }.pdf`;
          pdf.save(fileName);

          document.body.removeChild(container); // Clean up the container
        });
      });
    },
  }));
  return <div></div>;
});

export default ProfileDownload;
