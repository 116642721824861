import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import Select2 from "../Common/Select2Wrapper";
import CustomToastContainer from "../Common/CustomToastContainer";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";

const domainpath = process.env.REACT_APP_DOMAIN;

const EnrollmentEnquiries = () => {
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();
  const toastRef = useRef();

  useEffect(() => {
    const userid = User._id;

    async function getEnrollment() {
      try {
        const response = await api.getEnrollmentList(User?._id);
        if (response.status !== 400) {
          setData(response);
        } else if (response.status === 400) {
          // Handle 400 status if needed
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }

    getEnrollment();
  }, [User?._id]);

  const status = [
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
  ];

  const columns = [
    {
      name: "Course Title",
      selector: (row) => (
        <a
          href={"/dashboard/enrollment-enquiries/" + row?._id}
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.courseTitle}
        </a>
      ),
      width: "30%",
      sortable: true,
    },
    {
      name: "Applicant",
      selector: (row) => `${row?.firstName} ${row?.lastName}`, // Add space between first and last name
      sortable: true,
      width: "10%",
    },
    {
      name: "Phone",
      selector: (row) => row?.mobileNumber,
      sortable: true,
      width: "10%",
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
      width: "15%",
    },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <div
    //       className="relative job-search-label-section singleselect2 statusenroll"
    //       style={{ width: "100%", textAlign: "center" }}
    //     >
    //       <Select2
    //         id="status"
    //         className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
    //         name="status"
    //         multiple={false}
    //         data={status.map((type) => ({
    //           id: type.value,
    //           text: type.label,
    //         }))}
    //         value={row?.status || "Pending"} // Ensure defaultValue is valid
    //         options={{
    //           placeholder: "Select Job Status",
    //           theme: "classic",
    //           width: "resolve",
    //         }}
    //         onSelect={(e) => handlechangeEnrollStatus(e, row?._id)}
    //       />
    //     </div>
    //   ),
    //   style: {
    //     display: "block",
    //     marginTop: "4px",
    //     width: "20%",
    //   },
    //   sortable: true,
    // },
    {
      name: "Date",
      selector: (row) => new Date(row.enrolledAt).toLocaleDateString(),
      sortable: true,
      width: "15%",
    },
  ];

  const handlechangeEnrollStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    const formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.updateEnrollstatus(id, formdata);

      toastRef.current.addToast(res.message, "success");

      // Update status in local data without page reload
      setData((prevData) =>
        prevData.map((item) =>
          item._id === id ? { ...item, status: formdata.status } : item
        )
      );

      setLoading(false);
    } catch (err) {
      toastRef.current.addToast(err, "error");
      setLoading(false);
    }
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Enrollment Enquiries
        </h2>
        <DataTableExtensions columns={columns} data={data}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default EnrollmentEnquiries;
