import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import ApplyJobs from "../popup/Applyjob";
import { usePopup } from "../Common/PopupContext";
const domainpath = process.env.REACT_APP_DOMAIN;
const Jobsapply = () => {
  const { openPopup } = usePopup();
  const User = getUserData();
  const [data, setData] = useState([]);
  useEffect(() => {
    const userid = User._id;
    const status = "Applying";
    async function getjobs() {
      try {
        const response = await api.getapplications(userid, status);
        if (response.status !== 400) {
          const filteredData = response.filter((row) => row.jobId?.title);
          setData(filteredData);
        } else if (response.status === 400) {
          // Handle 400 status if needed
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getjobs();
  }, [User._id]);
  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <div>
          <div>
            <a
              href={domainpath + "/jobs/" + row?.jobId?._id}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.jobId?.title}
            </a>
          </div>
          <div>{row.jobId?.company.name}</div>
        </div>
      ),
      sortable: true,
      width: "40%",
    },
    {
      name: "Applying Date",
      selector: (row) => new Date(row.appliedAt).toLocaleDateString(),
      sortable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1 m-auto">
          <button
            className="manage-edit-btn text-white px-8   py-2 rounded"
            onClick={(e) => jobApply(row.jobId)}
          >
            Apply
          </button>
        </div>
      ),
      width: "40%",
    },
  ];

  const tableData = {
    columns,
    data,
  };
  const handleClosePopup = (message) => {
    //setPopupComponent(null);
    openPopup();
  };
  const jobApply = (jobid) => {
    const popup = (
      <ApplyJobs
        onClose={() => handleClosePopup("Popup closed")}
        JobId={jobid}
      />
    );

    //setPopupComponent(popup);
    openPopup(popup);
  };
  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow hrp-jobs-applying">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Applying Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default Jobsapply;
