import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";

const CustomDataTable = ({
  columns,
  data,
  totalRows,
  rowsPerPageOptions = [10, 25, 50],
  defaultRowsPerPage = 10,
  onPageChange,
  onRowsPerPageChange,
  currentPage,
  handleEdit,
  handleDelete,
  handleView,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(currentPage);
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  // Reset page when rowsPerPage changes
  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const handlePageClick = (page) => {
    onPageChange(page);
  };
  const renderPaginationButtons = () => {
    const pageButtons = [];
    const maxButtonsToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(1, endPage - maxButtonsToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`pagination-button ${i === currentPage ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };
  return (
    <div>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                className="py-2 px-4 border-b text-left bg-gray-100"
                style={{ width: col.width || "auto" }}
              >
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-100">
              {columns.map((col, colIndex) => (
                <td key={colIndex} className="py-2 px-4 border-b">
                  {col.selector ? (
                    col.selector(row)
                  ) : col.name === "Actions" ? (
                    <div className="flex gap-2 w-full item-center justify-center">
                      {row.canEdit && (
                        <button
                          className="manage-edit-btn text-white px-6 py-2"
                          onClick={() => handleEdit(row._id)}
                        >
                          <FeatherIcon icon="edit-2" />
                        </button>
                      )}
                      {row.canDelete && (
                        <button
                          className="manage-delete-btn text-white px-6 py-2"
                          onClick={() => handleDelete(row._id)}
                        >
                          <FeatherIcon icon="trash" />
                        </button>
                      )}
                      {row.canView && (
                        <button
                          className="manage-view-btn text-white px-6 py-2"
                          onClick={() => handleView(row._id)}
                        >
                          <FeatherIcon icon="eye" />
                        </button>
                      )}
                    </div>
                  ) : (
                    row[col.selectorKey]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Always display pagination controls */}
      <div className="pagination-container">
        <button
          onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        <div className="pagination-buttons">{renderPaginationButtons()}</div>
        <button
          onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomDataTable;
