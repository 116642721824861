import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import FeatherIcon from "feather-icons-react";
import CustomDataTable from "../Common/Customsdatatable";
const domainpath = process.env.REACT_APP_DOMAIN;

const ManageJobs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showMessages, setShowMessages] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const toastRef = useRef();
  const [filteredData, setFilteredData] = useState([]);
  const handleDelete = async (jobId) => {
    const response = await api.deletejob(`${jobId}`);
    if (response.status === "200") {
      toastRef.current.addToast(response.message, "success");
      setTimeout(() => {
        fetchJobs(page); // Reload data after deletion
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the job!",
        "error"
      );
    }
  };

  const fetchJobs = async (currentPage = 1) => {
    setLoading(true);
    try {
      const response = showMessages
        ? await api.getAlljobsForAdmin(currentPage, perPage)
        : await api.getJobsByReported(currentPage, perPage);

      if (response.status === 200) {
        const jobs = (response.jobsWithApplicants || response.reportedJobs).map(
          (job) => ({
            ...job,
            canEdit: true, // Set default to true; adjust based on your requirements
            canDelete: true, // Set default to true; adjust based on your requirements
            canView: false, // Set default to true; adjust based on your requirements
          })
        );
        setData(jobs);
        setFilteredData(jobs);
        setTotalRows(response.totalCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs(page);
  }, [page, perPage, searchTerm, showMessages]);

  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  const handlePerRowsChange = async (newPerPage) => {
    if (newPerPage !== perPage) {
      setPerPage(newPerPage);
      setPage(1); // Reset to the first page
    }
  };
  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  //   setPage(1); // Reset to the first page when starting a new search
  // };

  const handleUpdateJobStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    const formdata = { status: e.target.value };
    try {
      const res = await api.UpdateJObStatus(id, formdata);
      toastRef.current.addToast(res.message, "success");
      fetchJobs(page); // Reload data after status update
    } catch (err) {
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };

  const status = [
    { value: "Publish", label: "Published" },
    { value: "Hold", label: "Hold" },
    { value: "Disabled", label: "Disabled" },
    { value: "Closed", label: "Closed" },
    { value: "Draft", label: "Draft" },
    { value: "Block", label: "Block" },
  ];
  const filterData = (jobs, keyword) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    const filtered = jobs.filter((job) => {
      const titleMatch = job.title?.toLowerCase().includes(lowerCaseKeyword);
      const companyMatch = job.company?.name
        .toLowerCase()
        .includes(lowerCaseKeyword);
      const locationMatch =
        job.location?.city?.toLowerCase().includes(lowerCaseKeyword) ||
        job.location?.area?.toLowerCase().includes(lowerCaseKeyword) ||
        job.location?.address?.toLowerCase().includes(lowerCaseKeyword);
      return titleMatch || companyMatch || locationMatch;
    });
    setFilteredData(filtered);
    setTotalRows(filtered.length); // Update total rows for filtered data
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);
    filterData(data, keyword);
    setPage(1); // Filter data based on the search term
  };

  const handleEdit = (jobId) => {
    history.push(`./jobpost/${jobId}`);
  };

  const columns = showMessages
    ? [
        {
          name: "Job Title",
          selector: (row) => (
            <>
              <a
                href={`${domainpath}/jobs/${row?._id}`}
                className="hover:underline"
                style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
              >
                {row?.title || ""}
              </a>
              <br />
              {row.company ? `${row.company?.name}` : ""}
              <br />
              {row?.location?.address ||
                `${row?.location?.city} ${row?.location?.area} ${row?.location?.pincode}`}
            </>
          ),
          width: "25%",
          sortable: true,
        },
        {
          name: "Status",
          selector: (row) => (
            <div className="relative job-search-label-section singleselect2 statusenroll">
              <Select2
                data={status.map((type) => ({
                  id: type.value,
                  text: type.label,
                }))}
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                value={row.status}
                onSelect={(e) => handleUpdateJobStatus(e, row._id)}
                options={{
                  placeholder: "Select Status",
                  width: "100%",
                  theme: "classic",
                }}
              />
            </div>
          ),
          sortable: true,
          width: "20%",
        },
        {
          name: "Applicant/Shortlisted",
          selector: (row) => (
            <a
              href={`/dashboard/job/${row._id}`}
              className="hover:underline"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {`${row.totalApplicants}/${row.shortlistedApplicants}`}
            </a>
          ),
          sortable: true,
          width: "20%",
        },
        {
          name: "Posted On",
          selector: (row) => new Date(row.postedDate).toLocaleDateString(),
          sortable: true,
          width: "10%",
        },
        {
          name: "Actions",
          cell: (row) => {
            const { canEdit = false, canDelete = false, canView = false } = row;
            return canEdit || canDelete || canView ? (
              <div className="flex gap-2 w-full item-center justify-center">
                {canEdit && (
                  <button
                    className="manage-edit-btn text-white px-6 py-2"
                    onClick={() => history.push(`./jobpost/${row._id}`)}
                  >
                    <FeatherIcon icon="edit-2" />
                  </button>
                )}
                {canDelete && (
                  <button
                    className="manage-delete-btn text-white px-6 py-2"
                    onClick={() => handleDelete(row._id)}
                  >
                    <FeatherIcon icon="trash" />
                  </button>
                )}
                {canView && (
                  <button
                    className="manage-view-btn text-white px-6 py-2"
                    onClick={() => history.push(`./jobview/${row._id}`)}
                  >
                    <FeatherIcon icon="eye" />
                  </button>
                )}
              </div>
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>—</div>
            );
          },

          width: "25%",
        },
      ]
    : [
        {
          name: "Job Title",
          selector: (row) => (
            <>
              <a
                href={`${domainpath}/jobs/${row.jobId}`}
                className="hover:underline"
                style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
              >
                {row.jobTitle}
              </a>
              <br />
              {row.reportedBy
                ? `${row.reportedBy.name} (${row.reportedBy.email})`
                : ""}
            </>
          ),
          width: "25%",
          sortable: true,
        },
        {
          name: "Status",
          selector: (row) => (
            <div className="relative job-search-label-section singleselect2 statusenroll">
              <Select2
                data={status.map((type) => ({
                  id: type.value,
                  text: type.label,
                }))}
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                value={row.jobStatus}
                onSelect={(e) => handleUpdateJobStatus(e, row.jobId)}
                options={{
                  placeholder: "Select Status",
                  width: "100%",
                  theme: "classic",
                }}
              />
            </div>
          ),
          sortable: true,
          width: "20%",
        },
        {
          name: "Details",
          selector: (row) => row.details,
          sortable: true,
          width: "25%",
        },
        {
          name: "Reported Date",
          selector: (row) => new Date(row.reportedDate).toLocaleDateString(),
          sortable: true,
          width: "10%",
        },
        {
          name: "Actions",
          cell: (row) => (
            <div className="flex gap-1">
              <button
                className="manage-edit-btn text-white px-6 py-2"
                onClick={() => history.push(`./report/${row._id}`)}
              >
                <FeatherIcon icon="edit-2" />
              </button>
              <button
                className="manage-delete-btn text-white px-6 py-2"
                onClick={() => handleDelete(row.jobId)}
              >
                <FeatherIcon icon="trash" />
              </button>
            </div>
          ),
          width: "15%",
        },
      ];

  return (
    <div className="p-8 hrp-manage-job super-admin-dashboard">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <input
          type="text"
          placeholder="Search Jobs"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ borderRadius: "50px", padding: "10px 20px" }}
          className="border rounded p-2 mb-4"
        />
        <div className="flex gap-2 mb-4">
          <button
            className={`mt-2 px-4 py-2 hrp-ticket-btn ${
              showMessages ? "activebtn" : "inactive"
            } text-white`}
            onClick={() => {
              setShowMessages(true);
              setShowNotes(false);
              setPage(1); // Reset to the first page when changing views
            }}
          >
            Manage Jobs
          </button>
          <button
            className={`mt-2 px-4 py-2 hrp-ticket-btn ${
              showNotes ? "activebtn" : "inactive"
            } text-white`}
            onClick={() => {
              setShowMessages(false);
              setShowNotes(true);
              setPage(1); // Reset to the first page when changing views
            }}
          >
            Reported Jobs
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <CustomDataTable
              columns={columns}
              data={filteredData}
              totalRows={totalRows}
              rowsPerPageOptions={[10, 25, 50]}
              defaultRowsPerPage={perPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePerRowsChange}
              currentPage={page}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </>
        )}
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageJobs;
