import React, { useState, useRef, useEffect } from "react";
import { getUserData } from "../../helpers/utils";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import axios from "axios";
import logoimage from "../../image/company-logo-transparent-png-19.png";
import SearchableDropdown from "../../helpers/SearchableDropdown";
import { jobSectors } from "../../helpers/Object";

const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const Companysettings = () => {
  const user = getUserData();
  const toastRef = useRef();
  const inputRef = useRef(); // Reference to the address input field for Google Autocomplete
  const [error, setError] = useState(null);
  const [companyimg, setcompanyimg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyAddress, setCompanyAddress] = useState(""); // Will store formatted address from Google API
  const [addressData, setAddressData] = useState({}); // Will store full address details like city, area, lat, long
  const [companyIndustry, setCompanyIndustry] = useState(""); // State for company industry

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "uk" }, // Modify this as needed for country restriction
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;
        const addressData = {
          address: place.formatted_address,
          city: "",
          area: "",
          pincode: "",
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        };

        // Parsing the components to get city, area, postal code
        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            addressData.city = component.long_name;
          }
          if (types.includes("sublocality") || types.includes("neighborhood")) {
            addressData.area = component.long_name;
          }
          if (types.includes("postal_code")) {
            addressData.pincode = component.long_name;
          }
        });

        // Set address details and formatted address
        setAddressData(addressData);
        setCompanyAddress(place.formatted_address);
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, []);

  // Fetch company details on component mount
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await api.getCompanybyEmp(user?._id);
        if (response.status === 200) {
          const companyData = response.company;
          setCompanyName(companyData.name);
          setCompanyIndustry(companyData.industry);
          setCompanyDescription(companyData.description);
          setCompanyAddress(companyData.address);
          setcompanyimg(companyData.image);
        } else {
          toastRef.current.addToast("Company details not found", "error");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
        toastRef.current.addToast("Error fetching company details", "error");
      }
    };

    fetchCompanyDetails();
  }, [user._id]);

  const handleUpload = async (selectedFile) => {
    setLoading(true);

    const validTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/gif",
      "image/jiff",
    ];

    // Check if file type is one of the valid image types
    if (!validTypes.includes(selectedFile.type)) {
      setLoading(false);
      toastRef.current.addToast(
        "Only images are allowed (JPG, PNG, WEBP, GIF, JIFF)!",
        "error"
      );
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const response = await axios.post(
        `${domainpath}/api/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.imagePath) {
        setcompanyimg(response.data.imagePath);
        setLoading(false);

        toastRef.current.addToast("Image uploaded successfully", "success");
      } else {
        setLoading(false);

        toastRef.current.addToast(response?.message, "error");
      }
    } catch (error) {
      toastRef.current.addToast("Error uploading image", "error");
    }
  };

  const handleSubmit = async (e) => {
    console.log(companyIndustry);

    e.preventDefault(); // Prevent form submission refresh
    const data = {
      employerId: user?._id,
      name: companyName,
      image: companyimg,
      description: companyDescription,
      address: companyAddress, // Will send formatted address
      addressData, // Send full address data (city, area, lat, long)
      industry: companyIndustry, // Add the selected industry to the payload
    };

    try {
      const response = await api.PostCompanybyEmp(data);
      if (response.status === 201) {
        toastRef.current.addToast(response.message, "success");
      } else {
        toastRef.current.addToast(response.message, "error");
      }
    } catch (error) {
      console.error("Error:", error);
      toastRef.current.addToast("Error updating company details", "error");
    }
  };

  return (
    <>
      <div className="p-8 account-settings-main">
        <div className="mb-8">
          <h1 className="account-settings">Company Settings</h1>
          <form onSubmit={handleSubmit}>
            <div className="relative lg:w-52 lg:h-40 hrp-profile-header-image">
              <label
                htmlFor="companyimg"
                className="absolute transform -translate-x-1/2 -translate-y-1/2 text-gray-700 font-medium hrp-label-position"
              >
                <input
                  type="file"
                  id="companyimg"
                  accept="image/*"
                  name="companyimg"
                  onChange={(e) => handleUpload(e.target.files[0])}
                  className="hidden"
                />
                <div className="w-full bg-white rounded-full flex items-center justify-center border-2 border-white hrp-profile-section-image relative group">
                  <img
                    alt="Profile"
                    className="w-full h-full rounded-full"
                    src={
                      companyimg
                        ? companyimg.startsWith("http")
                          ? companyimg
                          : `${domainpath}/uploads/${companyimg}`
                        : logoimage
                    }
                    style={{ width: " 120px" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = logoimage;
                    }}
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-full">
                    <span className="text-white text-sm font-semibold text-center">
                      Change Logo
                    </span>
                  </div>
                </div>
              </label>
            </div>
            <div className="grid lg:grid-cols-3 lg:gap-5">
              <div className="animated-form profileheadlinediv">
                <div className="form-group">
                  <input
                    id="companyName"
                    className="border rounded w-full py-2 px-3 text-gray-700"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    type="text"
                    placeholder=""
                    required
                  />
                  <label htmlFor="companyName" className="profileheadline">
                    Company Name
                  </label>
                </div>
              </div>
              <div className="animated-form profileheadlinediv">
                <div className="form-group">
                  <input
                    id="companyAddress"
                    className="border rounded w-full py-2 px-3 text-gray-700"
                    defaultValue={companyAddress}
                    ref={inputRef} // Input ref for Google Autocomplete
                    type="text"
                    placeholder="Enter company address"
                    required
                  />
                  <label htmlFor="companyAddress" className="profileheadline">
                    Company Address
                  </label>
                </div>
              </div>
              <div className="animated-form profileheadlinediv">
                <div className="form-group">
                  <>
                    <SearchableDropdown
                      options={jobSectors.map((sector) => ({
                        value: sector.value,
                        label: String(sector.label),
                      }))}
                      className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                      multiple={false}
                      name="industry"
                      value={jobSectors.find(
                        (sector) => sector.value === companyIndustry
                      )}
                      onChange={(selectedOption) => {
                        // Check if `selectedOption` is valid and not undefined
                        if (selectedOption) {
                          setCompanyIndustry(selectedOption);
                        }
                      }}
                    />
                    <label className="block text-sm font-medium text-gray-700 SearchableDropdown">
                      Job Industry / Sector
                    </label>
                  </>
                </div>
              </div>
            </div>

            <div className="animated-form profileheadlinediv">
              <div className="form-group">
                <textarea
                  id="companyDescription"
                  className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer custom-scrollbar"
                  value={companyDescription}
                  onChange={(e) => setCompanyDescription(e.target.value)}
                  placeholder=""
                  required
                />
                <label
                  htmlFor="companyDescription"
                  className="form-input-label absolute top-2 left-4  peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600"
                >
                  Company Description
                </label>
              </div>
            </div>

            {error && <div className="text-red-500">{error}</div>}
            <div>
              <button
                type="submit"
                className="ViewDetails-btn text-white px-4 py-2"
              >
                Update Settings
              </button>
            </div>
          </form>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <CustomToastContainer ref={toastRef} />
      </div>
    </>
  );
};

export default Companysettings;
