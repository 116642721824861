import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Assuming you're using react-router for routing
import axios from "axios";

const VerifyEmail = () => {
  const { token } = useParams(); // Get the token from the URL
  const [verificationStatus, setVerificationStatus] = useState("Verifying...");

  useEffect(() => {
    // Call the verification API
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`/api/verify-email/${token}`);
        setVerificationStatus(
          "Email verified successfully! You can now log in."
        );
      } catch (error) {
        setVerificationStatus(
          "Invalid or expired verification link. Please try again."
        );
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token]);

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h2 style={{ margin: "40px" }}>{verificationStatus}</h2>
      {verificationStatus.includes("successfully") && (
        <a
          href="/login"
          style={{
            padding: "10px 20px",
            backgroundColor: "#ffa500",
            color: "#fff",
            textDecoration: "none",
            borderRadius: "4px",
          }}
        >
          Go to Login
        </a>
      )}
    </div>
  );
};

export default VerifyEmail;
