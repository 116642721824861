// Login.js
import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import { usePopup } from "../Common/PopupContext";
import { NavLink, useHistory } from "react-router-dom";
import { encryptData, decryptData } from "../../helpers/encryption";
import CustomToastContainer from "../Common/CustomToastContainer";
import VisitorTracker from "../Common/VisitorTracker.js";
import ForgotPassword from "./ForgotPassword";
import FeatherIcon from "feather-icons-react";
const Login = ({
  onClose,
  redirect_url,
  candidate,
  employee,
  data,
  jobid,
  savejobid,
}) => {
  const history = useHistory();
  const toastRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [role, setSelectedRole] = useState("applicant");
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const loginButtonRef = useRef(null);
  const registerButtonRef = useRef(null);
  useEffect(() => {
    const encryptedUserData = localStorage.getItem("user");
    if (encryptedUserData) {
      const userData = decryptData(encryptedUserData);
      if (userData && userData.accessToken) {
        history.push(redirect_url || "/dashboard");
      }
    }
  }, [history, redirect_url]);

  useEffect(() => {
    if (
      candidate !== undefined &&
      candidate !== null &&
      typeof candidate === "object" &&
      Object.keys(candidate).length > 0
    ) {
      setSelectedRole("applicant");
    }
    if (
      employee !== undefined &&
      employee !== null &&
      typeof employee === "object" &&
      Object.keys(employee).length > 0
    ) {
      setSelectedRole("employer");
    }
    if (data) {
      setEmail(data?.email);
    }
  }, [candidate, employee, data]);

  const handleReportJob = async (event, jobId, userid) => {
    event.preventDefault();

    setLoading(true);

    try {
      const candidateId = userid;
      const reportdata = {
        jobId,
        candidateId,
      };
      //console.log(reportdata);

      const response = await api.updatereportjob(reportdata);
      if (response.status === 201) {
      }
    } catch (error) {
      toastRef.current.addToast(error.response.data.message, "error");
      setLoading(false);
    }
  };

  const handleSavedJob = async (event, jobId, userid) => {
    event.preventDefault();

    setLoading(true);

    try {
      const candidateId = userid;

      //console.log(reportdata);

      const response = await api.updatesavedjob(candidateId, jobId);
      if (response.status === 201) {
      }
    } catch (error) {
      toastRef.current.addToast(error.response.data.message, "error");
      setLoading(false);
    }
  };
  function handleClick(showArray, hideArray) {
    showArray.map((id) => {
      document.getElementById(id).style = "display:block";
    });
    hideArray.map((id) => {
      document.getElementById(id).style = "display:none";
    });
  }
  function start() {
    document.querySelector("#step_2").classList.add("dv-step_2-show");
  }
  const [validationMessage, setValidationMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }
    setLoading(true);
    try {
      const userData = { email, password };
      const response = await api.Login(userData);
      const { token, refreshToken } = response;
      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        const { accessToken, refreshToken, _id, role } = response;
        const encryptedUserData = encryptData(response);
        localStorage.setItem("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (keepMeLoggedIn) {
          localStorage.setItem("user", encryptedUserData);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
        } else {
          sessionStorage.setItem("user", encryptedUserData);
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("refreshToken", refreshToken);
        }
        setTimeout(() => {
          var redirectdata = localStorage.getItem("redirect_url");
          // Correct the condition to check for valid redirectdata
          if (
            redirectdata !== undefined &&
            redirectdata !== "" &&
            redirectdata !== null
          ) {
            setLoading(false);
            window.location.href = redirectdata;
            localStorage.removeItem("redirect_url");
          } else if (
            redirect_url !== null &&
            redirect_url !== undefined &&
            jobid
          ) {
            setLoading(false);
            handleReportJob(e, jobid, response._id);
            setTimeout(() => {
              // window.location.href = redirect_url;
            }, 2000);
          } else if (savejobid) {
            setLoading(false);
            handleSavedJob(e, savejobid, response._id);
            setTimeout(() => {
               window.location.href = redirect_url;
            }, 2000);
          } else if (redirect_url !== null && redirect_url !== undefined) {
            setLoading(false);
            window.location.href = redirect_url;
          } else {
            setLoading(false);
            window.location.href = "/dashboard";
          }
        }, 2000);
      } else if (response.status == 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );

        toastRef.current.addToast(response.data.msg, "error");
        setLoading(false);
        // Handle unexpected response status
      }
    } catch (error) {
      const errorMessage = error.response?.data?.msg || "Invalid Credentials";
      toastRef.current.addToast(errorMessage, "error");
      setLoading(false);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    const googleAuthUrl = () => {
      const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // process.env.GOOGLE_CLIENT_ID;
      const googleCallbackUrl = process.env.REACT_APP_GOOGLE_CALLBACK_URL; // process.env.REACT_APP_GOOGLE_CALLBACK_URL;
      const scope = "profile email";
      const redirectUri = encodeURIComponent(googleCallbackUrl);
      const state = JSON.stringify({
        role: role === "employer" ? "employer" : "applicant",
      });
      return `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodeURIComponent(
        state
      )}&access_type=offline&prompt=consent`;
    };
    window.location.href = googleAuthUrl();
  };

  const handleKeepMeLoggedInChange = (e) => {
    setKeepMeLoggedIn(e.target.checked);
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    if (!firstName) {
      setValidationMessage("First Name is required.");
      return;
    }

    if (!lastName) {
      setValidationMessage("Last Name is required.");
      return;
    }

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!phone) {
      setValidationMessage("Phone number is required.");
      return;
    }

    if (role == "employer" && !company) {
      setValidationMessage("Company name is required.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const name = `${firstName} ${lastName}`;
      const userData = { firstName, lastName, name, email, password, phone };

      let response; // Declare response with let so it can be reassigned
      if (role === "employer") {
        response = await api.employerSignup(userData);
      } else if (role === "applicant") {
        response = await api.applicantSignup(userData);
      }
      if (response.status !== 400) {
        toastRef.current.addToast("User Created Successfully", "success");
        setLoading(false);
        setTimeout(() => {
          document.getElementById("step_2").style = "display:none";
          document.getElementById("step_1").style = "display:block";
        }, 1000);

        // Redirect or perform other actions as needed
      }
    } catch (error) {
      toastRef.current.addToast(error.response.data.msg, "error");
      setLoading(false);
      console.error(error);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  function formatPhoneNumber(value) {
    // Remove all non-numeric characters from the input
    const cleaned = ("" + value).replace(/\D/g, "");

    // Limit the input to 11 digits
    const limited = cleaned.substring(0, 11);

    // Check if the input has the correct length for formatting
    if (limited.length >= 10) {
      // Format the number in the 123-456-7890 format
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6,
        10
      )}`;
    } else if (limited.length > 6) {
      // Format the number partially as 123-456-
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6
      )}`;
    } else if (limited.length > 3) {
      // Format the number partially as 123-
      return `${limited.slice(0, 3)}-${limited.slice(3)}`;
    } else {
      // Return the cleaned input if less than 3 digits
      return limited;
    }
  }

  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  // const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = () => {
    //setPopupComponent(null);
    openPopup();
  };
  const handleforgot = () => {
    setLoading(true);

    if (redirect_url != undefined) {
      const popup = (
        <ForgotPassword
          onClose={() => handleClosePopup("Popup closed")}
          redirect_url={redirect_url}
        />
      );
      //setPopupComponent(popup);
      openPopup(popup);
    } else {
      history.push("./forgotpassword");
      window.location.reload();
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numeric values and limit to 10 digits
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericValue.length <= 10) {
      setPhone(numericValue); // Update state with numeric value up to 10 digits
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="w-full flex justify-center h-screen  login_inform-inner m-auto p-20">
        <div className="flex">
          <div>
            <div
              className="form_upto  m-3 loginDiv loginDiv-width "
              id="step_1"
              style={{
                display:
                  (candidate && Object.keys(candidate).length !== 0) ||
                  (employee && Object.keys(employee).length !== 0)
                    ? "none"
                    : "block",
              }}
            >
              <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
                <div className="mb-1 text-center py-10">
                  <div className="text-center HirePros-login-heading ">
                    <h2 className="  ">Hi, Welcome Back !</h2>
                  </div>
                </div>
                {isMessage && (
                  <button className="btn-loginmessage" type="text" danger>
                    {isMessage}
                  </button>
                )}
                <form
                  className="login-form-section animated-form"
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default behavior
                      loginButtonRef.current.click(); // Manually trigger button click
                    }
                  }}
                >
                  <div className="mb-1 form-group">
                    <input
                      required
                      className="  border rounded w-full py-2 px-3 text-gray-700  "
                      type="text"
                      id="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="Email-label">
                      Email
                    </label>
                  </div>
                  <div className="mb-2 form-group relative">
                    <input
                      required
                      id="password"
                      className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password" className="password-label">
                      Password
                    </label>

                    {/* Eye Icon */}
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                    >
                      {showPassword ? (
                        // Eye Slash Icon
                        <FeatherIcon
                          icon="eye-off"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        // Eye Icon
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-gray-500"
                          viewBox="0 0 576 512"
                        >
                          <path
                            fill="currentColor"
                            d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  {validationMessage && (
                    <div className="mb-4 text-red-500">{validationMessage}</div>
                  )}
                  <div className="mb-6 flex items-center justify-between forgot-password-section-font">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={keepMeLoggedIn}
                        onChange={handleKeepMeLoggedInChange}
                      />
                      <span className="ml-2 text-gray-700">Remember Me</span>
                    </label>
                    <NavLink
                      className="forgot-pass-link color"
                      to="/forgotpassword"
                      onClick={() => handleforgot()}
                    >
                      Forgot Password?
                    </NavLink>
                  </div>
                  <div>{isMessage != "null" ? isMessage : ""}</div>
                  <div className="">
                    <button
                      ref={loginButtonRef}
                      className="  login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      {isLoading ? "Loading..." : "Log in"}
                    </button>
                  </div>
                  <div className="line-after-login my-2">
                    <div className="line-after-login-inner">
                      <div className="line-after-login-first"></div>
                      <div className="line-after-login-or">or</div>
                      <div className="line-after-login-second"></div>
                    </div>
                  </div>
                  <div className="mb-6">
                    <button
                      className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleGoogleLogin}
                    >
                      {isLoading ? "Loading..." : "Sign in with google"}
                    </button>
                  </div>
                  <div className="keepForget text-center">
                    <p>
                      Don't have an account?
                      <span
                        onClick={(e) => {
                          handleClick(["step_2"], ["step_1"]);
                          start();
                        }}
                      >
                        <NavLink
                          className="forgot-pass-link color cursor-pointer	"
                          to=""
                        >
                          Signup
                        </NavLink>
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="form_upto  m-3 loginDiv loginDiv-width">
              <div
                className="hidden  loginDiv"
                id="step_2"
                style={{
                  display:
                    (candidate && Object.keys(candidate).length !== 0) ||
                    (employee && Object.keys(employee).length !== 0)
                      ? "block"
                      : "none",
                }}
              >
                <div className="flex justify-center hrp-register-section items-center login_inform-inner">
                  <div className="w-full">
                    <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
                      <div className="mb-1 text-center py-5 HirePros-login-heading">
                        <h2 className="">Create Account</h2>
                      </div>
                      <div className="register-user-select">
                        <div className="flex justify-center items-center w-full">
                          <div className="relative inline-flex items-center bg-blue-100 rounded-full w-full">
                            <span
                              className={`px-4 py-2 rounded-full cursor-pointer w-1/2 ${
                                role === "applicant"
                                  ? "bg-white active-border-btn "
                                  : ""
                              }`}
                              onClick={() => setSelectedRole("applicant")}
                            >
                              Applicant
                            </span>
                            <span
                              className={`px-4 py-2 rounded-full cursor-pointer w-1/2 ${
                                role === "employer"
                                  ? "bg-white active-border-btn "
                                  : ""
                              }`}
                              onClick={() => setSelectedRole("employer")}
                            >
                              Employer
                            </span>
                          </div>
                        </div>
                      </div>
                      <form
                        onSubmit={handleRegisterSubmit}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault(); // Prevent default behavior
                            registerButtonRef.current.click(); // Manually trigger button click
                          }
                        }}
                        className="register-form-section animated-form"
                      >
                        <div
                          className={`mb-2 ${"md:grid md:grid-cols-2 md:gap-x-5"}`}
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              id="firstName"
                              placeholder=""
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label
                              htmlFor="firstName"
                              className="form-input-label"
                            >
                              First Name
                            </label>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              id="lastName"
                              placeholder=""
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label
                              htmlFor="lastName"
                              className="form-input-label"
                            >
                              Last Name
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            id="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=""
                            className="shadow w-full border rounded py-2 px-3"
                          />
                          <label htmlFor="email" className="form-input-label">
                            Email
                          </label>
                        </div>
                        <div
                          className={`mb-2 ${
                            role === "employer"
                              ? "md:grid md:grid-cols-2 md:gap-x-5"
                              : "md:grid md:grid-cols-1 md:gap-x-5"
                          }`}
                        >
                          <div
                            className={`form-group ${
                              role !== "employer" ? "col-span-2" : ""
                            }`}
                          >
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              required
                              value={phone}
                              onChange={(e) => handleChange(e)}
                              placeholder=""
                              maxLength={13} // Setting the maximum length to 10 digits
                              // inputMode="tel"
                              // pattern="\d{3}-\d{3}-\d{4}"
                              // title="Phone number should be in the format: 123-456-7890"
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label htmlFor="phone" className="form-input-label">
                              Phone
                            </label>
                          </div>

                          {role === "employer" && (
                            <div className="form-group">
                              <input
                                id="company"
                                type="text"
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder=""
                                className="shadow w-full border rounded py-2 px-3"
                              />
                              <label
                                htmlFor="company"
                                className="form-input-label"
                              >
                                Company
                              </label>
                            </div>
                          )}

                          {/* Password and Confirm Password in one row with two columns */}
                          <div
                            className={`form-group ${
                              role === "employer" ? "col-span-1" : "col-span-1"
                            }`}
                          >
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              required
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder=""
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label
                              htmlFor="password"
                              className="form-input-label"
                            >
                              Password
                            </label>
                            <span
                              onClick={togglePasswordVisibility}
                              className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                            >
                              {showPassword ? (
                                // Eye Slash Icon
                                <FeatherIcon
                                  icon="eye-off"
                                  style={{ width: "20px", height: "20px" }}
                                />
                              ) : (
                                // Eye Icon
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 text-gray-500"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                                  />
                                </svg>
                              )}
                            </span>
                          </div>

                          <div
                            className={`form-group ${
                              role === "employer" ? "col-span-1" : "col-span-1"
                            }`}
                          >
                            <input
                              id="cpassword"
                              type="password"
                              placeholder=""
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                              className="shadow w-full border rounded py-2 px-3"
                            />
                            <label
                              htmlFor="cpassword"
                              className="form-input-label"
                            >
                              Confirm Password
                            </label>
                          </div>
                        </div>

                        {error && (
                          <p className="text-red-500 text-xs italic">{error}</p>
                        )}
                        <div className=" register-promotional-emails mb-6">
                          <input type="checkbox" name="terms" required />
                          <div className="terms-policy">
                            I agree{" "}
                            <a href="/terms-conditions"> Terms condation </a> &{" "}
                            <a href="/privacy-policy"> Privacy Policy</a>
                          </div>
                        </div>
                        <div className="Forgot_00">
                          <div className="">
                            <button
                              registerButtonRef
                              className="  text-white py-2  px-4 rounded w-full  register-form-btn"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>
                          <div className="line-after-login my-2">
                            <div className="line-after-login-inner">
                              <div className="line-after-login-first"></div>
                              <div className="line-after-login-or">or</div>
                              <div className="line-after-login-second"></div>
                            </div>
                          </div>
                          <div className="mb-2">
                            <button
                              className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                              type="button"
                              onClick={handleGoogleLogin}
                            >
                              {isLoading ? "Loading..." : "Sign Up with google"}
                            </button>
                          </div>
                          <div className="text-center Any-Account-section">
                            <p>
                              Already have an Account?
                              <span
                                className="cursor-pointer	"
                                onClick={() =>
                                  handleClick(["step_1"], ["step_2"])
                                }
                              >
                                Login here.
                              </span>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className=" close-btn-style  relative text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-7	 h-7 -top-6  text-gray-600 hover:text-gray-900"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <CustomToastContainer ref={toastRef} />
        <VisitorTracker />
      </div>
    </>
  );
};

export default Login;
