import React, { useState, useRef } from "react";
import { submitform } from "../../helpers/Formhelper";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";

const RegisterForm = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  // const [role, setrole] = useState([
  //   "superadmin",
  //   "employer",
  //   "applicant",
  //   "staff",
  //   "seller",
  // ]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    formData.name = formData.firstName + " " + formData.lastName;
    try {
      const response = await api.signup(formData);
      if (response.status !== 500) {
        setLoading(false);
        toastRef.current.addToast("User Created Successffully", "success");
        setTimeout(() => {
          onClose("");
          window.location.reload();
        }, 500);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setFormData({ ...formData, password: password });
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    phone: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [role, setrole] = useState([
    // "superadmin",
    "employer",
    "applicant",
    "staff",
    "seller",
  ]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About">
                Create An User
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              id="staffcreate"
              className="register-form-section animated-form"
            >
              <div className="mb-2 grid grid-cols-2 gap-x-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={(e) => handleInputChange(e)}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="firstName" className="form-input-label">
                    First Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={(e) => handleInputChange(e)}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="lastName" className="form-input-label">
                    Last Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    onChange={(e) => handleInputChange(e)}
                    name="email"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="email" className="form-input-label">
                    Email
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    id="phone"
                    maxLength={13}
                    name="phone"
                    onInput={(e) => {
                      if (e.target.value.length > 13)
                        e.target.value = e.target.value.slice(0, 13);
                    }}
                    onChange={(e) => handleInputChange(e)}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="phone" className="form-input-label">
                    Phone
                  </label>
                </div>
                <div className=" form-group">
                  <input
                    id="password"
                    className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password" className="password-label">
                    Password
                  </label>

                  {/* Eye Icon */}
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? (
                      // Eye Slash Icon
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 640 512"
                      >
                        <path
                          fill="currentColor"
                          d="M634.82 471l-49.65-38.94C609.75 411.33 640 366.31 640 320c0-70.68-112.49-192-320-192-49.66 0-92.89 7.73-129.76 19.92L132.16 49.15a16.03 16.03 0 00-22.46 2.58L85.65 83.05a16.03 16.03 0 002.58 22.47l49.64 38.94C30.24 191.36 0 236.38 0 288c0 70.68 112.49 192 320 192 49.66 0 92.89-7.73 129.76-19.92l58.08 45.77a16.03 16.03 0 0022.46-2.58l24.06-31.32a16.03 16.03 0 00-2.58-22.47zM320 160c99.21 0 176.31 54.55 214.36 107.02-4.39 6.63-9.07 13.4-14.28 20.16L444.8 232.27c-12.48-8.2-27.07-13.16-43.23-13.16a80 80 0 00-80 80c0 16.16 4.96 30.75 13.16 43.23l-54.91 75.28C197.69 392.11 144 337.45 105.64 288 126.71 256.28 195.24 160 320 160zm0 192a48 48 0 110-96 48 48 0 010 96z"
                        />
                      </svg>
                    ) : (
                      // Eye Icon
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="confirm-password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label
                    htmlFor="confirm-password"
                    className="form-input-label"
                  >
                    Confirm Password
                  </label>
                </div>
              </div>
              {error && <p className="text-red-500 text-xs italic">{error}</p>}
              <div className="form-group">
                <select
                  id="role"
                  name="role"
                  className="shadow w-full border rounded py-2 px-3"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {role.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
                <label htmlFor="role" className="form-input-label">
                  Role
                </label>
              </div>

              <div className="flex ">
                <button
                  type="submit"
                  className="hrp-find-course-btn py-2 px-10 rounded-full w-full"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default RegisterForm;
