import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ChatList from "../../helpers/ChatList";
import MessagePanel from "../../helpers/MessagePanel";
import { io } from "socket.io-client";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";

// Initialize socket connection to the server

const Messages = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const scrollRef = useRef(null);
  const User = getUserData();

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  let socket;
  useEffect(() => {
    // Declare socket here

    if (User != "" && User != null) {
      socket = io("wss://socket.hirepros.co.uk");
      socket.on("connect", () => {
        // //console.log("Connected to WebSocket server");
      });

      socket.on("disconnect", () => {
        // //console.log("Disconnected from WebSocket server");
      });

      socket.on("receiveMessage", handleReceiveMessage);
    }

    // Clean up socket connection when the component unmounts
    return () => {
      if (socket) {
        socket.off("receiveMessage", handleReceiveMessage);
        socket.disconnect(); // Ensure the socket connection is properly closed
      }
    };
  }, [User, selectedChat, messageList]);

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    if (chat.applicationId) {
      socket.emit("joinRoom", { applicationId: chat.applicationId });
      loadMessages(chat.applicationId);
    }
  };

  useEffect(() => {
    async function getmessages() {
      const id = User?._id;
      try {
        const response = await api.getMessages(id);
        setChats(response?.data);
        if (response?.data.length > 0) {
          const firstChat = response?.data[0];
          setSelectedChat(firstChat);
          loadMessages(firstChat?.applicationId); // Load messages for the first chat
        }
      } catch (error) {
        console.error("Failed to load messages:", error);
      }
    }
    getmessages();
  }, []);
  const loadMessages = async (applicationId) => {
    try {
      const response = await api.getMessagesByApplications(applicationId);
      const participants = response?.conversation.participants;

      const messages = response?.conversation.messages.map((msg, index) => {
        const sender = participants.find(
          (participant) => participant._id === msg.senderId
        );

        return {
          user: sender ? sender.name : "", // Map sender ID to participant name
          senderId: msg.sender?._id, // Correctly set the senderId
          text: msg.message, // Ensure the message content is retrieved from msg
          timestamp: moment(msg.createdAt).format("hh:mm A"), // Format the timestamp
          key: index,
        };
      });

      setMessageList(messages);
      scrollToBottom();
    } catch (error) {
      console.error("Failed to load messages:", error);
    }
  };

  const handleSendMessage = (text) => {
    if (selectedChat && text.trim() !== "") {
      const sender = selectedChat.participants.find(
        (participant) => participant._id === User?._id
      );

      const newMessage = {
        user: sender ? sender.name : "Unknown Sender",
        senderId: User?._id, // Set the senderId to the current user's ID
        text,
        timestamp: moment().format("hh:mm A"),
        local: true, // Temporary flag to prevent duplication from WebSocket
      };

      // Update the chat locally by adding the new message to the current message list
      setMessageList((prevMessages) => [...prevMessages, newMessage]);

      // Emit the message to the socket server with senderId
      socket.emit("sendMessage", {
        applicationId: selectedChat.applicationId,
        senderId: User?._id, // Send the correct senderId to the server
        message: text,
      });

      scrollToBottom();
    }
  };

  const handleReceiveMessage = (data) => {
    // Prevent adding duplicate messages that were just sent locally
    const isDuplicate = messageList.some(
      (msg) => msg.text === data.message && msg.local
    );

    if (
      !isDuplicate &&
      selectedChat &&
      data.applicationId === selectedChat.applicationId
    ) {
      setMessageList((prevMessages) => [
        ...prevMessages,
        {
          user: data.senderId === User._id ? User.name : "Other User",
          text: data.message,
          timestamp: moment(data.createdAt).format("hh:mm A"),
        },
      ]);
      scrollToBottom();
    } else {
      // Remove the temporary local flag once confirmed by the server
      setMessageList((prevMessages) =>
        prevMessages.map((msg) =>
          msg.local && msg.text === data.message
            ? { ...msg, local: false }
            : msg
        )
      );
    }
  };

  return (
    <>
      {chats?.length > 0 ? (
        <div className="flex main-chat-panel">
          <div className="w-1/3 border-r overflow-y-auto">
            <ChatList chats={chats} onSelectChat={handleSelectChat} />
          </div>
          <div className="w-2/3 flex flex-col">
            {selectedChat ? (
              <div className="flex-1 overflow-y-auto p-4" ref={scrollRef}>
                <MessagePanel
                  selectedChat={selectedChat}
                  onSendMessage={handleSendMessage}
                  messageList={messageList} // Pass the updated message list
                />
              </div>
            ) : (
              <div className="flex-1 p-4 flex items-center justify-center">
                Select a chat to start messaging
              </div>
            )}
          </div>
        </div>
      ) : (
        <p className="text-gray-500 text-center text-xl">No Messages Found</p>
      )}{" "}
    </>
  );
};

export default Messages;
