import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const originalError = console.error;
console.error = (...args) => {
  if (/defaultProps has been deprecated/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

// Using React 18+ createRoot API
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
