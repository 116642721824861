import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
const domainpath = process.env.REACT_APP_DOMAIN;
const domainPath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const Managejobs = () => {
  let paramdata = useParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [statusCounts, setStatusCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toastRef = useRef();

  const statuses = [
    "All",
    "Awaiting Review",
    "Reviewed",
    "Shortlisted",
    "Interview Scheduled",
    "Interviewed",
    "In Review",
    "Offered",
    "Hired",
    "Rejected",
  ];

  const columns = [
    {
      name: "Applicant Image",
      selector: (row) =>
        row?.profieimg && row?.profieimg !== "null" ? (
          row.profieimg.startsWith("https://") ? (
            <img
              className="image"
              width={60}
              height={60}
              style={{ borderRadius: "40px" }}
              src={row.profieimg}
              alt="Applicant"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop in case default image fails
                e.target.src = `${domainPath}/uploads/deafultman.png`;
              }}
            />
          ) : (
            <img
              className="image"
              width={60}
              style={{ borderRadius: "40px" }}
              height={60}
              src={`${domainPath}/uploads/${row.profieimg
                .replace("/uploads/uploads/", "/uploads/")
                .replace(/^\/uploads\//, "")}`}
              alt="Applicant"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop in case default image fails
                e.target.src = `${domainPath}/uploads/deafultman.png`;
              }}
            />
          )
        ) : (
          <img
            className="image"
            width={60}
            style={{ borderRadius: "40px" }}
            height={60}
            src={`${domainPath}/uploads/deafultman.png`}
            alt="Default"
          />
        ),
      sortable: true,
    },
    {
      name: "Applicant  Name",
      selector: (row) => (
        <a
          href={`${domainpath}/profile/${row?.profile}`}
          className="hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.applicantname}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Application Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Score",
      selector: (row) => {
        let color;
        if (row?.score < 60) {
          color = "font-semibold text-lg text-red-500";
        } else if (row?.score < 80) {
          color = "font-semibold text-lg text-yellow-500";
        } else {
          color = "font-semibold text-lg text-green-500";
        }
        return <span className={color}>{row?.score}%</span>;
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button
            className=" ManageApplication-View px-6 py-2 text-white "
            onClick={() =>
              history.push(`/dashboard/application/${row?.id}`, row?.profile)
            }
          >
            View Application
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    async function getJobs() {
      try {
        const response = await api.getAllApplicationByJObId(paramdata?.jobid);
        if (response.status !== 400) {
          const jobTitle = response.jobTitle || "Unknown Title";
          const jobLocation = response.jobLocation || "Unknown Location";
          const company = response.company || "Unknown Company";
          const jobStatus = response?.jobStatus || "Unknown Status";

          const data = response.applications.map((application) => ({
            jobTitle,
            joblocation: jobLocation,
            company,
            jobstatus: jobStatus,
            id: application?.applicationId,
            applicantname:
              application?.applicantId?.name || "Unknown Applicant",
            profile: application.profileId,
            profieimg: application.profileImg,
            status: application.status,
            score: application.score !== "N/A" ? application.score : "0",
          }));
          calculateStatusCounts(data);
          setData(data);
          setFilteredData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    getJobs();
  }, []);
  useEffect(() => {
    filterDataByStatus(selectedStatus, data);
    calculateStatusCounts(data);
  }, [selectedStatus, data]);

  const filterDataByStatus = (selectedStatus, data) => {
    if (selectedStatus === "All") {
      setFilteredData(data); // If 'All', show all data
    } else if (selectedStatus === "Awaiting Review") {
      // "Awaiting Review" includes "Applied"
      const filtered = data.filter(
        (application) =>
          application.status === "Awaiting Review" ||
          application.status === "Applied"
      );
      setFilteredData(filtered);
    } else {
      const filtered = data.filter(
        (application) => application.status === selectedStatus
      );
      setFilteredData(filtered); // Set filtered data based on status
    }
  };

  // Function to calculate status counts
  const calculateStatusCounts = (applications) => {
    const counts = {
      All: applications.length,
    };
    statuses.slice(1).forEach((status) => {
      if (status === "Awaiting Review") {
        // "Awaiting Review" includes "Applied"
        counts[status] = applications.filter(
          (application) =>
            application.status === "Awaiting Review" ||
            application.status === "Applied"
        ).length;
      } else {
        counts[status] = applications.filter(
          (application) => application.status === status
        ).length;
      }
    });
    setStatusCounts(counts);
  };
  const tableData = {
    columns,
    data: filteredData,
  };

  return (
    <div className="p-8">
      <h2 className="text-xl font-bold mb-4">Manage Applications</h2>
      <div className="bg-white p-4 rounded-lg shadow">
        <div
          className="flex gap-2 mb-4 overflow-x-auto custom-scrollbar"
          style={{ whiteSpace: "nowrap", paddingBottom: "10px" }}
        >
          {statuses.map((status) => (
            <button
              key={status}
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                selectedStatus === status ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => setSelectedStatus(status)}
            >
              {status} ({statusCounts[status] || 0})
            </button>
          ))}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Managejobs;
