import React, { useState, useEffect } from "react";
import api from "../../Api";
import { useLocation } from "react-router-dom";
import SearchCourse from "../Website/SearchCourseForm";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VisitorTracker from "../Common/VisitorTracker.js";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;

const CoursePage = () => {
  const [data, setdata] = useState([]);
  const [searchjobdata, setsearchjobdata] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    async function getCourses() {
      try {
        let response;

        response = await api.getAllCourses();

        if (response.status !== 400) {
          setdata(response);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    getCourses();
  }, []);

  function CourseCard({ course }) {
    return (
      <div className="bg-white shadow-md rounded-lg p-3 flex mb-4 hrp-find-course-card ">
        <div>
          <a href={clientdomainpath + "/courses/" + course._id}>
            {" "}
            <img
              src={
                course.courseThumbnail &&
                course.courseThumbnail.trim() !== "" &&
                course.courseThumbnail !== "NA"
                  ? course.courseThumbnail.startsWith("http://") ||
                    course.courseThumbnail.startsWith("https://")
                    ? course.courseThumbnail
                    : course.courseThumbnail.startsWith("/uploads/")
                    ? `${domainpath}${course.courseThumbnail}`
                    : `${domainpath}/uploads/${course.courseThumbnail}`
                  : `${domainpath}/uploads/defaultblog.png` // Use default if the logo is not present
              }
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop
                e.target.src = `${domainpath}/uploads/defaultblog.png`; // Use the default image on error
              }}
              alt={`${course.courseTitle} course image`}
              className="w-80 h-60 object-cover rounded-t-md"
            />
          </a>
        </div>
        <div className="ml-4 w-full flex">
          <div className="w-4/5 pr-10">
            <div>
              <a href={clientdomainpath + "/courses/" + course._id}>
                <h2 className="hrp-find-course-title">{course.courseTitle}</h2>
              </a>
            </div>
            <p
              className=""
              dangerouslySetInnerHTML={{
                __html: course.courseDescription,
              }}
            >
              {/* {course.courseDescription} */}
            </p>
            <p className="hrp-find-course-card-instructor mt-1">
              {course.instructorName}
            </p>

            <div className="flex flex-wrap	">
              {course.courseLevel != ""
                ? course.courseLevel.map((level, index) => (
                    <p
                      key={index}
                      className="border-t-2 rounded-full px-2 py-1 m-1"
                      style={{
                        color: "#003366",
                        border: "1px solid #003366",
                        fontSize: "13px",
                      }}
                    >
                      {level}
                    </p>
                  ))
                : ""}
            </div>
            <div className="mt-4 flex items-center">
              <span className="text-lg font-bold hrp-course-card-price">
                £{course.discountPrice.toFixed(2)}
              </span>
              <span className="ml-2 text-sm line-through hrp-course-card-originalPrice">
                £{course.price.toFixed(2)}
              </span>
            </div>
          </div>
          <div className="w-1/5 flex justify-end items-center">
            <div className="items-center mt-4 hrp-card-originalPrice">
              <a href={`/courses/${course._id}`}>
                <button className="hrp-find-course-btn px-10 py-3 rounded-full">
                  Quick view
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CourseList() {
    return (
      <div className="container mx-auto p-4">
        {searchjobdata.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImageSrc = (thumbnail) => {
    if (thumbnail && thumbnail.trim() !== "" && thumbnail !== "NA") {
      if (thumbnail.startsWith("http://") || thumbnail.startsWith("https://")) {
        return thumbnail;
      }
      return thumbnail.startsWith("/uploads/")
        ? `${domainpath}${thumbnail}`
        : `${domainpath}/uploads/${thumbnail}`;
    }
    return `${domainpath}/uploads/defaultblog.png`;
  };

  const getTextContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const truncatedText = (text) => text.split(" ").slice(0, 25).join(" ");
  const Courses = () => {
    return (
      <div className="lg:w-full md:w-11/12 w-3/4 m-auto md:mb-10 hrp-course-card-outer ">
        <Slider {...settings}>
          {data.map((course, index) => {
            const originalPrice = course.discountPrice
              ? course.price.toFixed(2)
              : null;
            const description = course.courseDescription || "";
            const isImageInDescription = description.includes("<img");
            const isHtmlContent = /<\/?[a-z][\s\S]*>/i.test(description);
            return (
              <div
                key={index}
                className="coursecard p-4 w-full h-full max-w-xs mx-auto my-4 border border-gray-300 rounded-md shadow-md"
                style={{ minHeight: "400px" }}
              >
                <a href={clientdomainpath + "/courses/" + course._id}>
                  <img
                    className="imgse renderCategoryEdit text uploadImage-logo"
                    src={
                      course.courseThumbnail &&
                      course.courseThumbnail.trim() !== "" &&
                      course.courseThumbnail !== "NA"
                        ? course.courseThumbnail.startsWith("http://") ||
                          course.courseThumbnail.startsWith("https://")
                          ? course.courseThumbnail
                          : course.courseThumbnail.startsWith("/uploads/")
                          ? `${domainpath}${course.courseThumbnail}`
                          : `${domainpath}/uploads/${course.courseThumbnail}`
                        : `${domainpath}/uploads/defaultblog.png`
                    }
                    alt="Course"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${domainpath}/uploads/defaultblog.png`;
                    }}
                  />
                </a>
                <div className="py-4">
                  <a href={"/courses/" + course._id}>
                    <h3 className="text-lg font-bold hrp-course-card-title">
                      {course.courseTitle}
                    </h3>
                  </a>
                  <p className="mt-1 hrp-find-cours-card-description">
                    {isImageInDescription ? (
                      "Description contains an image. Please check the course details for more information."
                    ) : isHtmlContent ? (
                      <>
                        {truncatedText(getTextContent(description))}
                        {getTextContent(description).split(" ").length > 25 &&
                          "..."}
                      </>
                    ) : (
                      <>
                        {truncatedText(description)}
                        {description.split(" ").length > 25 && "..."}
                      </>
                    )}
                  </p>
                  <p className="text-sm text-gray-600 hrp-course-card-instructor">
                    {course.instructorName}
                  </p>
                  <div className="mt-4 flex items-center">
                    <span className="text-lg font-bold text-green-600 hrp-course-card-price">
                      £{course.discountPrice.toFixed(2)}
                    </span>
                    {originalPrice && (
                      <span className="ml-2 text-sm line-through text-gray-500 hrp-course-card-originalPrice">
                        £{originalPrice}
                      </span>
                    )}
                  </div>
                  <div className="hrp-card-tittle mt-2 flex flex-wrap">
                    {course.courseLevel &&
                      course.courseLevel.map(
                        (level, index) =>
                          level && (
                            <p
                              key={index}
                              className="border rounded-full px-2 py-1 m-1 text-sm text-blue-800 border-blue-800"
                              style={{
                                color: "#003366",
                                border: "1px solid #003366",
                                fontSize: "13px",
                              }}
                            >
                              {level}
                            </p>
                          )
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  const handleFormData = (data) => {
    setsearchjobdata(data);
    //console.log("Form Data from Child:", data);
  };
  const handleClear = (e) => {
    e.preventDefault();
    setLoading(true);

    // Reset the form by its ID
    document.getElementById("coursesearchform").reset();
    window.location.href = "/courses";
    // No need to reassign 'industry' as a constant

    setLoading(false);
  };
  return (
    <div className="hrp-main-section course-page">
      <div className="hrp-container">
        <div className="hrp-sub-section hrp-background-color">
          <div className="hrp-sub-section-container">
            <SearchCourse onSubmit={handleFormData} query={location.search} />
          </div>
        </div>
        {searchjobdata?.length > 0 ? (
          <>
            <div className="hrp-sub-section">
              <div className="m-auto container hrp-sub-section-container">
                <div className="joblistheader justify-between w-full p5-10">
                  <div className="joblistheader-first">
                    <h1>{searchjobdata?.length} results</h1>
                  </div>
                  <div className="joblistheader-second">
                    <button
                      className="joblist-btnclear"
                      onClick={(e) => handleClear(e)}
                    >
                      Clear
                    </button>
                    <span>Sort</span>
                    <select>
                      <option value="latest">Latest</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="hrp-sub-section">
              <div className="m-auto container hrp-sub-section-container pb-20">
                <CourseList />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="hrp-sub-section">
          <div className="m-auto container hrp-sub-section-container ">
            <div>
              <div className="text-center hrp-broad-selection py-10 md:mt-0 mt-10">
                <h1>A broad selection of courses</h1>
                <p>
                  Choose from over 220,000 online video courses with new
                  additions published every month
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hrp-sub-section md:m-0 my-7">
          <div className="m-auto container hrp-sub-section-container flex">
            <Courses />
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <VisitorTracker />
    </div>
  );
};

export default CoursePage;
