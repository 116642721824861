import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select2 from "../Common/Select2Wrapper";
import "select2/dist/js/select2.min.js";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";

const Reportjob = () => {
  const { id } = useParams();
  const User = getUserData();
  const [ticket, setTicket] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessages, setShowMessages] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [data, setData] = useState(null);

  const fetchReportedJob = async () => {
    setLoading(true);
    try {
      const response = await api.getreportjobId(id);
      if (response.status === 200 && response.reportedJob) {
        setData(response.reportedJob);
      } else {
        console.error("Failed to fetch reported job", response);
      }
    } catch (error) {
      console.error("Error fetching reported job:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (newNote.trim()) {
      const noteData = {
        note: newNote, // The note content
        sender: User?._id, // The ID of the user sending the note
      };

      try {
        // Make the API request to post the note
        const response = await api.addNotesEnquiry(data.jobId, noteData);

        if (response && response.data) {
          // Update the local state with the newly added note
          const updatedNotes = [
            ...data.notes,
            { createdAt: new Date().toLocaleString(), note: newNote },
          ];
          setData((prevCourse) => ({
            ...prevCourse,
            notes: updatedNotes,
          }));
          setNewNote(""); // Clear the input field
        } else {
          // Handle errors here (e.g., show a notification to the user)
          console.error("Failed to add note", response);
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error adding note:", error);
      }
    }
  };

  const handleAddMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const messageData = {
        message: newMessage, // The message content
        sender: User?._id, // The ID of the user sending the message
      };

      try {
        // Make the API request to post the message
        const response = await api.addMessageJob(data.jobId, messageData);

        if (response && response.data) {
          // Update the local state with the newly added message
          const updatedMessages = [
            ...data.messages,
            {
              createdAt: new Date(), // Properly set the date
              message: newMessage,
              sender: User?.name, // Include the sender's name
            },
          ];
          setData((prevCourse) => ({
            ...prevCourse,
            messages: updatedMessages,
          }));
          setNewMessage(""); // Clear the input field
        } else {
          // Handle errors here (e.g., show a notification to the user)
          console.error("Failed to add message", response);
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error adding message:", error);
      }
    }
  };

  useEffect(() => {
    fetchReportedJob();
  }, [id]);

  return (
    <div className="single-ticket-page flex flex-col lg:flex-row">
      <div className="w-full p-4">
        <h2 className="text-xl font-semibold mb-4" style={{ color: "#003366" }}>
          Report Job
        </h2>
        <div className="bg-white rounded-lg p-6 mb-4 hrp-ticket-details">
          <div>
            {/* Ticket Information */}
            <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center gap-4">
              <div className="flex items-start gap-2">
                <h1
                  className="text-lg font-medium"
                  style={{ color: "#343434" }}
                >
                  Job Title:
                </h1>
                <h3 className="text-sm" style={{ color: "#3e3e3e" }}>
                  {data?.jobTitle || "N/A"}
                </h3>
              </div>

              <div className="flex items-start gap-2 mt-2 sm:mt-0"></div>
            </div>
            {/* Reported By, Date, Reason */}
            <div className="flex flex-col sm:flex-row gap-2 mt-2">
              <h1 className="text-lg font-medium" style={{ color: "#343434" }}>
                Reported by:
              </h1>
              <h3 className="text-sm" style={{ color: "#3e3e3e" }}>
                {data?.reportedBy?.name || "Unknown"}
              </h3>
            </div>
            <div className="flex flex-col sm:flex-row gap-2 mt-2">
              <h1 className="text-lg font-medium" style={{ color: "#343434" }}>
                Reported Date:
              </h1>
              <h3 className="text-sm" style={{ color: "#3e3e3e" }}>
                {new Date(data?.reportedDate).toLocaleDateString() || "N/A"}
              </h3>
            </div>
            <div className="flex flex-col sm:flex-row gap-2 mt-2">
              <h1 className="text-lg font-medium" style={{ color: "#343434" }}>
                Report Reason:
              </h1>
              <h3 className="text-sm" style={{ color: "#3e3e3e" }}>
                {data?.reasons?.join(", ") || "N/A"}
              </h3>
            </div>
          </div>
        </div>
        <div>
          <div className="flex   gap-2 mb-4">
            <button
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                showMessages ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => {
                setShowMessages(true);
                setShowNotes(false);
              }}
            >
              Message
            </button>
            <button
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                showNotes ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => {
                setShowMessages(false);
                setShowNotes(true);
              }}
            >
              Notes
            </button>
          </div>

          {showMessages && (
            <div className="mb-6 mt-5">
              <h3
                className="text-lg font-semibold mb-4"
                style={{ color: "#003366" }}
              >
                Messages
              </h3>
              <div className="mt-4">
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <textarea
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder=""
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="newMessage"
                      rows={7}
                    ></textarea>
                    <label
                      htmlFor="newMessage"
                      className="profileheadline description-label"
                    >
                      Add a message...
                    </label>
                  </div>
                </div>
                <button
                  onClick={handleAddMessage}
                  className="mt-2 text-white px-4 py-2 rounded-full"
                  style={{ backgroundColor: "#003366" }}
                >
                  Add Message
                </button>
              </div>
              
              <div className="space-y-4 mt-5 h-96 overflow-auto p-8 pl-0">
                {data?.messages.map((message, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 p-3 rounded-md shadow"
                  >
                    <div className="flex justify-between items-center">
                      <p className="text-sm " style={{ color: "#003366" }}>
                        {message?.sender}
                      </p>
                      <p className="text-sm text-gray-600">
                        {new Date(message.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <p className="text-gray-800">{message.message}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {showNotes && (
            <div className="mb-6 mt-5">
              <h3
                className="text-lg font-semibold mb-4"
                style={{ color: "#003366" }}
              >
                Notes
              </h3>
              <div className="mt-4">
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <textarea
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                      placeholder=""
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="newNote"
                      rows={7}
                    ></textarea>
                    <label
                      htmlFor="newNote"
                      className="profileheadline description-label"
                    >
                      Add a note...
                    </label>
                  </div>
                </div>
                <button
                  onClick={handleAddNote}
                  className="mt-2 text-white px-4 py-2 rounded-full"
                  style={{ backgroundColor: "#003366" }}
                >
                  Add Notes
                </button>
              </div>
              <div className="space-y-4 mt-5 h-96 overflow-auto p-8  pl-0">
                {data?.notes.map((note, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 p-3 rounded-md shadow"
                  >
                    <div className="flex justify-between items-center">
                      <p className="text-sm" style={{ color: "#003366" }}>
                        {note?.sender}
                      </p>
                      <p className="text-sm text-gray-600">
                        {new Date(note.createdAt).toLocaleDateString()}
                      </p>
                    </div>

                    <p className="text-gray-800">{note.note}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
    </div>
  );
};

export default Reportjob;
