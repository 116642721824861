import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CustomToastContainer from "../Common/CustomToastContainer";
import api from "../../Api";
import Select2 from "../Common/Select2Wrapper";
import { getUserData } from "../../helpers/utils";
import { useHistory } from "react-router-dom";
const domainpath = process.env.REACT_APP_DOMAIN;
const domainPath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const ApplicationList = () => {
  const User = getUserData();
  const history = useHistory();
  const toastRef = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Store filtered data
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [statusCounts, setStatusCounts] = useState({}); // Store status counts

  const status = [
    { id: "Reviewed", text: "Reviewed" },
    { id: "Applying", text: "Applying" },
    { id: "In Review", text: "In Review" },
    { id: "Rejected", text: "Reject" },
    { id: "Shortlisted", text: "Shortlisted" },
    { id: "Applied", text: "Awaiting Review" }, // Applied maps to Awaiting Review
    { id: "Interviewed", text: "Interviewed" },
    { id: "Interview Scheduled", text: "Interview Scheduled" },
    { id: "Offered", text: "Offered" },
    { id: "Hired", text: "Hired" },
  ];

  const statuses = [
    "All",
    "Awaiting Review", // "Awaiting Review" includes "Applied"
    "Reviewed",
    "Shortlisted",
    "Interview Scheduled",
    "Interviewed",
    "In Review",
    "Offered",
    "Hired",
    "Rejected",
  ];

  useEffect(() => {
    async function fetchData() {
      const userid = User._id;
      setLoading(true);
      try {
        const response = await api.getAllApplicationByEmp(userid);
        if (response.status !== 500) {
          // Filter out items with jobId as "N/A" or empty
          const validData = response.filter(
            (item) => item.jobId && item.jobId !== "" && item.jobId !== "N/A"
          );
          setData(validData);
          setFilteredData(validData);
          calculateStatusCounts(validData); // Calculate counts initially
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // UseEffect to filter data when selectedStatus changes
  useEffect(() => {
    filterDataByStatus(selectedStatus, data); // Filter data by the selected status
  }, [selectedStatus, data]);

  // Function to filter data by status
  const filterDataByStatus = (selectedStatus, data) => {
    if (selectedStatus === "All") {
      setFilteredData(data); // If 'All', show all data
    } else if (selectedStatus === "Awaiting Review") {
      // "Awaiting Review" includes "Applied"
      const filtered = data.filter(
        (application) =>
          application.status === "Awaiting Review" ||
          application.status === "Applied"
      );
      setFilteredData(filtered);
    } else {
      const filtered = data.filter(
        (application) => application.status === selectedStatus
      );
      setFilteredData(filtered); // Set filtered data based on status
    }
  };

  // Function to calculate status counts
  const calculateStatusCounts = (applications) => {
    const counts = {
      All: applications.length,
    };
    statuses.slice(1).forEach((status) => {
      if (status === "Awaiting Review") {
        // "Awaiting Review" includes "Applied"
        counts[status] = applications.filter(
          (application) =>
            application.status === "Awaiting Review" ||
            application.status === "Applied"
        ).length;
      } else {
        counts[status] = applications.filter(
          (application) => application.status === status
        ).length;
      }
    });
    setStatusCounts(counts);
  };

  const handleUpdateApplicationStatus = async (e, id) => {
    e.preventDefault();
    var formdata = {
      status: e.target.value,
      userId: User?._id,
    };
    try {
      const res = await api.PostApplicationStatus(id, formdata);
      if (res.status === 200) {
        toastRef.current.addToast(res.message, "success");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (err) {
      toastRef.current.addToast(err, "error");
    }
  };

  const columns = [
    {
      name: "Applicant",
      selector: (row) => (
        <div className="flex gap-2 items-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row?.profileId?.jobProfileImage &&
            row?.profileId?.jobProfileImage !== "null" ? (
              row?.profileId?.jobProfileImage.startsWith("https://") ? (
                <img
                  className="image"
                  width={60}
                  height={60}
                  style={{ borderRadius: "40px" }}
                  src={row?.profileId?.jobProfileImage}
                  alt="Applicant"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop in case default image fails
                    e.target.src = `${domainPath}/uploads/deafultman.png`;
                  }}
                />
              ) : (
                <img
                  className="image"
                  width={60}
                  height={60}
                  style={{ borderRadius: "40px" }}
                  src={`${domainPath}/uploads/${row?.profileId?.jobProfileImage
                    .replace("/uploads/uploads/", "/uploads/")
                    .replace(/^\/uploads\//, "")}`}
                  alt="Applicant"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop in case default image fails
                    e.target.src = `${domainPath}/uploads/deafultman.png`;
                  }}
                />
              )
            ) : (
              <img
                className="image"
                width={60}
                height={60}
                style={{ borderRadius: "40px" }}
                src={`${domainPath}/uploads/deafultman.png`}
                alt="Default"
              />
            )}
          </div>
          <div>
            <a
              href={`/dashboard/application/${row?.applicationId}`}
              className=" hover:underline"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row?.applicant?.name}
            </a>
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Job Title",
      selector: (row) => (
        <a
          href={`${domainpath}/jobs/${row?.jobId}`}
          className=" hover:underline"
          style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
        >
          {row?.jobTitle}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Score",
      selector: (row) => {
        let color;
        if (row?.score < 60) {
          color = "font-semibold text-lg text-red-500";
        } else if (row?.score < 80) {
          color = "font-semibold text-lg text-yellow-500";
        } else {
          color = "font-semibold text-lg text-green-500";
        }
        return <span className={color}>{row?.score}%</span>;
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className="relative job-search-label-section singleselect2 statusenroll"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Select2
            data={status}
            className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
            defaultValue={row.status}
            onSelect={(e) =>
              handleUpdateApplicationStatus(e, row.applicationId)
            }
            options={{
              placeholder: "Select Status",
              width: "100%",
              theme: "classic",
            }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 w-full item-center justify-center">
          <button
            className=" ManageApplication-View lg:px-4 md:px-6 py-2 text-white "
            onClick={() =>
              history.push(
                `/dashboard/application/${row?.applicationId}`,
                row?.profileId
              )
            }
          >
            View Application
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: filteredData, // Use filtered data for the table
  };

  return (
    <div className="p-8">
      <h2 className="text-xl font-bold mb-4" style={{ color: "#003366" }}>
        Manage Applications
      </h2>
      <div className="bg-white p-4 rounded-lg shadow">
        {/* Scrollable container for statuses */}
        <div
          className="flex gap-2 mb-4 overflow-x-auto custom-scrollbar"
          style={{ whiteSpace: "nowrap", paddingBottom: "10px" }}
        >
          {statuses.map((status) => (
            <button
              key={status}
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                selectedStatus === status ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => setSelectedStatus(status)}
            >
              {status} ({statusCounts[status] || 0})
            </button>
          ))}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={filteredData} // Use filtered data here
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ApplicationList;
