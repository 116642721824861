import React, { useState, useRef } from "react";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CustomToastContainer from "../Common/CustomToastContainer";
import sellersignupimg from "../../image/sellersignupimg.png";
import VisitorTracker from "../Common/VisitorTracker.js";

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const history = useHistory();
  const toastRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "").slice(0, 10); // Only allow numbers
      setFormData((prevData) => ({ ...prevData, phone: numericValue }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const validateForm = () => {
    const { firstName, lastName, email, phone, password, confirmPassword } =
      formData;
    if (!firstName) return "First Name is required.";
    if (!lastName) return "Last Name is required.";
    if (!email) return "Email is required.";
    if (!/\S+@\S+\.\S+/.test(email)) return "Email is invalid.";
    // if (!phone) return "Phone number is required.";
    if (!password) return "Password is required.";
    if (password !== confirmPassword) return "Passwords do not match.";
    return "";
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setValidationMessage(validationError);
      return;
    }

    try {
      setLoading(true);
      const { firstName, lastName, email, password, phone } = formData;
      const userData = {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        email,
        password,
        phone,
      };

      const response = await api.sellerSignup(userData);
      if (response.status !== 400) {
        toastRef.current.addToast("User Created Successfully", "success");
        setTimeout(() => {
          history.push(`./login`);
        }, 1000);
      } else if (response.status === 400) {
        toastRef.current.addToast("User Already Exist", "error");
      }
    } catch (error) {
      toastRef.current.addToast(
        error.response.data.msg || "Registration failed",
        "error"
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const socialLogin = async (e) => {
    e.preventDefault();
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const googleCallbackUrl = process.env.REACT_APP_GOOGLE_CALLBACK_URL;
    const scope = "profile email";
    const redirectUri = encodeURIComponent(googleCallbackUrl);
    const state = JSON.stringify({ role: "seller" });
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodeURIComponent(
      state
    )}&access_type=offline&prompt=consent`;
    window.location.href = googleAuthUrl;
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, confirmPassword: value }));
    setError(value !== formData.password ? "Passwords do not match" : "");
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-50 ">
      <div className="container m-auto md:flex mt-20">
        <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
          <img src={sellersignupimg} alt="Signup" />
        </div>
        <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
          <div className="flex justify-center hrp-register-section items-center login_inform-inner">
            <div className="w-full">
              <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
                <h2
                  className="text-center py-5 font-semibold"
                  style={{ color: "#003366" }}
                >
                  Create An Account
                </h2>
                <form
                  onSubmit={handleRegisterSubmit}
                  className="register-form-section animated-form"
                >
                  <div className="mb-2 md:grid md:grid-cols-2 md:gap-x-5">
                    <div className="form-group">
                      <input
                        type="text"
                        name="firstName"
                        required
                        placeholder=""
                        onChange={handleChange}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="firstName" className="form-input-label">
                        First Name
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="lastName"
                        required
                        placeholder=""
                        onChange={handleChange}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="lastName" className="form-input-label">
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 md:grid md:grid-cols-2 md:gap-x-5">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        required
                        onChange={handleChange}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="email" className="form-input-label">
                        Email
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="phone" className="form-input-label">
                        Phone
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        required
                        onChange={handleChange}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="password" className="form-input-label">
                        Password
                      </label>
                      <span
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                      >
                        {showPassword ? (
                          <FeatherIcon
                            icon="eye-off"
                            style={{ width: "20px", height: "20px" }}
                          />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-500"
                            viewBox="0 0 576 512"
                          >
                            <path
                              fill="currentColor"
                              d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        id="cpassword"
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="cpassword" className="form-input-label">
                        Confirm Password
                      </label>
                    </div>
                  </div>
                  {validationMessage && (
                    <div className="mb-4 text-red-500">{validationMessage}</div>
                  )}
                  {error && (
                    <p className="text-red-500 text-xs italic">{error}</p>
                  )}
                  <div className="register-promotional-emails mb-6">
                    <input type="checkbox" name="terms" required />
                    <div className="terms-policy">
                      I agree <a href="">Terms & Conditions</a> &{" "}
                      <a href="">Privacy Policy</a>
                    </div>
                  </div>
                  <div className="Forgot_00">
                    <button
                      className="text-white py-2 px-4 rounded w-full register-form-btn"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Register"}
                    </button>
                    <div className="line-after-login my-2">
                      <div className="line-after-login-inner">
                        <div className="line-after-login-first"></div>
                        <div className="line-after-login-or">or</div>
                        <div className="line-after-login-second"></div>
                      </div>
                    </div>
                    <button
                      className="text-white login-google-btn py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={socialLogin}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Sign Up with Google"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisitorTracker />
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default SignUp;
