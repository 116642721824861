import React, { useState, useRef } from "react";
import { submitform } from "../../helpers/Formhelper";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
import FeatherIcon from "feather-icons-react";
import CustomToastContainer from "../Common/CustomToastContainer";

const RegisterForm = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const User = getUserData();
  const toastRef = useRef();
  const [positions] = useState([
    "Hiring Manager",
    "Recruiter",
    "HR Manager/Coordinator",
    "Talent Acquisition Specialist",
    "HR Business Partner",
    "Employee Relations Specialist",
    "Compensation and Benefits Manager",
    "Training and Development Coordinator",
    "Onboarding Specialist",
    "Payroll Specialist",
  ]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = submitform("staffcreate");
    formdata.employerid = User?._id;
    //console.log(formdata);

    try {
      const response = await api.addStaff(formdata);
      if (response.status !== 500) {
        toastRef.current.addToast("Staff Created Successffully", "success");
        onClose("");
        window.location.reload();
        setLoading(false);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);

        toastRef.current.addToast(response.msg, "error");
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      toastRef.current.addToast(error, "error");
    } finally {
      setLoading(false);
    }
  };
  const [password, setPassword] = useState("");
  const [phone, setphone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  function formatPhoneNumber(value) {
    const cleaned = ("" + value).replace(/\D/g, "");

    const limited = cleaned.substring(0, 11);

    if (limited.length >= 10) {
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6,
        10
      )}`;
    } else if (limited.length > 6) {
      return `${limited.slice(0, 3)}-${limited.slice(3, 6)}-${limited.slice(
        6
      )}`;
    } else if (limited.length > 3) {
      return `${limited.slice(0, 3)}-${limited.slice(3)}`;
    } else {
      return limited;
    }
  }

  const handleChange = (e) => {
    const phonevalue = e.target.value;
    const formattedNumber = formatPhoneNumber(phonevalue);
    setphone(formattedNumber);
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div
          className="form_upto hrp-employer-add-user m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded p-8 mb-4">
            <div className="text-center mb-4">
              <h1 className="hrp-heading-size text-center hrp-About">
                Create Staff Account
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              id="staffcreate"
              className="register-form-section animated-form"
            >
              <div className="mb-2 grid grid-cols-2 gap-x-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="firstName" className="form-input-label">
                    First Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="lastName" className="form-input-label">
                    Last Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="email" className="form-input-label">
                    Email
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    onChange={(e) => handleChange(e)}
                    value={phone}
                    inputMode="tel"
                    pattern="\d{3}-\d{3}-\d{4}"
                    title="Phone number should be in the format: 123-456-7890"
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="phone" className="form-input-label">
                    Phone
                  </label>
                </div>
                <div className=" form-group">
                  <input
                    id="password"
                    name="password"
                    className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password" className="password-label">
                    Password
                  </label>

                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? (
                      <FeatherIcon
                        icon="eye-off"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label
                    htmlFor="confirm-password"
                    className="form-input-label"
                  >
                    Confirm Password
                  </label>
                </div>
              </div>
              {error && <p className="text-red-500 text-xs italic">{error}</p>}
              <div className="form-group">
                <select
                  id="role"
                  name="position"
                  className="shadow w-full border rounded py-2 px-3"
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {positions.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
                <label htmlFor="role" className="form-input-label">
                  Role
                </label>
              </div>

              <div className="flex ">
                <button
                  type="submit"
                  className="hrp-find-course-btn py-2 px-10 rounded-full w-full"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default RegisterForm;
