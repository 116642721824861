import React, { useEffect, useState, useRef } from "react";
import { submitform } from "../../helpers/Formhelper";
import api from "../../Api";
import { encryptData, decryptData } from "../../helpers/encryption";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";
import FeatherIcon from "feather-icons-react";
const Inviteprofile = ({ onClose, data }) => {
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const User = getUserData();
  const [selectedJobs, setSelectedJobs] = useState([]);
  const toastRef = useRef();
  const [validationMessage, setValidationMessage] = useState("");
  useEffect(() => {
    async function getProfileid() {
      try {
        const response = await api.getProfileId(data);
        //console.log(response);
        if (response.status !== 500) {
          setFirstName(response.name);
          setLastName(response.name);
          setPhone(response.phone);
          setEmail(response.email);
        } else if (response.status == 500) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getProfileid();
  }, []);
  const handleConfirmPasswordChange = async (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const profiledata = { email, password, name: firstName, phone };
      //console.log(profiledata);
      const response = await api.updateProfileByEmail(data, profiledata);
      //console.log(response);
      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        const { accessToken, refreshToken, _id, role } = response;
        const encryptedUserData = encryptData(response.user);
        localStorage.setItem("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        setTimeout(() => {
          setLoading(false);
          window.location.href = `/dashboard/profile/${data}`;
        }, 1000);
      } else if (response.status == 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(error);

      toastRef.current.addToast(error.response.data.msg, "error");
      setLoading(false);
    }
    // Optionally, clear the form or redirect the user
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="flex">
        <div>
          <div className="form_upto  m-3 loginDiv loginDiv-width " id="step_1">
            {" "}
            <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
              <div className="hrp-heading-job ">
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#003366",
                  }}
                >
                  Create Password
                </h1>
              </div>
              <form
                className="register-form-section animated-form"
                onSubmit={handleSubmit}
              >
                <div className="mb-2 grid xl:grid-cols-2 grid-cols-1 gap-x-5">
                  <div className="form-group">
                    <input
                      type="text"
                      id="first-name"
                      defaultValue={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="first-name" className="form-input-label">
                      First Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      defaultValue={lastName}
                      id="last-name"
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="last-name" className="form-input-label">
                      Last Name
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder=""
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="email" className="form-input-label">
                    Email
                  </label>
                </div>
                <div className="xl:mb-2 mb-0 grid xl:grid-cols-2 grid-cols-1 gap-x-5">
                  <div className="form-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="password" className="form-input-label">
                      Password
                    </label>
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                    >
                      {showPassword ? (
                        // Eye Slash Icon
                        <FeatherIcon
                          icon="eye-off"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        // Eye Icon
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-gray-500"
                          viewBox="0 0 576 512"
                        >
                          <path
                            fill="currentColor"
                            d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                          />
                        </svg>
                      )}
                    </span>
                  </div>{" "}
                  {validationMessage && (
                    <div className="mb-4 text-red-500">{validationMessage}</div>
                  )}
                  <div className="form-group">
                    <input
                      type="password"
                      id="cpassword"
                      placeholder=""
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="cpassword" className="form-input-label">
                      Confirm Password
                    </label>
                  </div>
                </div>{" "}
                {error && (
                  <p className="text-red-500 text-xs italic">{error}</p>
                )}
                <div className="terms-policy my-5">
                  <input className="mr-2" type="checkbox" />I agree
                  <a href="/terms-conditions"> Terms condation </a> &
                  <a href="/privacy-policy"> Privacy Policy</a>
                </div>
                <div>
                  <div className="flex justify-between gap-x-5">
                    <div className="w-full">
                      <button className="hrp-btn w-full flex items-center">
                        Confirm &nbsp;
                        <svg
                          width="35"
                          height="16"
                          viewBox="0 0 35 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M34.7071 8.70711C35.0976 8.31658 35.0976 7.68342 34.7071 7.29289L28.3431 0.928932C27.9526 0.538408 27.3195 0.538408 26.9289 0.928932C26.5384 1.31946 26.5384 1.95262 26.9289 2.34315L32.5858 8L26.9289 13.6569C26.5384 14.0474 26.5384 14.6805 26.9289 15.0711C27.3195 15.4616 27.9526 15.4616 28.3431 15.0711L34.7071 8.70711ZM0 9H34V7H0V9Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>{" "}
        </div>{" "}
        <button
          className=" relative text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-7	 h-7 -top-6  text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Inviteprofile;
