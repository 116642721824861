import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Adduser from "../popup/AddUserSuperAdmin";
import EditUser from "../popup/EditUserSuperAdmin";
import { usePopup } from "../Common/PopupContext";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import FeatherIcon from "feather-icons-react";
import Cookies from "js-cookie";
import { encryptData } from "../../helpers/encryption";
import api from "../../Api";
const domainPath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const ManageUser = () => {
  const { openPopup } = usePopup();
  const toastRef = useRef();
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(0);
  const [data, setdata] = useState([]);
  // const [popupComponent, setPopupComponent] = useState(null);
  const handleLoginClick = () => {
    const popup = <Adduser onClose={() => handleClosePopup("Popup closed")} />;
    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleDelete = async (jobId) => {
    const response = await api.deleteStaff(`${jobId}`);
    //console.log(response);

    if (response.status != 500) {
      toastRef.current.addToast(response.message, "sucess");
      setRender(render + 1);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the User!",
        "error"
      );
    }
  };
  const handleClosePopup = (message) => {
    //console.log(message);
    //setPopupComponent(null);
    openPopup();
  };
  const handleEditUser = (id) => {
    const popup = (
      <EditUser id={id} onClose={() => handleClosePopup("Popup closed")} />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleSecertLogin = async (id) => {
    setLoading(true);
    try {
      const userData = {
        userId: id,
      };
      const response = await api.SeceretLogin(userData);
      //console.log(response);

      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        // Destructure tokens and user details from API response
        const { accessToken, refreshToken } = response?.user;
        const encryptedUserData = encryptData(response.user);

        // Get the existing tokens and user data from localStorage and cookies
        const existingAccessToken =
          localStorage.getItem("accessToken") || Cookies.get("accessToken");
        const existingRefreshToken =
          localStorage.getItem("refreshToken") || Cookies.get("refreshToken");
        const existingUser =
          localStorage.getItem("user") || Cookies.get("user");

        // Rename and store existing values as admin keys in localStorage and cookies
        if (existingAccessToken) {
          localStorage.setItem("adminaccessToken", existingAccessToken);
          Cookies.set("adminaccessToken", existingAccessToken);
        }
        if (existingRefreshToken) {
          localStorage.setItem("adminrefreshToken", existingRefreshToken);
          Cookies.set("adminrefreshToken", existingRefreshToken);
        }
        if (existingUser) {
          localStorage.setItem("adminuser", existingUser);
          Cookies.set("adminuser", existingUser);
        }

        // Log for debugging (optional)
        // console.log("Existing tokens and user renamed as admin keys", {
        //   adminAccessToken: localStorage.getItem("adminaccessToken"),
        //   adminRefreshToken: localStorage.getItem("adminrefreshToken"),
        //   adminUser: localStorage.getItem("adminuser"),
        // });

        // Set new tokens and user data from API response in localStorage and cookies
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        Cookies.set("user", encryptedUserData);

        // If keepMeLoggedIn is true, persist in localStorage; otherwise, use sessionStorage

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);

        // Redirect to the appropriate URL
        setTimeout(() => {
          setLoading(false);
          window.location.href = "/dashboard";
        }, 2000);
      } else if (response.status === 400) {
        // Handle login failure
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );

        toastRef.current.addToast(
          response.data.msg || "An error occurred",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors
      console.error(error);

      toastRef.current.addToast(
        error.response?.data?.msg || "An error occurred",
        "error"
      );
      setLoading(false);
    }
  };
  const status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "InActive" },
    { value: "block", label: "Block" },
  ];
  const handleUpdateUserStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    var formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.UpdateUserStatus(id, formdata);
      if (res.status === 200) {
        toastRef.current.addToast(res.message, "success");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <>
          {" "}
          <div
            className="flex items-center hover:underline"
            style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
          >
            <span
              // style={{ marginLeft: "10px" }}
              className="cursor-pointer"
              onClick={(e) => handleSecertLogin(row?._id)}
            >
              {row?.name}
            </span>
          </div>
          <span>{row?.email}</span>
          <br></br>
          <span>{row?.phone}</span>
        </>
      ),
      sortable: true,
      width: "35%",
    },

    {
      name: "Role",
      selector: (row) => row?.role,
      sortable: true,
      width: "15%",
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className="relative job-search-label-section singleselect2 statusenroll"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Select2
            data={status.map((type) => ({
              id: type.value,
              text: type.label,
            }))}
            className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
            value={row.status}
            onSelect={(e) => handleUpdateUserStatus(e, row._id)}
            options={{
              placeholder: "Select Status",
              width: "100%",
              theme: "classic",
            }}
          />
        </div>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 w-full item-center justify-center">
          <button
            className="manage-edit-btn text-white px-4 py-2"
            onClick={(e) => handleEditUser(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-edit-2 "
            >
              <g>
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </g>
            </svg>
          </button>
          <span
            className="manage-delete-btn text-white px-3 py-2 "
            onClick={(e) => handleDelete(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-trash-2 "
              color="red"
            >
              <g>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </g>
            </svg>
          </span>
          <button
            className="manage-secrate-btn text-white px-3 py-2 "
            onClick={(e) => handleSecertLogin(row._id)}
          >
            <FeatherIcon
              icon="eye"
              onClick={(e) => handleSecertLogin(row?._id)}
              className="cursor-pointer"
            />
          </button>
        </div>
      ),
      width: "30%",
    },
  ];

  useEffect(() => {
    async function getusers() {
      try {
        const response = await api.getUsers();
        //console.log(response);
        if (response.status !== 400) {
          setdata(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getusers();
  }, [render]);

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between ">
          <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
            Manage User
          </h2>
          <button
            className="manage-add-btn text-white px-3 py-1 Job-Post-btn"
            onClick={handleLoginClick}
          >
            Add User
          </button>
        </div>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageUser;
