import React from "react";
const BASE_DOMAIN_URL = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const Message = ({ user, text, timestamp, isCurrentUser }) => {
  return (
    <div
      className={`flex items-start mb-4 ${
        isCurrentUser ? "justify-end" : "justify-start"
      }`}
    >
      {!isCurrentUser && (
        <div className="w-10 h-10 mr-2">
          <img
            className="profileimgheader"
            src={BASE_DOMAIN_URL + "/uploads/deafultman.png"}
            alt="profileimg"
          />
        </div>
      )}
      <div
        className={`chat-box text-black p-2 rounded-lg max-w-xs ${
          isCurrentUser ? "bg-blue-500 text-black" : "bg-gray-300 text-black"
        }`}
        style={
          isCurrentUser
            ? {
                borderBottom: "2px solid #003464",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                borderRadius: "20px 0px 20px 20px",
                marginTop: "20px",
                minWidth: " 100px",
              }
            : {
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                borderRadius: "0 20px 20px 20px",
                marginTop: "20px",
                minWidth: " 100px",
              }
        }
      >
        <p>{text}</p>
        <p className="text-xs text-right">{timestamp}</p>
      </div>
      {isCurrentUser && (
        <div className="w-10 h-10 ml-2">
          <img
            className="profileimgheader"
            src={BASE_DOMAIN_URL + "/uploads/deafultman.png"}
            alt="profileimg"
          />
        </div>
      )}
    </div>
  );
};

export default Message;
