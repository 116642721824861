import { useEffect, useState } from "react";
import api from "../../Api"; // Adjust the path based on your API setup

const VisitorTracker = () => {
  const [clicks, setClicks] = useState(0);
  const [location, setLocation] = useState({ lat: null, long: null });
  const [hasFetchedLocation, setHasFetchedLocation] = useState(false);
  const [dataSent, setDataSent] = useState(false);
  const startTime = Date.now();

  useEffect(() => {
    // Track clicks on the page
    const handleDocumentClick = () => {
      setClicks((prevClicks) => prevClicks + 1);
    };
    document.addEventListener("click", handleDocumentClick);

    // Check for geolocation permission only once when the component mounts
    const checkGeolocationPermission = async () => {
      if (navigator.permissions) {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        if (permissionStatus.state === "granted" && !hasFetchedLocation) {
          getGeolocation();
        }
      }
    };

    // Function to request geolocation
    const getGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            //console.log("Geolocation fetched successfully.");
            setLocation({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
            setHasFetchedLocation(true);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    // Log visitor data
    const logVisitorData = async () => {
      if (dataSent) return; // Don't log if data has already been sent

      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // time in seconds

      if (location.lat !== null && location.long !== null) {
        const visitorData = {
          referer: document.referrer,
          date: new Date(startTime).toISOString(),
          timeSpent,
          url: window.location.href,
          site: window.location.host,
          pageUrl: window.location.pathname,
          clicks,
          lat: location.lat,
          long: location.long,
        };

        try {
          const res = await api.PostVisitor(visitorData);
          if (res.status === 201) {
            //console.log("Visitor data sent successfully.");
            setDataSent(true); // Mark data as sent
          }
        } catch (error) {
          console.error("Error sending visitor data:", error);
        }
      } else {
        console.warn("Location data not available yet.");
      }
    };

    // Check geolocation permission
    checkGeolocationPermission();

    // Log visitor data once when the component mounts
    logVisitorData();

    // Cleanup
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [clicks, location, hasFetchedLocation, dataSent]);

  return null; // This component doesn't need to render anything
};

export default VisitorTracker;
