import React, {
  useState,
  useImperativeHandle,
  useEffect,
  forwardRef,
} from "react";

const getIcon = (type) => {
  switch (type) {
    case "success":
      return (
        <span className="text-white text-lg" role="img" aria-label="Success">
          ✔️
        </span>
      );
    case "error":
      return (
        <span className="text-white text-lg" role="img" aria-label="Error">
          ⚠️
        </span>
      );
    default:
      return null;
  }
};

const Toast = ({ message, type = "success", onClose }) => {
  const [progress, setProgress] = useState(0); // Start progress at 0

  useEffect(() => {
    const duration = 3000;
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 1 : 100
      ); // Increase progress
    }, duration / 100);

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [onClose]);

  return (
    <div
      className={`fixed top-5 right-5 max-w-xs p-4 rounded-full shadow-lg 
        transition-opacity duration-300 ease-in-out`}
      style={{
        backgroundColor: type === "error" ? "#f1f4f7" : "#f1f4f7",
        width: "fit-content",
        color: type === "error" ? "#ff0000" : "#003366",
        boxShadow: "-1px 1px 7px rgb(0 0 0 / 66%)",
      }}
    >
      <div className="flex items-center">
        <div className="w-6 h-6 mr-2 flex items-center justify-center">
          {getIcon(type)}
        </div>
        <span>{message}</span>
        <button
          onClick={onClose}
          className="close-btn-style  relative text-xl flex mx-auto justify-center items-center bg-white rounded-2xl w-7	 h-7 -top-6 -right-4 text-gray-600 hover:text-gray-900"
          style={{ color: "#003366" }}
        >
          &#10005;
        </button>
      </div>
      <div
        className="h-1 mt-2 rounded"
        style={{
          width: `${progress}%`,
          background: "linear-gradient(90deg, #003366 0%, #ffa500 100%)", // Correct background gradient
          transition: "width 0.3s ease",
        }}
      ></div>
    </div>
  );
};

const CustomToastContainer = forwardRef((props, ref) => {
  const [toasts, setToasts] = useState([]);

  useImperativeHandle(ref, () => ({
    addToast(message, type = "success") {
      const id = Math.random().toString(36).substr(2, 9);
      setToasts((prevToasts) => [...prevToasts, { id, message, type }]);
      setTimeout(() => {
        removeToast(id);
      }, 3000);
    },
  }));

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </>
  );
});

export default CustomToastContainer;
