import React, { createContext, useContext, useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// Create the context
const CVDownloadContext = createContext();

// Context provider
export const CVDownloadProvider = ({ children }) => {
  const profileRef = useRef(null); // This ref will be attached to the component containing the CV details

  // Function to download the CV
  const downloadCV = async () => {
    if (!profileRef.current) {
      return;
    }

    const canvas = await html2canvas(profileRef.current);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });
    const imgWidth = 210; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save("profile.pdf");
  };

  return (
    <CVDownloadContext.Provider value={{ profileRef, downloadCV }}>
      {children}
    </CVDownloadContext.Provider>
  );
};

// Hook to use the context
export const useCVDownload = () => useContext(CVDownloadContext);
