import React, { useEffect, useState, useRef } from "react";
import Select2 from "../Common/Select2Wrapper";
import api from "../../Api";
import { useParams, useHistory } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";
import debounce from "lodash.debounce";
const TicketForm = () => {
  const User = getUserData();
  const [data, setdata] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toastRef = useRef();
  const { id } = useParams();
  const dropdownRef = useRef();
  const dropDownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [userKeyword, setUserKeyword] = useState("");
  // Controls dropdown visibility
  const [searchKeyword, setSearchKeyword] = useState("");
  const [formData, setFormData] = useState({
    assignedTo: "",
    jobId: "",
    subject: "",
    description: "",
    status: "",
    userId: User?._id,
    currentJobStatus: "", // New field for job status
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    // If the textarea id is "Query", map it to the "description" field in formData
    if (id === "description") {
      setFormData({ ...formData, description: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSelectChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };
  useEffect(() => {
    async function fetTicket() {
      setLoading(true);
      try {
        const res1 = await api.getTicketbyId(id); // Assuming getTicketbyId is the correct API method
        if (res1.status === 200) {
          const respdata = res1?.ticket;
          // Adjust this based on how your response is structured
          setFormData({
            jobId: respdata.jobId._id, // Setting jobId from the nested jobId object
            assignedTo: respdata?.assignedTo, // Setting jobId from the nested jobId object
            subject: respdata.subject, // Setting subject from the response
            description: respdata.description, // Setting description from the response
            status: respdata.status || "", // Default to "Open" if status is missing
          }); // If you have a featured image in the blog, handle it here
          setLoading(false);
          setSearchKeyword(respdata.jobId.title);
          setUserKeyword(respdata.userId.name);
          setShowDropdown(false);
          setShowUserDropdown(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        console.error("Error fetching blog data:", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetTicket();
  }, [id]);
  const fetchUsers = debounce(async () => {
    if (!userKeyword || formData.assignedTo) return;
    try {
      setLoading(true);
      const response = await api.searchUsers(userKeyword);
      if (response && response.users) {
        setuserdata(
          response.users.map((user) => ({
            value: user._id,
            label: user.name,
          }))
        );
      } else {
        setuserdata([]);
      }
      setShowUserDropdown(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  }, 500);
  useEffect(() => {
    if (userKeyword && !formData.assignedTo) {
      fetchUsers();
    }
  }, [userKeyword, formData.assignedTo]);
  const fetchJobs = debounce(async () => {
    if (!searchKeyword) return;
    try {
      setLoading(true);
      const response = await api.getJobsByAdmin(10, 0, searchKeyword);
      if (response && response.jobs) {
        setdata(
          response.jobs.map((job) => ({
            value: job._id,
            label: job.title,
          }))
        );
        setShowDropdown(true); // Show dropdown when data is fetched
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (searchKeyword && !formData.jobId) {
      fetchJobs();
    }
  }, [searchKeyword]);
  console.log(showUserDropdown, "showUserDropdown");

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
    setFormData({ ...formData, jobId: "" }); // Clear jobId when typing new search
    setShowDropdown(true);
  };
  const handleUserSelect = (user) => {
    setFormData({ ...formData, assignedTo: user.value });
    setUserKeyword(user.label);
    setShowUserDropdown(false);
  };
  const handleUserSearchChange = (e) => {
    setUserKeyword(e.target.value);
    setFormData({ ...formData, assignedTo: "" });
    if (e) {
      setShowUserDropdown(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    //console.log(formData);
    try {
      const response = await api.editTicket(id, formData);
      if (response.status === 200) {
        toastRef.current.addToast("Ticket Updated Successffully", "success");
        setLoading(false);
        window.location.reload();
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      // alert("Failed to submit course. Please try again.");
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowUserDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const dummyJobStatusOptions = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];
  const handleJobSelect = (job) => {
    setFormData({ ...formData, jobId: job.value });
    setSearchKeyword(job.label); // Display selected job title in input
    setShowDropdown(false); // Hide dropdown after selection
  };
  return (
    <div>
      <div className="w-3/4 m-auto p-10">
        <div className="py-5 text-3xl font-bold" style={{ color: "#003366" }}>
          Edit Ticket
        </div>
        <form
          onSubmit={handleSubmit}
          className="animated-form profileheadlinediv space-y-6"
        >
          <input type="hidden" id="userId" value={User?._id} />
          <div className="grid grid-cols-2 gap-5">
            <div className="">
              <div className="form-group relative" ref={dropdownRef}>
                <input
                  id="jobSearch"
                  type="text"
                  name="jobId"
                  value={searchKeyword}
                  onChange={handleSearchChange}
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  placeholder="Type to search jobs..."
                />
                <label htmlFor="jobSearch">Search Job</label>
                {showDropdown && (
                  <ul className="absolute z-10 w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto mt-1 custom-scrollbar">
                    {data.length > 0 ? (
                      data.map((job) => (
                        <li
                          key={job.value}
                          onClick={() => handleJobSelect(job)}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                        >
                          {job.label}
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">No data found</li>
                    )}
                  </ul>
                )}
              </div>
            </div>
            <div className="">
              <div className="form-group relative" ref={dropDownRef}>
                <input
                  id="assignedToSearch"
                  type="text"
                  name="assignedTo"
                  value={userKeyword}
                  onChange={handleUserSearchChange}
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  placeholder="Type to search users..."
                />
                <label htmlFor="assignedToSearch">Assign To</label>
                {showUserDropdown && (
                  <ul className="absolute z-10 w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto mt-1 custom-scrollbar">
                    {userdata.length > 0 ? (
                      userdata.map((user) => (
                        <li
                          key={user.value}
                          onClick={() => handleUserSelect(user)}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                        >
                          {user.label}
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">No data found</li>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="">
              <div className="relative job-search-label-section singleselect2 mt-1">
                <Select2
                  id="statusOptions"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="statusOptions"
                  multiple={false}
                  defaultValue={formData.status}
                  onChange={(e) => handleSelectChange(e, "status")}
                  data={dummyJobStatusOptions.map((status) => ({
                    id: status.value,
                    text: status.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="statusOptions"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Status
                </label>
              </div>
            </div>{" "}
            <div className="">
              <div className="form-group">
                <input
                  id="subject"
                  placeholder=""
                  value={formData.subject}
                  onChange={handleChange}
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  rows="4"
                />
                <label htmlFor="subject" className="profileheadline">
                  Subject
                </label>
              </div>
            </div>
            {/* <div className="">
              <div className="relative job-search-label-section singleselect2">
                <Select2
                  id="currentJobStatus"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="currentJobStatus"
                  multiple={false}
                  defaultValue={formData.currentJobStatus}
                  onChange={(e) => handleSelectChange(e, "currentJobStatus")}
                  data={currentJobStatusOptions.map((status) => ({
                    id: status.value,
                    text: status.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="currentJobStatus"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Current Job Status
                </label>
              </div>
            </div> */}
          </div>

          <div className="form-group">
            <textarea
              id="description"
              placeholder=""
              value={formData.description}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700 custom-scrollbar"
              rows="4"
            />
            <label htmlFor="description" className="profileheadline">
              Description
            </label>
          </div>

          <div className="flex justify-end mt-6">
            <button type="submit" className="Job-Post-btn text-white">
              Save
            </button>
          </div>
        </form>
      </div>{" "}
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default TicketForm;
